import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { authSuccess } from "../../app/Actions/auth";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useLocation,
} from "react-router-dom";
import Login from "../component/Login/Login";
import UserLayout from "../UserLayout";
import InforCompany from "../../containers/component/manage_company/infor_company/InforCompany"
import CategoryMaster from "../../containers/component/manage_company/category_master/CategoryMaster"
import SettingDocument from "../../containers/component/manage_company/export_document/SettingDocument"
import { CustomLayout } from "../../layout/Layout";
import NotPermission from "../notPermission"
import NotFoundLayout from "../notfoundLayout"
import { getUserInfo } from "../../app/Reducers/getUserInfo";
import CreateNewPassword from "../component/Login/CreateNewPassword";
import ForgetPassword from "../component/Login/ForgetPassword";
import Invoice from "../component/manage_doc/invoice/Invoice";
import Statement from "../component/manage_doc/statement/Statement";
import History from "../component/manage_doc/history/History";
import AccountingInvoice from "../component/manage_doc/accounting_entry/accounting_invoice/AccountingInvoice";
import AccountingStatement from "../component/manage_doc/accounting_entry/accounting_statement/AccountingStatement";
import InforUser from "../component/manage_user/infor_user/InforUser";
import InforWithRoleAdmin from "../component/manage_company/infor_company/role_admin/InforWithRoleAdmin";
import CategoryMasterRoleAdmin from "../component/manage_company/category_master/role_admin/CategoryMasterRoleAdmin";
import InforUserRoleAdmin from "../component/manage_user/infor_user/role_admin/InforUserRoleAdmin";
const cookies = new Cookies();

function PageTitleUpdater() {
    const location = useLocation();

    React.useEffect(() => {
        const path = location.pathname;
        let pageTitle = "VAC"; // Đặt tiêu đề mặc định

        // Cập nhật tiêu đề dựa trên URL
        if (path === "/") {
            pageTitle = "Khai báo thông tin DN";
        } else if (path === "/export-document") {
            pageTitle = "Khai báo mẫu xuất hóa đơn";
        } else if (path === "/category-master") {
            pageTitle = "Khai báo danh mục";
        } else if (path === "/invoice") {
            pageTitle = "Hóa đơn";
        } else if (path === "/statement") {
            pageTitle = "Sao kê";
        } else if (path === "/history") {
            pageTitle = "Thông tin tải lên";
        } else if (path === "/accounting-invoice") {
            pageTitle = "Hóa đơn";
        } else if (path === "/accounting-statement") {
            pageTitle = "Sao kê";
        }
        else if (path === "/infor-user") {
            pageTitle = "Quản lý tài khoản";
        }

        // Cập nhật tiêu đề trang
        document.title = pageTitle;
    }, [location]);

    return null;
}

function Main() {
    const dispatch = useDispatch();
    const [lsPermissions, setLsPermissions] = useState([]);

    const token = cookies.get("token_vac");
    if (token) {
        dispatch(authSuccess(token));
    }
    const auth = useSelector((state) => state.auth.token) !== null;
    const userInfo = useSelector((state) => state.getUserInfo.userInfo);
    useEffect(() => {
        if (auth && token) {
            dispatch(getUserInfo());
        }
    }, [auth, dispatch, token]);

    useEffect(() => {
        if (Object.keys(userInfo).length !== 0 && userInfo !== undefined) {
            setLsPermissions([userInfo.role_name]);
        }

    }, [userInfo]);

    const checkRole = userInfo.role_id === 1 || userInfo.role_id === 2

    return (
        <Router>
            <PageTitleUpdater />
            <Switch>
                <ProtectLoginRoute
                    exact
                    path="/login"
                    protect={auth}
                    user_info={userInfo}
                >
                    <UserLayout>
                        <Login />
                    </UserLayout>
                </ProtectLoginRoute>
                <RouteWithLayout
                    component={checkRole === true ? InforCompany : InforWithRoleAdmin}
                    exact
                    layout={CustomLayout}
                    path="/"
                    isPrivate={true}
                    lsPermissions={lsPermissions}
                    permission={["Admin", "Admin Tax", "Admin Manager"]}
                    isLogged={auth}
                />
                <RouteWithLayout
                    component={SettingDocument}
                    exact
                    layout={CustomLayout}
                    path="/export-document"
                    isPrivate={true}
                    lsPermissions={lsPermissions}
                    permission={["Admin", "Admin Tax", "Admin Manager"]}
                    isLogged={auth}
                />

                <RouteWithLayout
                    component={checkRole === true ? CategoryMaster : CategoryMasterRoleAdmin}
                    exact
                    layout={CustomLayout}
                    path="/category-master"
                    isPrivate={true}
                    lsPermissions={lsPermissions}
                    permission={["Admin", "Admin Tax", "Admin Manager"]}
                    isLogged={auth}
                />
                <RouteWithLayout
                    component={Invoice}
                    exact
                    layout={CustomLayout}
                    path="/invoice"
                    isPrivate={true}
                    lsPermissions={lsPermissions}
                    permission={["Admin", "Admin Tax", "Admin Manager", "Member"]}
                    isLogged={auth}
                />
                <RouteWithLayout
                    component={Statement}
                    exact
                    layout={CustomLayout}
                    path="/statement"
                    isPrivate={true}
                    lsPermissions={lsPermissions}
                    permission={["Admin", "Admin Tax", "Admin Manager", "Member"]}
                    isLogged={auth}
                />
                <RouteWithLayout
                    component={History}
                    exact
                    layout={CustomLayout}
                    path="/history"
                    isPrivate={true}
                    lsPermissions={lsPermissions}
                    permission={["Admin", "Admin Tax", "Admin Manager", "Member"]}
                    isLogged={auth}
                />
                <RouteWithLayout
                    component={AccountingInvoice}
                    exact
                    layout={CustomLayout}
                    path="/accounting-invoice"
                    isPrivate={true}
                    lsPermissions={lsPermissions}
                    permission={["Admin", "Admin Tax", "Admin Manager", "Member"]}
                    isLogged={auth}
                />
                <RouteWithLayout
                    component={AccountingStatement}
                    exact
                    layout={CustomLayout}
                    path="/accounting-statement"
                    isPrivate={true}
                    lsPermissions={lsPermissions}
                    permission={["Admin", "Admin Tax", "Admin Manager", "Member"]}
                    isLogged={auth}
                />
                <RouteWithLayout
                    component={checkRole === true ? InforUser : InforUserRoleAdmin}
                    exact
                    layout={CustomLayout}
                    path="/infor-user"
                    isPrivate={true}
                    lsPermissions={lsPermissions}
                    permission={["Admin", "Admin Tax", "Admin Manager"]}
                    isLogged={auth}
                />

                <Route
                    exact
                    path="/renewpass/:tok"
                    component={CreateNewPassword} />

                {auth === true ?
                    <RouteWithLayout
                        exact
                        path="/forgot-password"
                        component={ForgetPassword}
                        isLogged={true}
                        isPrivate={true}
                        lsPermissions={lsPermissions}
                        permission={'403'}
                    />
                    : <Route
                        exact
                        path="/forgot-password"
                        component={ForgetPassword} />
                }

                <RouteWithLayout
                    component={NotPermission}
                    exact
                    layout={CustomLayout}
                    path="/notpermission"
                    isPrivate={true}
                    lsPermissions={lsPermissions}
                    permission={'403'}
                    isLogged={auth}
                />
                <RouteWithLayout
                    component={NotFoundLayout}
                    exact
                    layout={CustomLayout}
                    path="/"
                    isPrivate={true}
                    lsPermissions={lsPermissions}
                    permission={'404'}
                    isLogged={auth}
                />

            </Switch>
        </Router>
    );
}

const RouteWithLayout = (props) => {
    const {
        layout: Layout,
        // eslint-disable-next-line no-useless-rename
        isLogged: isLogged,
        component: Component,
        // eslint-disable-next-line no-useless-rename
        isPrivate: isPrivate,
        // eslint-disable-next-line no-useless-rename
        lsPermissions: lsPermissions,
        // eslint-disable-next-line no-useless-rename
        permission: permission,
        // eslint-disable-next-line no-useless-rename
        path: path,
        // eslint-disable-next-line no-useless-rename
        isSuperA: isSuperA,
        ...rest
    } = props;

    const getRejectRoute = (type) => {
        if (type !== "404" && path !== "/") {
            type = "403";
        }

        switch (type) {
            case "403":
                return <NotPermission />;
            case "404":
                return <NotFoundLayout />;
            default:
                return <NotPermission />;
        }
    };
    return (
        <Route
            {...rest}
            render={() =>
                isPrivate ? (
                    isLogged ? (
                        lsPermissions && lsPermissions.length > 0 ? (
                            lsPermissions.some((r) => permission.includes(r)) ? (
                                <Layout isLogged={isLogged}>
                                    <Component {...props} />
                                </Layout>
                            ) : (
                                getRejectRoute(permission)
                            )
                        ) : (
                            <span></span>
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    )
                ) : (
                    <Layout isLogged={isLogged}>
                        <Component {...props} />
                    </Layout>
                )
            }
        />
    );
};

const ProtectLoginRoute = ({
    protect,
    lsPermissionsType,
    lsPermissions,
    user_info,
    children,
    ...rest
}) => {
    return (
        <>
            <Route
                {...rest}
                render={() =>
                    !protect ? (
                        children
                    ) : (
                        <>
                            {/*Mặc định lúc đăng nhập vào */}
                            <Redirect to="/invoice"></Redirect>
                        </>
                    )
                }
            />
        </>
    );
};

export default Main;
