// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/background.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rowGetInTouch {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center/ cover !important;
  /* background-image: url(images/background.svg); */
  /* background-size: cover;
    background-repeat: no-repeat; */
  position: relative;
  /* top: 50%;
    left: 50%; */
  transform: "translate(-50%, -50%)";
  background-color: "background.paper";
  /* padding: 4; */
}

.formItemGetInTouch .ant-col.ant-form-item-label label {
  font-size: 14px !important;
  font-weight: 500;
}

.textFieldContact.ant-input-lg {
  height: 48px;
  border-radius: 15px;
  border-color: #1abdf3;
}
.ant-col.ant-col-19.ColGetInTouch > p {
  margin: 0;
}

.ant-col.ant-col-4.ColGetInTouch > p {
  margin: 0;
}

.ant-col.ant-col-24.ColGetInTouch > p {
  margin: 0;
}

.btnGetInTouch1.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(25, 118, 210, 0.04);
  border-radius: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/containers/component/Login/ModalContact.css"],"names":[],"mappings":"AAAA;EACE,6FAAyF;EACzF,kDAAkD;EAClD;mCACiC;EACjC,kBAAkB;EAClB;gBACc;EACd,kCAAkC;EAClC,oCAAoC;EACpC,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,qBAAqB;AACvB;AACA;EACE,SAAS;AACX;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,6BAA6B;EAC7B,qBAAqB;EACrB,0CAA0C;EAC1C,kBAAkB;AACpB","sourcesContent":[".rowGetInTouch {\r\n  background: url(../../../images/background.svg) no-repeat center center/ cover !important;\r\n  /* background-image: url(images/background.svg); */\r\n  /* background-size: cover;\r\n    background-repeat: no-repeat; */\r\n  position: relative;\r\n  /* top: 50%;\r\n    left: 50%; */\r\n  transform: \"translate(-50%, -50%)\";\r\n  background-color: \"background.paper\";\r\n  /* padding: 4; */\r\n}\r\n\r\n.formItemGetInTouch .ant-col.ant-form-item-label label {\r\n  font-size: 14px !important;\r\n  font-weight: 500;\r\n}\r\n\r\n.textFieldContact.ant-input-lg {\r\n  height: 48px;\r\n  border-radius: 15px;\r\n  border-color: #1abdf3;\r\n}\r\n.ant-col.ant-col-19.ColGetInTouch > p {\r\n  margin: 0;\r\n}\r\n\r\n.ant-col.ant-col-4.ColGetInTouch > p {\r\n  margin: 0;\r\n}\r\n\r\n.ant-col.ant-col-24.ColGetInTouch > p {\r\n  margin: 0;\r\n}\r\n\r\n.btnGetInTouch1.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {\r\n  -webkit-text-decoration: none;\r\n  text-decoration: none;\r\n  background-color: rgba(25, 118, 210, 0.04);\r\n  border-radius: 50%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
