// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-error {
  color: var(--Red, #e55353);
  text-align: center;
  /* font-family: Roboto; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
}
.text-success {
  color: var(--Red, #2EB85C);
  text-align: center;
  /* font-family: Roboto; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
}

.custom-image {
  margin: 0px;
}

.custom-confirm-btn {
  background-color: unset !important;
  color: black !important;
  border: unset !important;
}
.custom-confirm-btn:hover {
  background-color: unset !important;
}

.title-btn {
  color: var(--text-title-3, #516583);
  text-align: center;
  /* font-family: Roboto; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  letter-spacing: 1.25px;
  text-transform: capitalize;
}
`, "",{"version":3,"sources":["webpack://./src/app/Actions/auth.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;AAChC;AACA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;AAChC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kCAAkC;EAClC,uBAAuB;EACvB,wBAAwB;AAC1B;AACA;EACE,kCAAkC;AACpC;;AAEA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;EAChC,sBAAsB;EACtB,0BAA0B;AAC5B","sourcesContent":[".text-error {\r\n  color: var(--Red, #e55353);\r\n  text-align: center;\r\n  /* font-family: Roboto; */\r\n  font-size: 18px;\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  line-height: 140%; /* 22.4px */\r\n}\r\n.text-success {\r\n  color: var(--Red, #2EB85C);\r\n  text-align: center;\r\n  /* font-family: Roboto; */\r\n  font-size: 18px;\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  line-height: 140%; /* 22.4px */\r\n}\r\n\r\n.custom-image {\r\n  margin: 0px;\r\n}\r\n\r\n.custom-confirm-btn {\r\n  background-color: unset !important;\r\n  color: black !important;\r\n  border: unset !important;\r\n}\r\n.custom-confirm-btn:hover {\r\n  background-color: unset !important;\r\n}\r\n\r\n.title-btn {\r\n  color: var(--text-title-3, #516583);\r\n  text-align: center;\r\n  /* font-family: Roboto; */\r\n  font-size: 16px;\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  line-height: 16px; /* 133.333% */\r\n  letter-spacing: 1.25px;\r\n  text-transform: capitalize;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
