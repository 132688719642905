import { Button, Col, Form, Input, Row, Select, Divider, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Container } from '@mui/material';
import IconEditCompany from "../../../../images/IconEditCompany.svg"
import "./InforCompany.css"
import { GetInForCompanyApi, GetListCityApi, GetListDistrictsApi, PutInForCompanyApi } from '../../../../api/inforCompanyApi';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import ErrorImage from "../../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "../../../../images/SuccessNotiIcon.svg"
import InforCompanyMobile from './InforCompanyMobile';
import { screenMobile } from '../../../../Function';

const { Option } = Select;
const MySwal = withReactContent(Swal);
const onNotification = (icon, status, message) => {
  MySwal.fire({
    timer: 3000,
    html: <i>{message}</i>,
    imageUrl: icon,
    showConfirmButton: false,
    customClass: {
      icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
      popup: "custom-notification"
    },
    position: "top",
  });
}

function InforCompany() {
  const [form] = Form.useForm();
  const [selectedCity, setSelectedCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [loadingButtonSave, setLoadingButtonSave] = useState(false);
  const [checkEditInfor, setCheckEditInfor] = useState(false)
  const [listEmailSendDoc, setListEmailSendDoc] = useState([])
  const [checkFormatEmail, setCheckFormatEmail] = useState()
  const [dataEmailSendDoc, setDataEmailSendDoc] = useState()
  const [valueTenDoanhNghiep, setValueTenDoanhNghiep] = useState("");

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  const fetchListDataCity = () => {
    GetListCityApi().then((res) => {
      setCities(res.data);
    });
  };

  const fetchListDataDistricts = (selectedCity) => {
    GetListDistrictsApi({ code_city: selectedCity }).then((res) => {
      setDistricts(res.data);
    });
  };

  const handleCityChange = (e) => {
    setSelectedCity(e);
    form.setFieldsValue({ code_district: null });
  };

  const fetchInforCompany = (id) => {
    GetInForCompanyApi(id).then(res => {
      setTimeout(() => {
        form.setFieldsValue({
          tax_code: res.data.tax_code,
          name_abbreviation: res.data.name_abbreviation,
          name_organization: res.data.name_organization,
          address: res.data.address,
          code_city: res.data.code_city,
          code_district: res.data.code_district,
          email_registration: userInfo.email,
          numberphone: res.data.numberphone,
          email_send_doc: res.data.email_send_doc,
          amount_deviation: res.data.amount_deviation,
        });
      }, 100);
      setDataEmailSendDoc(res.data.email_send_doc)
      fetchListDataDistricts(res.data.code_city)
      setListEmailSendDoc(res.data.email_send_doc)
    }).catch(err => {
      console.log(err)
    })
  }
  useEffect(() => {
    fetchListDataCity();
  }, []);

  useEffect(() => {
    fetchListDataDistricts(selectedCity);
  }, [selectedCity]);

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadingButtonSave(true);
    data.append("id_customer", userInfo.customer_id);
    data.append("address", values.address);
    data.append("code_city", values.code_city);
    data.append("code_district", values.code_district);
    data.append("email_send_doc", values.email_send_doc.toString().replaceAll(",", ";"));
    data.append("name_abbreviation", values.name_abbreviation);
    data.append("name_organization", values.name_organization);
    data.append("numberphone", values.numberphone);
    PutInForCompanyApi(data)
      .then((res) => {
        setCheckEditInfor(false)
        setLoadingButtonSave(false)
        setCheckFormatEmail("")
        onNotification(SuccessImage, "success", res.data.message)
      }).catch(err => {
        setLoadingButtonSave(false)
        onNotification(ErrorImage, "error", err.data.message)
      });
  };

  const handleTenDoanhNghiep = (e) => {
    const words = e.target.value.split(" ");
    if (words.length > 0) {
      const lastWord = words[words.length - 1]; // Lấy từ cuối cùng trong mảng
      setValueTenDoanhNghiep(lastWord);
    } else {
    }
  };

  const ClickEditInfor = () => {
    setCheckEditInfor(true)
  }

  const ClickCancelEdit = () => {
    setCheckEditInfor(false)
  }

  useEffect(() => {
    fetchInforCompany(userInfo.customer_id)
    if (checkEditInfor === true) {
      setName()
      setCheckFormatEmail()
    }
  }, [checkEditInfor]);

  const canEdit = checkEditInfor === true ? false : true;
  const [name, setName] = useState('');
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    if (event.target.value === "") {
      setCheckFormatEmail("")
    }
    setName(event.target.value);
  };
  const addItem = (e) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let flag = false;
    if (name !== undefined && name !== "") {
      for (let i = 0; i < dataEmailSendDoc.length; i++) {
        if (name.toLowerCase() === dataEmailSendDoc[i]) {
          flag = true;
          break;
        }
      }

      if (emailRegex.test(name) === true && flag === false) {
        e.preventDefault();
        setCheckFormatEmail("")
        setListEmailSendDoc([...listEmailSendDoc, name])
        form.setFieldValue('email_send_doc', [...form.getFieldValue('email_send_doc'), name])
        setName('');
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      } else if (emailRegex.test(name) === false) {
        setCheckFormatEmail("Email chưa đúng định dạng !!!")
      } else if (flag === true) {
        setCheckFormatEmail("Email này đã tồn tại !!!")
      }
    } else {
      setCheckFormatEmail("Vui lòng nhập Email cần thêm !!!")
    }
  };

  const btnEditRoleMember = () => {
    if (userInfo.role_id === 1) {
      return (<>
        {checkEditInfor === false ?
          <>
            <Button
              htmlType="button"
              className='text-btn design-btn'
              onClick={() => setTimeout(() => {
                ClickEditInfor()
              }, 200)}
              style={
                {
                  float: "right",
                }
              }
            >
              Chỉnh sửa
            </Button>
          </>
          :
          <>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button

                htmlType="button"
                className='text-btn design-btn'

                onClick={() => setTimeout(() => {
                  ClickCancelEdit()
                }, 200)}
              >
                Hủy
              </Button>
              <Button
                // variant="contained"
                // type="success"
                htmlType="submit"
                disabled={loadingButtonSave}
                className='text-btn'
                style={
                  {
                    border: "2px solid var(--Color-line, #058DF4)",
                    borderRadius: 8,
                    height: 45,
                    width: "100px",
                    color: "#fff",
                    background: "var(--Color-line, linear-gradient(0deg, #058DF4 0%, #6266EF 100%))",
                  }
                }
              >
                Lưu
              </Button>
            </div>
          </>
        }
      </>)
    }
  }

  return (
    <>
      {screenMobile === false ?
        <Container maxWidth="false" className='container-editOrView' style={{ width: "97%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div className='bg-all' style={{ overflowY: "auto" }}>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              // validateMessages={validateMessages}
              // loading={loading}
              className='text-label-manage-company'
              initialValues={{
                remember: true,
              }}
              style={{ width: "94%", height: "75vh" }}
            >
              <Row className="rowInforCustomer">
                <Col
                  span={24}
                  style={{ display: "flex" }}
                >
                  <img src={IconEditCompany} alt=''></img>
                  <span className="title-infor-company" style={{ margin: "0% 0% 0% 0.5%" }}>
                    {checkEditInfor === false ? "Chi tiết" : "Chỉnh sửa"}  thông tin doanh nghiệp
                  </span>
                </Col>
              </Row>

              <div style={{ paddingTop: "3%", display: "grid", gridRowGap: "1.5%" }}>
                <Row>
                  <Col className="colTTDN" span={16}>
                    <Form.Item
                      label="Tên doanh nghiệp:"
                      name="name_organization"
                      rules={[
                        {
                          required: true,
                          message:
                            "Vui lòng nhập tên doanh nghiệp!",
                        },
                      ]}
                    >
                      <Input
                        size={
                          "large"
                        }
                        className="inputTTDN"
                        placeholder="Tên doanh nghiệp"
                        onChange={handleTenDoanhNghiep}
                        readOnly={canEdit}
                      />
                    </Form.Item>
                  </Col>

                  <Col className="colTTDN" span={7} offset={1}>
                    <Form.Item
                      label="Tỉnh/thành phố:"
                      name="code_city"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn tỉnh/thành phố!",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        id="code_city"
                        // value={selectedCity}
                        onChange={handleCityChange}
                        className="SelectTTDN"
                        // showSearch
                        allowClear
                        disabled={canEdit}
                        optionFilterProp="children"
                      >
                        {cities.map((city, index) => (
                          <Option key={index} value={city.code}>
                            {city.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={16}>
                    <Form.Item
                      label="Tên viết tắt:"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập tên viết tắt!",
                        },
                      ]}
                      name="name_abbreviation"
                    >
                      <Input
                        size={
                          "large"
                        }
                        className="inputTTDN"
                        placeholder={`VD: ${valueTenDoanhNghiep}`}
                        readOnly={canEdit}

                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} offset={1}>
                    <Form.Item
                      label="Quận/huyện:"
                      name="code_district"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn quận/huyện!",
                        },
                      ]}
                    >
                      <Select
                        id="code_district"
                        size={"large"}
                        allowClear
                        className="SelectTTDN"
                        disabled={canEdit}
                        optionFilterProp="children"
                      >
                        {districts.length > 0
                          ? districts.map((district, index) => (
                            <Option key={index} value={district.code}>
                              {district.name}
                            </Option>
                          ))
                          : null}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col
                    className="colTTDN"
                    span={16}
                  >
                    <Form.Item label="Địa chỉ trụ sở:" name="address">
                      <Input
                        size={"large"}
                        placeholder="Địa chỉ trụ sở"
                        // autoSize={{
                        //   minRows: 4.5,
                        //   maxRows: 5,
                        // }}
                        readOnly={canEdit}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="colTTDN" span={7} offset={1}>
                    <Form.Item
                      label="Mã số thuế:"
                      name="tax_code"
                      rules={[
                        {
                          required: true,
                          message:
                            "Vui lòng nhập mã số thuế!",
                        },
                      ]}
                    >
                      <Input
                        size={
                          "large"
                        }
                        onBlur={(e) => {
                          form.setFieldsValue({ tax_code: e.target.value.trim() });
                        }}
                        onKeyPress={(e) => {
                          const charCode = e.which
                            ? e.which
                            : e.keyCode;
                          if ((charCode < 48 || charCode > 57) && charCode !== 45) {
                            e.preventDefault();
                          }
                        }}
                        className="inputTTDN"
                        disabled={canEdit === false ? true : false}
                        readOnly={true}
                        style={{ color: "black" }}
                      />
                    </Form.Item>
                  </Col>


                </Row>

                <Row>
                  <Col
                    className="colTTDN"
                    span={16}
                  >
                    <Form.Item
                      label="Email nhận hóa đơn, chứng từ:"
                      name="email_send_doc"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập email gửi từ chứng từ !",
                        },
                      ]}
                      className='form-email'
                    >
                      <Select
                        style={{
                          width: "100%",
                        }}
                        maxTagCount={"responsive"}
                        mode='multiple'
                        placeholder="Chọn email gửi từ chứng từ"
                        disabled={canEdit}
                        allowClear={false}
                        optionFilterProp="children"
                        size='large'
                        value={listEmailSendDoc}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: '8px 0',
                              }}
                            />
                            <Space
                              style={{
                                padding: '0 8px 4px',
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "start"
                              }}
                            >
                              <div style={{ display: "grid" }}>
                                <Input
                                  placeholder="Nhập email"
                                  ref={inputRef}
                                  value={name}
                                  onChange={onNameChange}
                                  onKeyDown={(e) => e.stopPropagation()}
                                  style={checkFormatEmail !== undefined && checkFormatEmail !== "" ? { border: "1px solid red" } : null}
                                />
                                <span style={{ color: "#ff4d4f" }}>{checkFormatEmail}</span>
                              </div>
                              <Button type="text" onClick={addItem}>
                                <PlusOutlined />Thêm email
                              </Button>
                            </Space>
                          </>
                        )}

                      >
                        {listEmailSendDoc.length > 0
                          ? listEmailSendDoc.map((district, index) => (
                            <Option key={index} value={district}>
                              {district}
                            </Option>
                          ))
                          : null}
                      </Select>
                    </Form.Item>
                    <span style={{ float: "left", fontSize: 12, fontStyle: "italic" }}>( Danh sách mail dùng để gửi chứng từ đến VAC )</span>
                  </Col>
                  <Col span={7} offset={1}>
                    <Form.Item
                      label="Điện thoại:"
                      name="numberphone"
                      rules={[
                        {
                          pattern: /^\d+$/,
                          message: "Số điện thoại chỉ được nhập bằng chữ số!",
                        },
                        {
                          max: 12,
                          message: "Chỉ được nhập tối đa 12 số",
                        },
                      ]}
                    >
                      <Input
                        size={
                          "large"
                        }
                        className="inputTTDN"
                        placeholder="Điện thoại"
                        readOnly={canEdit}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col
                    span={16}
                  ></Col>
                  <Col span={7} offset={1} className="colTTDN">
                    {btnEditRoleMember()}
                  </Col>
                </Row>

                <Row>
                  &nbsp;
                </Row>
              </div>

            </Form>
          </div>
        </Container>
        :
        <InforCompanyMobile
          onFinish={onFinish}
          checkEditInfor={checkEditInfor}
          handleTenDoanhNghiep={handleTenDoanhNghiep}
          canEdit={canEdit}
          valueTenDoanhNghiep={valueTenDoanhNghiep}
          handleCityChange={handleCityChange}
          cities={cities}
          districts={districts}
          listEmailSendDoc={listEmailSendDoc}
          onNameChange={onNameChange}
          name={name}
          checkFormatEmail={checkFormatEmail}
          addItem={addItem}
          ClickEditInfor={ClickEditInfor}
          ClickCancelEdit={ClickCancelEdit}
          loadingButtonSave={loadingButtonSave}
          form={form}
        />
      }
    </>
  );
}
export default InforCompany