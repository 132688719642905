import { Button, Col, Modal, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import DownloadIcon from "./../../../../images/image_manage_docs/DownloadInvoice.svg"
import { DownloadHandleStatementApi, GetViewStatementApi } from '../../../../api/StatementApi';

function ModalViewDetailStatement({ open, close, dataDetail, idCustomer }) {
    const [dataSource, setDataSource] = useState([])
    const [subDataSource, setSubDataSource] = useState([])

    const fetchDetailStatement = () => {
        GetViewStatementApi({
            id_statement: dataDetail.id,
            id_customer: idCustomer
        }).then(res => {
            setDataSource(res.data);
            setSubDataSource([{
                opening_balance: res.data.opening_balance,
                ending_balance: res.data.ending_balance,
                total_debit: res.data.total_debit,
                total_credit: res.data.total_credit,
            }])
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        fetchDetailStatement()
    }, []);

    const downloadFileSingleRecord = () => {
        const fnFileDownload = require("js-file-download");
        const filename = dataDetail.name_file_handle;
        DownloadHandleStatementApi({
            id_customer: idCustomer,
            id_statement: dataDetail.id,
        }).then((res) => {
            fnFileDownload(res.data, filename);
            // setLoadingBtnSingleDownload(false)
        }).catch((err) => {
            // setLoadingBtnSingleDownload(false)
        });
    }

    const ConvertLocaleString = (text) => {
        if (text !== undefined && text !== null) {
            return parseInt(text).toLocaleString()
        } else {
            return text
        }
    }

    const subColumns = [
        {
            title: 'Số dư đầu kỳ',
            dataIndex: 'opening_balance',
            key: 'opening_balance',
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record) => ConvertLocaleString(text)
        },
        {
            title: 'Số dư cuối kỳ',
            dataIndex: 'ending_balance',
            key: 'ending_balance',
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record) => ConvertLocaleString(text)
        },
        {
            title: 'Tổng ghi nợ',
            dataIndex: 'total_debit',
            key: 'total_debit',
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record) => ConvertLocaleString(text)
        },
        {
            title: 'Tổng ghi có',
            dataIndex: 'total_credit',
            key: 'total_credit',
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record) => ConvertLocaleString(text)
        },
    ];

    const columns = [
        {
            title: 'Ngày',
            dataIndex: 'Ngày',
            key: 'Ngày',
            align: "center",
            ellipsis: true,
            width: 100,
        },
        {
            title: 'Nợ',
            dataIndex: 'Nợ',
            key: 'Nợ',
            align: "center",
            ellipsis: true,
            width: 50,
        },
        {
            title: 'Có',
            dataIndex: 'Có',
            key: 'Có',
            align: "center",
            ellipsis: true,
            width: 50,
        },
        {
            title: 'Mã đối tượng',
            dataIndex: 'Mã đối tượng',
            key: 'Mã đối tượng',
            align: "center",
            ellipsis: true,
            width: 120,
        },

        {
            title: 'Tên đối tượng',
            dataIndex: 'Tên đối tượng',
            key: 'Tên đối tượng',
            align: "left",
            ellipsis: true,
            width: 120,
        },
        {
            title: 'Số chứng từ',
            dataIndex: 'Số chứng từ',
            key: 'Số chứng từ',
            align: "center",
            ellipsis: true,
            width: 120,
        },
        {
            title: 'Diễn giải',
            dataIndex: 'Diễn giải',
            key: 'Diễn giải',
            align: "left",
            ellipsis: true,
            width: 250,
        },
        {
            title: 'Thành tiền',
            dataIndex: 'Thành tiền',
            key: 'Thành tiền',
            align: "center",
            ellipsis: true,
            width: 120,
        },
        {
            title: 'Diễn giải gốc',
            dataIndex: 'Diễn giải gốc',
            key: 'Diễn giải gốc',
            align: "left",
            ellipsis: true,
            width: 250,
        },
        {
            title: 'YTCP Confi',
            dataIndex: 'YTCP Confi',
            key: 'YTCP Confi',
            align: "center",
            ellipsis: true,
            width: 100,
        },
    ];

    return (
        <>
            <Modal open={open} footer={false} onCancel={close} width={"80%"} style={{ position: "relative", top: 20 }}>
                <Row>
                    <Col span={8} style={{ display: "flex", justifyContent: "flex-start" }}>
                        <Button onClick={downloadFileSingleRecord} className='btn-invoice-all' style={{background: "rgba(50, 31, 219, 0.12)", border: "unset" }}><img alt='' src={DownloadIcon} style={{ marginRight: "3%" }}></img><span style={{ color: "rgb(85,69,225)", fontWeight: 'bold'}}>DOWNLOAD</span></Button>
                    </Col>
                    <Col span={8} className='title-table-detail-statement'>CHI TIẾT SAO KÊ</Col>

                    <Col span={8}></Col>
                </Row>

                <Table className="table-setting-doc" scroll={{
                    x: 2000, y: "60vh"
                }} style={{ paddingTop: "1%", height: "auto" }} pagination={false} bordered dataSource={dataSource.data_file} columns={columns} size='middle' />

                <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                    {dataSource &&
                        <Table className="table-setting-doc" style={{ paddingTop: "1%", width: "50%" }} pagination={false} bordered dataSource={subDataSource} columns={subColumns} size='middle' />
                    }
                </Row>

            </Modal>
        </>
    )
}

export default ModalViewDetailStatement