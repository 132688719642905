import { Button, Col, Form, Input, Row, Table, Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { Container } from '@mui/material';
import { SearchOutlined } from '@ant-design/icons';
import "./InforUser.css"
import AddIcon from "./../../../../images/AddIcon.svg"
import { GetInforUser, LockUserApi, PostInforUser } from '../../../../api/manageInforUser';
import { useSelector } from 'react-redux';
import SuccessImage from "./../../../../images/SuccessNotiIcon.svg"
import ErrorImage from "./../../../../images/ErrorNotifiIcon.svg"
import DetailIcon from "../image/ViewIcon.svg"
import EditIcon from "../image/EditIcon.svg"
import UnlockIcon from "../image/UnlockIcon.svg"
import LockIcon from "../image/LockIcon.svg"

import { openNotificationSweetAlert } from '../../../../Function';
import Swal from 'sweetalert2';
import ModalViewDetail from './function/ModalViewDetail';
import ModalEdit from './function/ModalEdit';

const ToastSuccess = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    customClass: {
        popup: "my-custom-popup",
        timerProgressBar: "my-custom-progress-bar-success", // Thêm class tùy chỉnh
    },
});
function InforUser() {
    const [isOpenModalAdd, setIsOpenModalAdd] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [isOpenModalDetail, setIsOpenModalDetail] = useState(false)
    const [isOpenModalEdit, setIsOpenModalEdit] = useState(false)
    const [dataDetail, setDataDetail] = useState()
    const userInfo = useSelector((state) => state.getUserInfo.userInfo);
    const [nameCompany, setNameCompany] = useState("")
    const [idTaxAgent, setIdTaxAgent] = useState()
    const [valueSearch, setValueSearch] = useState("")
    const [pager, setPager] = useState({
        pageSize: 10,
        count: 0,
        current: 1,
    });

    const columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'name',
            align: "center",
            ellipsis: true,
            width: 50,
            render: (value, item, index) =>
                index + 1 + (pager.current - 1) * pager.pageSize,
        },
        {
            title: 'Tên tài khoản',
            dataIndex: 'username',
            key: 'username',
            align: "center",
            ellipsis: true,
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            align: "center",
            ellipsis: true,
        },

        {
            title: 'Vai trò',
            dataIndex: 'role_user_id',
            key: 'role_user_id',
            align: "center",
            ellipsis: true,
            render: (text, record, index) => showText(text)
        },

        {
            title: 'Thao tác',
            dataIndex: 'Action',
            key: 'Action',
            align: "center",
            ellipsis: true,
            width: 170,
            render: (text, record, index) => {
                return (<div style={{ columnGap: "1.5ch", justifyContent: "center", alignItems: "center", display: "flex" }}>
                    <img style={{ cursor: "pointer" }} onClick={() => openModalDetail(record)} src={DetailIcon} alt=''></img>
                    {record.is_active === true ?
                        <img style={{ cursor: "pointer" }} onClick={() => openModalEdit(record)} alt='' src={EditIcon}></img>
                        :
                        <img style={{ cursor: "not-allowed" }} alt='' src={EditIcon}></img>
                    }
                    {userInfo.id !== record.id ? <img style={{ cursor: "pointer" }} onClick={() => lockUser(record)} alt='' src={record.is_active === true ? UnlockIcon : LockIcon}></img> : <img style={{ opacity: 0 }} alt='' src={record.is_active === true ? UnlockIcon : LockIcon}></img>}
                </div>)
            }
        },
    ];

    const showText = (text) => {
        if (text === 1) {
            return "Quản lý / Doanhh nghiệp"
        } else if (text === 2) {
            return "Nhân viên"
        }
        else if (text === 3) {
            return "Quản lý vận hành"
        }
        else if (text === 4) {
            return "Đại lý thuế"
        }
    }

    const openModalDetail = (record) => {
        setDataDetail(record)
        setIsOpenModalDetail(true)
    }

    const openModalEdit = (record) => {
        setDataDetail(record)
        setIsOpenModalEdit(true)
    }

    const lockUser = (record) => {
        var FormData = require("form-data");
        var data = new FormData();
        data.append("id_user", record.id)
        data.append("is_active", !record.is_active)
        LockUserApi(data).then(res => {
            if (!record.is_active === false) {
                ToastSuccess.fire({
                    icon: "success",
                    title: "Khóa tài khoản thành công",
                });
            } else {
                ToastSuccess.fire({
                    icon: "success",
                    title: " Mở khóa tài khoản thành công",
                });
            }
            fetchListUser(record.customer_user_id)
        }).catch(err => {
            console.log(err)
        })
    }

    const showModalAdd = () => {
        setIsOpenModalAdd(true)
    }

    const fetchListUser = (params = {}) => {
        GetInforUser(params).then(res => {
            setPager({
                current: params.page,
                pageSize: params.page_size,
                count: res.data.count,
            });
            setDataSource(res.data.results)
            setNameCompany(userInfo.customer_name)
            setIdTaxAgent(userInfo.taxagent_user)
        }).catch(err => {
            console.log(err)
        })
    }

    const loadData = (dataSearch) => {
        const id = setInterval(() => {
            fetchListUser({ id_customer: userInfo.customer_id, search: dataSearch, page: 1, page_size: pager.pageSize });
            clearInterval(id);
        }, 300);
        return () => clearInterval(id);
    }

    useEffect(() => {
        loadData("")
    }, []);

    const searchUser = (e) => {
        setValueSearch(e.target.value)
        loadData(e.target.value)
    }

    const handleChange = (pagination) => {
        const currentPager = { ...pager };
        currentPager.current = pagination.current;
        currentPager.pageSize = 10;
        setPager({ ...currentPager });
        fetchListUser(
            {
                id_customer: userInfo.customer_id,
                search: valueSearch,
                page: pagination.current,
                page_size: pagination.pageSize,
            }
        )
    };

    return (
        <>
            <Container maxWidth="false" style={{ backgroundColor: "#fff", width: "97%", marginTop: "1.2%", height: "88vh" }}>
                <Row style={{ paddingTop: "1.5%" }}>
                    <Col span={16}>
                        <Input allowClear onChange={searchUser} suffix={<SearchOutlined />} className='search-user' placeholder='Tìm kiếm người dùng'></Input>
                    </Col>
                    <Col span={8}>
                        <Button className='btn-invoice-all' onClick={showModalAdd} style={{ background: "rgba(58, 183, 176, 0.15)", float: "right" }}><img alt='' src={AddIcon}></img></Button>
                    </Col>
                </Row>
                <div style={{ width: "100%", position: "relative", paddingTop: "0.5%" }}>
                    <Table
                        size='middle'
                        columns={columns}
                        dataSource={dataSource}
                        style={{ marginTop: "1%", fontSize: 14, position: "absolute", width: "100%" }}
                        className='table-invoice-all'
                        bordered

                        scroll={{
                            x: 1000,
                        }}
                        onChange={handleChange}
                        pagination={{
                            current: pager.current,
                            pageSize: pager.pageSize,
                            total: pager.count,
                            showSizeChanger: false,
                            showTotal: (total, range) => `Tổng cộng: ${total}`
                        }}
                    />
                </div>

                {isOpenModalAdd === true ?
                    <ModalAddUser
                        open={isOpenModalAdd}
                        setIsOpenModalAdd={setIsOpenModalAdd}
                        fetchListUser={fetchListUser}
                        userInfo={userInfo}
                    />
                    : null}

                {isOpenModalDetail === true ?
                    <ModalViewDetail
                        open={isOpenModalDetail}
                        setIsOpenModalDetail={setIsOpenModalDetail}
                        dataDetail={dataDetail}
                        nameCompany={nameCompany}
                    />
                    : null}
                {isOpenModalEdit === true ?
                    <ModalEdit
                        open={isOpenModalEdit}
                        setIsOpenModalEdit={setIsOpenModalEdit}
                        dataDetail={dataDetail}
                        nameCompany={nameCompany}
                        fetchListUser={fetchListUser}
                        idTaxAgent={idTaxAgent}
                    />
                    : null}
            </Container>

        </>
    );
}

const ModalAddUser = ({ open, setIsOpenModalAdd, fetchListUser, userInfo }) => {
    const [form] = Form.useForm()
    const [loadingBtnSubmit, setLoadingBtnSubmit] = useState(false)
    const handleCancel = () => {
        form.resetFields();
        setIsOpenModalAdd(false)
    }

    const onFinish = (values) => {
        setLoadingBtnSubmit(true)
        var FormData = require("form-data");
        var data = new FormData();
        data.append("id_customer", userInfo.customer_id);
        data.append("username", values.username);
        data.append("email_registration", values.email_registration);
        data.append("numberphone", values.numberphone);
        data.append("work_position", values.work_position);
        data.append("role_user_id", 2);
        PostInforUser(data).then(res => {
            setLoadingBtnSubmit(false)
            fetchListUser()
            setIsOpenModalAdd(false)
            openNotificationSweetAlert(SuccessImage, res.data.message)

        }).catch(err => {
            setLoadingBtnSubmit(false)
            console.log(err)
            openNotificationSweetAlert(ErrorImage, err.data.message)
        })
    }

    return (
        <>
            <Modal className='modal-add-customer' width={800} open={open} onCancel={handleCancel} footer={false}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <span style={{ margin: 0, fontSize: 30, fontWeight: "bold", color: "#058DF4" }}>Tạo mới tài khoản</span>
                </div>
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    initialValues={{
                        remember: true,
                    }}
                    className='text-label-add-company'
                    style={{ paddingTop: "1%" }}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Thuộc đại lý/ Công ty mẹ" name={"id_customer"}>
                                <Input readOnly defaultValue={userInfo.customer_name} />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Tài khoản"
                                name='username'
                                rules={[
                                    {
                                        required: true,
                                        message: "Vui lòng nhập tài khoản!",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Nhập tài khoản'
                                    onBlur={(e) => {
                                        form.setFieldsValue({ username: e.target.value.trim() });
                                    }}
                                >
                                </Input>
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                label="Email đăng ký"
                                name='email_registration'
                                rules={[
                                    {
                                        required: true,
                                        message: "Vui lòng nhập email đăng ký!",
                                    },
                                    {
                                        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: 'Email chưa đúng định dạng',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Nhập Email'
                                    onBlur={(e) => {
                                        form.setFieldsValue({ email_registration: e.target.value.trim() });
                                    }}
                                >
                                </Input>
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="Số điện thoại"
                                name='numberphone'
                                rules={[
                                    {
                                        pattern: /^\d+$/,
                                        message: "Số điện thoại chỉ được nhập bằng chữ số!",
                                    },
                                    {
                                        max: 12,
                                        message: "Chỉ được nhập tối đa 12 số",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Nhập số điện thoại'
                                    onBlur={(e) => {
                                        form.setFieldsValue({ numberphone: e.target.value.trim() });
                                    }}
                                >
                                </Input>
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                label="Vị trí"
                                name='work_position'
                                rules={[
                                    {
                                        required: true,
                                        message: "Vui lòng nhập vị trí!",
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Nhập vị trí'
                                    onBlur={(e) => {
                                        form.setFieldsValue({ work_position: e.target.value.trim() });
                                    }}
                                >
                                </Input>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Vai trò"
                                name='role_user_id'

                            >
                                <Input readOnly defaultValue={"Nhân viên"} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button

                            htmlType="button"
                            className='text-btn design-btn-cancel'

                            onClick={handleCancel}
                        >
                            Hủy
                        </Button>
                        <Button
                            // variant="contained"
                            // type="success"
                            htmlType="submit"
                            // disabled={loadingButtonSave}
                            className='text-btn design-btn-submit'
                            loading={loadingBtnSubmit}
                        >
                            Lưu
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    )
}
export default InforUser