import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Select, Space } from 'antd';
import React, { useRef } from 'react'
import IconEditCompany from "../../../../images/IconEditCompany.svg"
import { Container } from '@mui/material';

const { Option } = Select;

function InforCompanyMobile({
    districts,
    onFinish,
    checkEditInfor,
    handleTenDoanhNghiep,
    canEdit,
    valueTenDoanhNghiep,
    handleCityChange,
    cities,
    listEmailSendDoc,
    onNameChange,
    name,
    checkFormatEmail,
    addItem,
    ClickEditInfor,
    ClickCancelEdit,
    loadingButtonSave,
    form
}) {
    const inputRef = useRef(null);

    return (
        <>
            <Container maxWidth="false" className='container-editOrView' style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    // validateMessages={validateMessages}
                    // loading={loading}
                    className='text-label-manage-company'
                    initialValues={{
                        remember: true,
                    }}
                    style={{ width: "100%", height: "82vh" }}
                >
                    <div className='bg-all-master-mobile' style={{ overflowY: "auto" }}>

                        <Row className="rowInforCustomer">
                            <Col
                                span={24}
                                style={{ display: "flex" }}
                            >
                                <img style={{ witdh: 22, height: 22 }} src={IconEditCompany} alt=''></img>
                                <span className="title-infor-company" style={{ margin: "0% 0% 0% 0.5%" }}>
                                    {checkEditInfor === false ? "Chi tiết" : "Chỉnh sửa"}  thông tin doanh nghiệp
                                </span>
                            </Col>
                        </Row>

                        <div style={{ paddingTop: "3%", display: "grid" }}>
                            <Row>
                                <Col className="colTTDN" span={24}>
                                    <Form.Item
                                        label="Tên doanh nghiệp:"
                                        name="name_organization"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Vui lòng nhập tên doanh nghiệp!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            size={
                                                "middle"
                                            }
                                            className="inputTTDN"
                                            placeholder="Tên doanh nghiệp"
                                            onChange={handleTenDoanhNghiep}
                                            readOnly={canEdit}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Tên viết tắt:"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Vui lòng nhập tên viết tắt!",
                                            },
                                        ]}
                                        name="name_abbreviation"
                                    >
                                        <Input
                                            size={
                                                "middle"
                                            }
                                            className="inputTTDN"
                                            placeholder={`VD: ${valueTenDoanhNghiep}`}
                                            readOnly={canEdit}

                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="colTTDN" span={24}>
                                    <Form.Item
                                        label="Mã số thuế:"
                                        name="tax_code"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Vui lòng nhập mã số thuế!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            size={
                                                "middle"
                                            }
                                            className="inputTTDN"
                                            // value={valueNameTaxCode}
                                            disabled={canEdit === false ? true : false}
                                            readOnly={true}
                                            style={{ color: "black" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col
                                    className="colTTDN"
                                    span={24}
                                >
                                    <Form.Item label="Địa chỉ trụ sở:" name="address">
                                        <Input
                                            size={"middle"}
                                            placeholder="Địa chỉ trụ sở"
                                            // autoSize={{
                                            //   minRows: 4.5,
                                            //   maxRows: 5,
                                            // }}
                                            readOnly={canEdit}

                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="colTTDN" span={24}>
                                    <Form.Item
                                        label="Tỉnh/thành phố:"
                                        name="code_city"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Vui lòng chọn tỉnh/thành phố!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            size={"middle"}
                                            id="code_city"
                                            // value={selectedCity}
                                            onChange={handleCityChange}
                                            className="SelectTTDN"
                                            // showSearch
                                            allowClear
                                            disabled={canEdit}
                                            optionFilterProp="children"
                                        >
                                            {cities.map((city, index) => (
                                                <Option key={index} value={city.code}>
                                                    {city.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Quận/huyện:"
                                        name="code_district"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Vui lòng chọn quận/huyện!",
                                            },
                                        ]}
                                    >
                                        <Select
                                            id="code_district"
                                            size={"middle"}
                                            allowClear
                                            className="SelectTTDN"
                                            disabled={canEdit}
                                            optionFilterProp="children"
                                        >
                                            {districts.length > 0
                                                ? districts.map((district, index) => (
                                                    <Option key={index} value={district.code}>
                                                        {district.name}
                                                    </Option>
                                                ))
                                                : null}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Email đăng ký:"
                                        name="email_registration"
                                        className='form-email'
                                        rules={[
                                            {
                                                message: "Vui lòng nhập đúng định dạng!",
                                                type: "email",
                                            },

                                            {
                                                required: true,
                                                message: "Vui lòng nhập email !",
                                            },
                                        ]}
                                    >
                                        <Input
                                            size={
                                                "middle"
                                            }
                                            className="inputTTDN"
                                            disabled={canEdit === false ? true : false}
                                            readOnly={true}
                                            placeholder="Email"
                                            onBlur={(e) => {
                                                form.setFieldsValue({
                                                    email: e.target.value.trim(),
                                                });
                                            }}
                                            style={{ color: "black" }}

                                        />
                                    </Form.Item>
                                    <span style={{ float: "left", fontSize: 12, fontStyle: "italic" }}>(Dùng để xác nhận email thông tin doanh nghiệp, reset password)</span>

                                </Col>
                                <Col span={24} style={{ paddingTop: "1%" }}>
                                    <Form.Item
                                        label="Điện thoại:"
                                        name="numberphone"
                                        rules={[
                                            {
                                                pattern: /^\d+$/,
                                                message: "Số điện thoại chỉ được nhập bằng chữ số!",
                                            },
                                            {
                                                max: 12,
                                                message: "Chỉ được nhập tối đa 12 số",
                                            },
                                        ]}
                                    >
                                        <Input
                                            size={
                                                "middle"
                                            }
                                            className="inputTTDN"
                                            placeholder="Điện thoại"
                                            readOnly={canEdit}
                                        />
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row>
                                <Col
                                    className="colTTDN"
                                    span={24}
                                >
                                    <Form.Item
                                        label="Email nhận hóa đơn, chứng từ:"
                                        name="email_send_doc"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Vui lòng nhập email gửi từ chứng từ !",
                                            },
                                        ]}
                                        className='form-email'
                                    >
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            maxTagCount={"responsive"}
                                            mode='multiple'
                                            placeholder="Chọn email gửi từ chứng từ"
                                            disabled={canEdit}
                                            allowClear={false}
                                            size='middle'
                                            optionFilterProp="children"
                                            value={listEmailSendDoc}
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider
                                                        style={{
                                                            margin: '8px 0',
                                                        }}
                                                    />
                                                    <Space
                                                        style={{
                                                            padding: '0 8px 4px',
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            alignItems: "start"
                                                        }}
                                                    >
                                                        <div style={{ display: "grid" }}>
                                                            <Input
                                                                placeholder="Nhập email"
                                                                ref={inputRef}
                                                                value={name}
                                                                onChange={onNameChange}
                                                                onKeyDown={(e) => e.stopPropagation()}
                                                                style={checkFormatEmail !== undefined && checkFormatEmail !== "" ? { border: "1px solid red" } : null}
                                                            />
                                                            <span style={{ color: "#ff4d4f" }}>{checkFormatEmail}</span>
                                                        </div>
                                                        <Button type="text" onClick={addItem}>
                                                            <PlusOutlined />Thêm email
                                                        </Button>
                                                    </Space>
                                                </>
                                            )}

                                        >
                                            {listEmailSendDoc.length > 0
                                                ? listEmailSendDoc.map((district, index) => (
                                                    <Option key={index} value={district}>
                                                        {district}
                                                    </Option>
                                                ))
                                                : null}
                                        </Select>
                                    </Form.Item>
                                    <span style={{ float: "left", fontSize: 12, fontStyle: "italic" }}>( Danh sách mail dùng để gửi chứng từ đến VAC )</span>
                                </Col>
                            </Row>


                        </div>
                    </div>
                    <Row>
                        <Col span={24} className="colTTDN" style={{ paddingTop: "1.5%" }}>
                            {checkEditInfor === false ?
                                <>
                                    <Button
                                        htmlType="button"
                                        className='text-btn design-btn'
                                        onClick={() => setTimeout(() => {
                                            ClickEditInfor()
                                        }, 200)}
                                        style={
                                            {
                                                width: "100%"
                                            }
                                        }
                                    >
                                        Chỉnh sửa
                                    </Button>
                                </>
                                :
                                <>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button

                                            htmlType="button"
                                            className='text-btn design-btn'
                                            style={{ height: 45, width: "50%" }}
                                            onClick={() => setTimeout(() => {
                                                ClickCancelEdit()
                                            }, 200)}
                                        >
                                            Hủy
                                        </Button>
                                        <Button
                                            // variant="contained"
                                            // type="success"
                                            htmlType="submit"
                                            disabled={loadingButtonSave}
                                            className='text-btn design-btn-submit'
                                            style={{ height: 45, width: "50%" }}
                                        >
                                            Lưu
                                        </Button>
                                    </div>
                                </>
                            }
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    )
}

export default InforCompanyMobile