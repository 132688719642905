import { Button, Col, Form, Input, Modal, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { EditExpenseCustomerApi, GetExpenseCustomerApi } from '../../../../../api/categoryMaster';

import EditIcon from "./../../../../../images/EditIcon.svg"
import DeleteIcon from "./../../../../../images/DeleteIcon.svg"
import ErrorImage from "../../../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "../../../../../images/SuccessNotiIcon.svg"
import MinusIcon from "../../../../../images/MinusIcon.svg"
import { openNotificationSweetAlert } from '../../../../../Function';

function ModalEditCategoryCustomer({ open, setOpenEditCategoryCustomer, idCustomer }) {
  const [form] = Form.useForm()
  const [loadingModal, setLoadingModal] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [checkScroll, setCheckScroll] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [listIndexEdit, setListIndexEdit] = useState([])
  const [listIndexDelete, setListIndexDelete] = useState([])
  const [listIndexFormDelete, setListIndexFormDelete] = useState([])
  const [loadingBtn, setLoadingBtn] = useState(false)
  const pageSize = 50

  const closeModal = () => {
    setOpenEditCategoryCustomer(false)
  }

  const fetchDataCategoryCustomer = (params = {}) => {
    if (loadingModal) return;
    setLoadingModal(true);
    GetExpenseCustomerApi(params).then(res => {
      setDataSource(res.data.results.data_category_customer)
      setTotalPage(Math.floor(res.data.count / pageSize) + 1)
      form.setFieldValue("data_edit", res.data.results.data_category_customer)
      setLoadingModal(false);
    }).catch(err => {
      setLoadingModal(false);
    })
  }

  useEffect(() => {
    if (checkScroll === true && page < totalPage) {
      loadData()
    }
  }, [checkScroll]);

  const loadData = () => {
    if (loadingModal) return;
    setLoadingModal(true);
    GetExpenseCustomerApi({
      id_customer: idCustomer,
      page: page + 1,
      page_size: pageSize
    }).then(res => {
      const arrDara = dataSource.concat(res.data.results.data_category_customer)
      setDataSource(arrDara)
      setLoadingModal(false)
      setPage(page + 1)
      setCheckScroll(false)
    }).catch(err => {
      setLoadingModal(false)
    })
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: "center",
      ellipsis: true,
      width: 40,
      render: (text, value, index) =>
        index + 1
    },
    {
      title: 'Mã ĐTPN',
      dataIndex: 'Mã ĐTPN',
      key: 'Mã ĐTPN',
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record, index) => (
        formInputEdit(index, text, 'Mã ĐTPN', record)
      )
    },
    {
      title: 'Tên đối tượng pháp nhân',
      dataIndex: 'Tên đối tượng pháp nhân',
      key: 'Tên đối tượng pháp nhân',
      align: "left",
      ellipsis: true,
      width: 200,
      render: (text, record, index) => (
        formInputEdit(index, text, 'Tên đối tượng pháp nhân', record)
      )
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'Địa chỉ',
      key: 'Địa chỉ',
      align: "left",
      ellipsis: true,
      width: 300,
      render: (text, record, index) => (
        formInputEdit(index, text, 'Địa chỉ', record)
      )
    },
    {
      title: 'Mã số thuế',
      dataIndex: 'Mã số thuế',
      key: 'Mã số thuế',
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record, index) => (
        formInputEdit(index, text, 'Mã số thuế', record)
      )
    },
    {
      title: 'Điện thoại',
      dataIndex: 'Điện thoại',
      key: 'Điện thoại',
      align: "left",
      ellipsis: true,
      width: 150,
      render: (text, record, index) => (
        formInputEdit(index, text, 'Điện thoại', record)
      )
    },
    {
      title: 'KeyWord',
      dataIndex: 'KeyWord',
      key: 'KeyWord',
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record, index) => (
        formInputEdit(index, text, 'KeyWord', record)
      )
    },
    {
      title: 'Khách hàng',
      dataIndex: 'Khách hàng',
      key: 'Khách hàng',
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record, index) => (
        formInputEdit(index, text, 'Khách hàng', record)
      )
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'Nhà cung cấp',
      key: 'Nhà cung cấp',
      align: "center",
      ellipsis: true,
      width: 150,
      render: (text, record, index) => (
        formInputEdit(index, text, 'Nhà cung cấp', record)
      )
    },
    {
      title: 'Nhân viên',
      dataIndex: 'Nhân viên',
      key: 'Nhân viên',
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record, index) => (
        formInputEdit(index, text, 'Nhân viên', record)
      )
    },
    {
      title: 'Trạng thái',
      dataIndex: 'Trạng thái',
      key: 'Trạng thái',
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record, index) => (
        formInputEdit(index, text, 'Trạng thái', record)
      )
    },
    {
      title: 'Người tạo',
      dataIndex: 'Người tạo',
      key: 'Người tạo',
      align: "center",
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'Ngày tạo',
      key: 'Ngày tạo',
      align: "center",
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Người sửa',
      dataIndex: 'Người sửa',
      key: 'Người sửa',
      align: "center",
      ellipsis: true,
      width: 100
    },
    {
      title: 'Ngày sửa',
      dataIndex: 'Ngày sửa',
      key: 'Ngày sửa',
      align: "center",
      ellipsis: true,
      width: 100
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record, index) => (
        (!listIndexEdit.includes(record.index) && !listIndexFormDelete.includes(record.index)) ?
          <>
            {record.type !== "add" ?
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: "1ch" }}>
                <img onClick={() => showFormEdit(index, record)} style={{ cursor: "pointer" }} src={EditIcon} alt=''></img>
                <img onClick={() => deleteRecord(record['index'], record)} style={{ cursor: "pointer" }} alt='' src={DeleteIcon}></img>
              </div>
              :
              <>
                <div className='item-center'>
                  <Button onClick={() => deleteRecordAdd(index)}><img alt='' src={MinusIcon}></img></Button>
                </div>
              </>
            }
          </>
          : null
      )
    },
  ];

  const showFormEdit = (index, record) => {
    form.setFieldValue(["data_edit", index, "Mã ĐTPN"], record["Mã ĐTPN"])
    form.setFieldValue(["data_edit", index, "Tên đối tượng pháp nhân"], record["Tên đối tượng pháp nhân"])
    form.setFieldValue(["data_edit", index, "Địa chỉ"], record["Địa chỉ"])
    form.setFieldValue(["data_edit", index, "Mã số thuế"], record["Mã số thuế"])
    form.setFieldValue(["data_edit", index, "Điện thoại"], record["Điện thoại"])
    form.setFieldValue(["data_edit", index, "KeyWord"], record["KeyWord"])
    form.setFieldValue(["data_edit", index, "Khách hàng"], record["Khách hàng"])
    form.setFieldValue(["data_edit", index, "Nhà cung cấp"], record["Nhà cung cấp"])
    form.setFieldValue(["data_edit", index, "Nhân viên"], record["Nhân viên"])
    form.setFieldValue(["data_edit", index, "Trạng thái"], record["Trạng thái"])
    form.setFieldValue(["data_edit", index, "index"], record["index"])
    const listIndex = listIndexEdit
    setListIndexEdit([...listIndex, record["index"]])
  }

  const formInputEdit = (index, text, dataIndex, record) => {
    const listRequire = ["mã đtpn", "tên đối tượng pháp nhân"]
    if (record.type === "add") {
      if (listRequire.includes(dataIndex.toLowerCase())) {
        return <Form.Item
          name={["data_add", index, dataIndex]}
          label={""}
          rules={[{ required: true, message: "" }]}
          key={dataIndex}
        >
          <Input defaultValue={text}></Input>
        </Form.Item>
      } else {
        return <Form.Item
          name={["data_add", index, dataIndex]}
          label={""}
          key={dataIndex}
        >
          <Input defaultValue={text}></Input>
        </Form.Item>
      }
    } else
      if (listIndexEdit.includes(record["index"])) {
        if (listRequire.includes(dataIndex.toLowerCase())) {
          return (
            <Form.Item
              name={["data_edit", index, dataIndex]}
              label={""}
              key={dataIndex}
              rules={[{ required: true, message: "" }]}
            >
              <Input defaultValue={text}></Input>
            </Form.Item>
          )
        } else {
          return (
            <Form.Item
              name={["data_edit", index, dataIndex]}
              label={""}
              key={dataIndex}
            >
              <Input defaultValue={text}></Input>
            </Form.Item>
          )
        }
      } else {
        return text
      }
  }

  const deleteRecord = (index, record) => {
    const listIndex = listIndexDelete
    const listIndexForm = listIndexFormDelete
    setListIndexDelete([...listIndex, record['index']])
    setListIndexFormDelete([...listIndexForm, index])
  }

  const deleteRecordAdd = async (index) => {
    const mockData = dataSource
    const datas = form.getFieldValue("data_add")

    mockData.splice(0, datas.length)
    datas.splice(index, 1)

    setDataSource([...datas, ...mockData])
    form.setFieldValue("data_add", datas)


    const dataEdit = form.getFieldValue("data_edit")
    dataEdit.shift()
    form.setFieldValue("data_edit", dataEdit)
  }

  useEffect(() => {
    var tableContent = document.querySelector('.table-customer .ant-table-body');
    tableContent.addEventListener('scroll', (event) => {
      let maxScroll = event.target.scrollHeight - event.target.clientHeight;
      let currentScroll = event.target.scrollTop;
      if (currentScroll === maxScroll - 1) {
        currentScroll = currentScroll + 1
      }

      if (currentScroll === maxScroll) {
        setCheckScroll(true)
      }
    })
    fetchDataCategoryCustomer({ id_customer: idCustomer, page: 1, page_size: pageSize })
  }, []);

  const addFirstItem = () => {
    form.setFieldValue(["data_add", 0, "Mã ĐTPN"], '')
    form.setFieldValue(["data_add", 0, "Tên đối tượng pháp nhân"], '')
    form.setFieldValue(["data_add", 0, "Địa chỉ"], '')
    form.setFieldValue(["data_add", 0, "Mã số thuế"], '')
    form.setFieldValue(["data_add", 0, "Điện thoại"], '')
    form.setFieldValue(["data_add", 0, "KeyWord"], '')
    form.setFieldValue(["data_add", 0, "Khách hàng"], '')
    form.setFieldValue(["data_add", 0, "Nhà cung cấp"], '')
    form.setFieldValue(["data_add", 0, "Nhân viên"], '')
    form.setFieldValue(["data_add", 0, "Trạng thái"], '')
    form.setFieldValue(["data_add", 0, "type"], 'add')
  }

  const addRecord = () => {
    const newData = {
      "Mã ĐTPN": '',
      "Tên đối tượng pháp nhân": '',
      "Địa chỉ": '',
      "Mã số thuế": '',
      "Điện thoại": '',
      "KeyWord": '',
      "Khách hàng": '',
      "Nhà cung cấp": '',
      "Nhân viên": '',
      "Trạng thái": '',
      type: "add",
    };
    let arrData = []
    if (form.getFieldValue("data_add") === undefined) {
      arrData = form.getFieldValue("data_add")
      addFirstItem()
    } else {
      arrData = form.getFieldValue("data_add")
      for (let i = 0; i < arrData.length + 1; i++) {
        if (i === 0) {
          addFirstItem()
        } else {
          form.setFieldValue(["data_add", i, "Mã ĐTPN"], arrData[i - 1]["Mã ĐTPN"])
          form.setFieldValue(["data_add", i, "Tên đối tượng pháp nhân"], arrData[i - 1]["Tên đối tượng pháp nhân"])
          form.setFieldValue(["data_add", i, "Địa chỉ"], arrData[i - 1]["Địa chỉ"])
          form.setFieldValue(["data_add", i, "Mã số thuế"], arrData[i - 1]["Mã số thuế"])
          form.setFieldValue(["data_add", i, "Điện thoại"], arrData[i - 1]["Điện thoại"])
          form.setFieldValue(["data_add", i, "KeyWord"], arrData[i - 1]["KeyWord"])
          form.setFieldValue(["data_add", i, "Khách hàng"], arrData[i - 1]["Khách hàng"])
          form.setFieldValue(["data_add", i, "Nhà cung cấp"], arrData[i - 1]["Nhà cung cấp"])
          form.setFieldValue(["data_add", i, "Nhân viên"], arrData[i - 1]["Nhân viên"])
          form.setFieldValue(["data_add", i, "Trạng thái"], arrData[i - 1]["Trạng thái"])
          form.setFieldValue(["data_add", i, "type"], 'add')
        }
      }
    }

    resizeDataEdit()
    setDataSource([newData, ...dataSource]);
  }

  const resizeDataEdit = () => {
    const dataEdit = form.getFieldValue("data_edit")
    if (listIndexEdit.length > 0) {
      dataEdit.unshift({
        "Mã ĐTPN": '',
        "Tên đối tượng pháp nhân": '',
        "Địa chỉ": '',
        "Mã số thuế": '',
        "Điện thoại": '',
        "KeyWord": '',
        "Khách hàng": '',
        "Nhà cung cấp": '',
        "Nhân viên": '',
        "Trạng thái": '',
      })
    }
    form.setFieldValue('data_edit', dataEdit)
  }

  const onFinish = (values) => {
    const dataEdit = form.getFieldValue("data_edit")
    const dataAdd = form.getFieldValue("data_add")
    setLoadingBtn(true)
    let filterData = null
    let filterDataAdd = []

    if (dataEdit !== undefined) {
      filterData = dataEdit.filter(item => item !== undefined && listIndexEdit.includes(item.index))
    }
    if (dataAdd !== undefined) {
      for (let i = 0; i < dataAdd.length; i++) {
        filterDataAdd.push({
          "#": i + 1,
          "Mã ĐTPN": dataAdd[i]['Mã ĐTPN'],
          "Tên đối tượng pháp nhân": dataAdd[i]['Tên đối tượng pháp nhân'],
          "Địa chỉ": dataAdd[i]['Địa chỉ'],
          "Mã số thuế": dataAdd[i]['Mã số thuế'],
          "Điện thoại": dataAdd[i]['Điện thoại'],
          "KeyWord": dataAdd[i]['KeyWord'],
          "Khách hàng": dataAdd[i]['Khách hàng'],
          "Nhà cung cấp": dataAdd[i]['Nhà cung cấp'],
          "Nhân viên": dataAdd[i]['Nhân viên'],
          "Trạng thái": dataAdd[i]['Trạng thái'],
        })
      }
    }

    var FormData = require("form-data");
    var data = new FormData();

    data.append("id_customer", idCustomer);
    data.append("data_add", JSON.stringify(filterDataAdd));
    data.append("data_edit", JSON.stringify(filterData));
    data.append("list_index_delete", listIndexDelete);
    EditExpenseCustomerApi(data).then(res => {
      openNotificationSweetAlert(SuccessImage, res.data.message)
      setOpenEditCategoryCustomer(false)
      setLoadingBtn(false)
    }).catch(err => {
      openNotificationSweetAlert(ErrorImage, err.data.message)
      setLoadingBtn(false)
    })
  }

  return (
    <>
      <Modal open={open} footer={false} onCancel={closeModal} width={"80%"} style={{ position: "relative" }}>
        <Row>
          <Col span={4} style={{ display: "flex", justifyContent: "flex-start" }}>
          </Col>
          <Col span={16} className='title-table-detail-statement'>DANH MỤC KHÁCH HÀNG</Col>
          <Col span={4}></Col>
        </Row>

        <div style={{ overflowY: 'auto' }}>
          <Button disabled={loadingModal} onClick={addRecord}>Thêm dòng</Button>
          <Form
            onFinish={onFinish}
            form={form}
            className='form-edit-invoice'
          >
            <Table
              className="table-setting-doc table-customer"
              scroll={{
                y: "60vh",
              }}
              loading={loadingModal}
              style={{ paddingTop: "1%", height: "auto" }}
              pagination={false}
              bordered
              dataSource={dataSource}
              columns={columns}
              size='middle'
              rowClassName={(record, index) => {
                if (listIndexFormDelete.includes(record.index)) {
                  return "bg-delete";
                } else {
                  return null;
                }
              }}
            />

            {listIndexEdit.length > 0 || listIndexDelete.length > 0 || (form.getFieldValue('data_add') !== undefined && form.getFieldValue('data_add').length > 0) ?
              <Button
                variant="contained"
                type="success"
                htmlType="submit"
                className='text-btn design-btn-submit'
                style={{ marginTop: 10, float: "right" }}
                loading={loadingBtn}
              >
                Lưu
              </Button>
              : null}
          </Form>
        </div>
      </Modal>
    </>
  )
}

export default ModalEditCategoryCustomer