import { ChangeinforMaster, circularNoURL, editCategoryAccount, editCategoryCustomer, expenseAccount, inforMaster, revenueAccount } from "../constants";
import { authAxios } from "./axiosClient";

export const GetListCircularNoApi = (params = {}) => {
    const url = circularNoURL;
    return authAxios().get(url, { params });
};

// export const GetInforMasterApi = (id, params = {}) => {
//     const url = inforMaster;
//     return authAxios().get(url(id), { params });
// };

export const PutInforMasterApi = (params = {}) => {
    const url = ChangeinforMaster;
    return authAxios().put(url, params);
};
export const GetInforMasterApi = (params = {}) => {
    const url = ChangeinforMaster;
    return authAxios().get(url, params);
};

export const GetCategoryAccountApi = (params = {}) => {
    const url = editCategoryAccount;
    return authAxios().get(url, { params });
};

export const GetRevenueAccountApi = (params = {}) => {
    const url = revenueAccount;
    return authAxios().get(url, { params });
};

export const EditRevenueAccountApi = (params = {}) => {
    const url = revenueAccount;
    return authAxios().put(url, params);
};

export const GetExpenseAccountApi = (params = {}) => {
    const url = expenseAccount;
    return authAxios().get(url, { params });
};

export const EditExpenseAccountApi = (params = {}) => {
    const url = expenseAccount;
    return authAxios().put(url,  params);
};

export const GetExpenseCustomerApi = (params = {}) => {
    const url = editCategoryCustomer;
    return authAxios().get(url, { params });
};

export const EditExpenseCustomerApi = (params = {}) => {
    const url = editCategoryCustomer;
    return authAxios().put(url, params);
};