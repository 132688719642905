// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal.modal-add-customer .ant-modal-close {
  display: none;
}

.ant-modal.modal-add-customer .ant-modal-content {
  padding: 24px 30px 1px;
}

.bg-title {
  background: #76809d26;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.text-title {
  padding: 8px 0px;
  color: #058df4;
  font-size: 20px;
  font-family: var(--global-font)
}

.ant-form-vertical.text-label-add-company .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
  color: var(--text-title-2, #394b76);
  /* font-family: Roboto; */
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: #516583;
  font-weight: bold;
}

.ant-tag-processing {
  color: #058DF4 !important; 
  background: #e6f4ff;
  border-color: #91caff;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/containers/component/manage_company/infor_company/role_admin/InforWithRoleAdmin.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf;AACF;;AAEA;;;EAGE,SAAS;EACT,mCAAmC;EACnC,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;AACA;;;EAGE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,qBAAqB;EACrB,iBAAiB;AACnB","sourcesContent":[".ant-modal.modal-add-customer .ant-modal-close {\r\n  display: none;\r\n}\r\n\r\n.ant-modal.modal-add-customer .ant-modal-content {\r\n  padding: 24px 30px 1px;\r\n}\r\n\r\n.bg-title {\r\n  background: #76809d26;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  border-radius: 5px;\r\n}\r\n\r\n.text-title {\r\n  padding: 8px 0px;\r\n  color: #058df4;\r\n  font-size: 20px;\r\n  font-family: var(--global-font)\r\n}\r\n\r\n.ant-form-vertical.text-label-add-company .ant-form-item-label > label,\r\n.ant-col-24.ant-form-item-label > label,\r\n.ant-col-xl-24.ant-form-item-label > label {\r\n  margin: 0;\r\n  color: var(--text-title-2, #394b76);\r\n  /* font-family: Roboto; */\r\n  font-size: 14px;\r\n  font-style: normal;\r\n  line-height: normal;\r\n}\r\n.ant-tag,\r\n.ant-tag a,\r\n.ant-tag a:hover {\r\n  color: #516583;\r\n  font-weight: bold;\r\n}\r\n\r\n.ant-tag-processing {\r\n  color: #058DF4 !important; \r\n  background: #e6f4ff;\r\n  border-color: #91caff;\r\n  font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
