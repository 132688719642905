import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import SearchIcon from "./../../../../images/image_manage_docs/search.svg";
import DownloadIcon from "./../../../../images/image_manage_docs/DownloadInvoice.svg";
import UploadIcon from "./../../../../images/image_manage_docs/UploadInvoice.svg";
import DetailIcon from "./../../../../images/image_manage_docs/ViewDetail.svg";
import ResetFields from "./../../../../images/image_manage_docs/ResetFieldFilter.svg";

import "./Invoice.css";
import dayjs from "dayjs";

import {
  DownloadInputAllInvoiceApi,
  GetInforInvoiceApi,
} from "../../../../api/InvoiceApi";
import ModalViewDetail from "./ModalViewDetail";
import ModalUpload from "./ModalUpload";
import ContentInvoiceMB from "./mobile/ContentInvoiceMB";
import ModalUploadMobile from "./mobile/ModalUploadMobile";
import ModalViewDetailMB from "./mobile/ModalViewDetailMB";
import { screenMobile } from "../../../../Function";
const { RangePicker } = DatePicker;
const { Option } = Select;

function ContentInvoice({ titleTable, typeDocument, idCustomer, nameCompany }) {
  const [form] = Form.useForm();
  const [datesAccounting, setDatesAccounting] = useState(null);
  const [datesUpload, setDatesUpload] = useState(null);
  const [isOpenModalUploadAll, setIsOpenModalUploadAll] = useState(false);
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [status, setStatus] = useState([]);
  const [idInvoice, setIdInvoice] = useState();
  const [loadingTable, setLoadingTable] = useState(false);
  const [pager, setPager] = useState({
    pageSize: 10,
    count: 0,
    current: 1,
  });
  const [fieldFilter, setFieldFilter] = useState({
    invoice_number: null,
    total_vat_amount: null,
    tax_code_seller: null,
    status: null,
    name_seller: null,
    code_lookup: null,
    type_document: null,
    from_release_date: null,
    to_release_date: null,

    from_create_date: null,
    to_create_date: null,
  });

  const openModalDetail = (record) => {
    setIdInvoice(record.id);
    setIsOpenModalDetail(true);
  };

  const customColorStatus = (text) => {
    if (text.toLowerCase() === "thất bại") {
      return { color: "#E55353", background: "rgba(229, 83, 83, 0.13)" };
    } else if (text.toLowerCase() === "đang xử lý") {
      return { color: "#FAAB35", background: "rgba(250, 171, 53, 0.17)" };
    } else if (text.toLowerCase() === "hoàn thành") {
      return { color: "#2EB85C", background: "rgba(87, 232, 135, 0.15)" };
    } else if (text.toLowerCase() === "đang định khoản") {
      return { color: "#4873DE", background: "rgba(72, 115, 222, 0.13)" };
    }
  };

  const colorCriteria = (text) => {
    if (text !== null && text !== undefined) {
      const value = text.split("/");
      if (parseInt(value[0]) === parseInt(value[1])) {
        return "rgb(46, 184, 92)";
      } else {
        return "rgb(229, 83, 83)";
      }
    }
  };

  const columnsMobile = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "name",
      align: "center",
      ellipsis: true,
      width: 30,
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
    },
    {
      title: "Thao tác",
      dataIndex: "thao_tac",
      key: "thao_tac",
      align: "center",
      ellipsis: true,
      width: 50,
      render: (text, record) => {
        return (
          <img
            style={{ cursor: "pointer" }}
            onClick={() => openModalDetail(record)}
            src={DetailIcon}
            alt=""
          ></img>
        );
      },
    },
    {
      title: "Số hóa đơn",
      dataIndex: "invoice_number",
      key: "invoice_number",
      align: "center",
      ellipsis: true,
      width: 70,
    },

    {
      title: "Mã số thuế",
      dataIndex: "tax_code",
      key: "tax_code",
      align: "center",
      ellipsis: true,
      width: 80,
    },

    {
      title: "Thành tiền",
      dataIndex: "total_vat_amount",
      key: "total_vat_amount",
      align: "center",
      ellipsis: true,
      width: 80,
      render: (text, record) => text.toLocaleString(),
    },

    {
      title: "Định khoản",
      dataIndex: "name_status",
      key: "name_status",
      align: "center",
      ellipsis: true,
      width: 80,
      render: (text, record) => {
        return (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="text-dinh-khoan" style={customColorStatus(text)}>
                {text}
              </p>
            </div>
          </>
        );
      },
    },
  ];

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "name",
      align: "center",
      ellipsis: true,
      width: 50,
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
    },
    {
      title: "Thao tác",
      dataIndex: "thao_tac",
      key: "thao_tac",
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record) => {
        return (
          <img
            style={{ cursor: "pointer" }}
            onClick={() => openModalDetail(record)}
            src={DetailIcon}
            alt=""
          ></img>
        );
      },
    },

    {
      title: "Ngày phát hành",
      dataIndex: "invoice_date",
      key: "invoice_date",
      align: "center",
      ellipsis: true,
      width: 150,
      render: (text, record) =>
        dayjs(text).format("DD-MM-YYYY") !== "Invalid Date"
          ? dayjs(text).format("DD-MM-YYYY")
          : null,
    },

    {
      title: "Ngày tải lên",
      dataIndex: "created_date",
      key: "created_date",
      align: "center",
      ellipsis: true,
      width: 120,
      render: (text, record) =>
        dayjs(text).format("DD-MM-YYYY") !== "Invalid Date"
          ? dayjs(text).format("DD-MM-YYYY")
          : null,
    },

    {
      title: "Số hóa đơn",
      dataIndex: "invoice_number",
      key: "invoice_number",
      align: "center",
      ellipsis: true,
      width: 120,
    },

    {
      title: "NCC / Khách hàng",
      dataIndex: "customer_name",
      key: "customer_name",
      align: "left",
      ellipsis: true,
    },

    {
      title: "Mã số thuế",
      dataIndex: "tax_code",
      key: "tax_code",
      align: "center",
      ellipsis: true,
      width: 150,
    },

    {
      title: "Thành tiền",
      dataIndex: "total_vat_amount",
      key: "total_vat_amount",
      align: "center",
      ellipsis: true,
      width: 150,
      render: (text, record) => text.toLocaleString(),
    },

    {
      title: "Định khoản",
      dataIndex: "name_status",
      key: "name_status",
      align: "center",
      ellipsis: true,
      width: 180,
      render: (text, record) => {
        return (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {text === "Thất bại" ? (
                <Tooltip placement="topRight" title={record.other1}>
                  <p
                    className="text-dinh-khoan"
                    style={customColorStatus(text)}
                  >
                    {text}
                  </p>
                </Tooltip>
              ) : (
                <p className="text-dinh-khoan" style={customColorStatus(text)}>
                  {text}
                </p>
              )}
            </div>
          </>
        );
      },
    },

    {
      title: "Tiêu chí",
      dataIndex: "achieved_criteria",
      key: "achieved_criteria",
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record) => (
        <span style={{ color: colorCriteria(text) }}>{text}</span>
      ),
    },

    {
      title: "HĐĐT",
      dataIndex: "is_electronic",
      key: "is_electronic",
      align: "center",
      ellipsis: true,
      width: 70,
      render: (text, record) => {
        return (
          <Checkbox
            style={{ background: "#fff" }}
            checked={text === true ? true : false}
          ></Checkbox>
        );
      },
    },
  ];

  const fetchInforInvoice = (params = {}) => {
    setLoadingTable(true);
    GetInforInvoiceApi(params)
      .then((res) => {
        setPager({
          current: params.page,
          pageSize: params.page_size,
          count: res.data.count,
        });
        setLoadingTable(false);
        setStatus(res.data.data_status);
        setDataSource(res.data.results);
      })
      .catch((err) => {
        setLoadingTable(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setLoadingTable(true);
    const id = setInterval(() => {
      fetchInforInvoice({
        id_customer: idCustomer,
        page: 1,
        page_size: pager.pageSize,
        type_document: typeDocument,
      });
      clearInterval(id);
    }, 300);
    return () => clearInterval(id);
  }, [pager.pageSize, typeDocument, idCustomer]);

  const disabledDateAccounting = (current) => {
    if (!datesAccounting) {
      return false;
    }
    const tooLate =
      datesAccounting[0] && current.diff(datesAccounting[0], "days") >= 366;
    const tooEarly =
      datesAccounting[1] && datesAccounting[1].diff(current, "days") >= 366;
    return !!tooEarly || !!tooLate;
  };

  const disabledDateUpload = (current) => {
    if (!datesUpload) {
      return false;
    }
    const tooLate =
      datesUpload[0] && current.diff(datesUpload[0], "days") >= 366;
    const tooEarly =
      datesUpload[1] && datesUpload[1].diff(current, "days") >= 366;
    return !!tooEarly || !!tooLate;
  };

  const trimField = (text) => {
    if (text !== undefined && text !== null) {
      return text.trim();
    } else {
      return null;
    }
  };

  const onFinish = (values) => {
    fetchInforInvoice({
      page: 1,
      page_size: pager.pageSize,
      id_customer: idCustomer,
      invoice_number: trimField(values.invoice_number),
      total_vat_amount: values.total_vat_amount,
      tax_code_seller: trimField(values.tax_code_seller),
      status: JSON.stringify(values.status),
      name_seller: trimField(values.name_seller),
      code_lookup: trimField(values.code_lookup),
      type_document: typeDocument,

      from_release_date:
        values.release_date !== undefined && values.release_date !== null
          ? dayjs(values.release_date[0]).format("YYYY-MM-DD")
          : null,
      to_release_date:
        values.release_date !== undefined && values.release_date !== null
          ? dayjs(values.release_date[1]).format("YYYY-MM-DD")
          : null,

      from_create_date:
        values.create_date !== undefined && values.create_date !== null
          ? dayjs(values.create_date[0]).format("YYYY-MM-DD")
          : null,
      to_create_date:
        values.create_date !== undefined && values.create_date !== null
          ? dayjs(values.create_date[1]).format("YYYY-MM-DD")
          : null,
    });

    setFieldFilter({
      invoice_number: trimField(values.invoice_number),
      total_vat_amount: values.total_vat_amount,
      tax_code_seller: trimField(values.tax_code_seller),
      status: JSON.stringify(values.status),
      name_seller: trimField(values.name_seller),
      code_lookup: trimField(values.code_lookup),
      type_document: typeDocument,

      from_release_date:
        values.release_date !== undefined && values.release_date !== null
          ? dayjs(values.release_date[0]).format("YYYY-MM-DD")
          : null,
      to_release_date:
        values.release_date !== undefined && values.release_date !== null
          ? dayjs(values.release_date[1]).format("YYYY-MM-DD")
          : null,

      from_create_date:
        values.create_date !== undefined && values.create_date !== null
          ? dayjs(values.create_date[0]).format("YYYY-MM-DD")
          : null,
      to_create_date:
        values.create_date !== undefined && values.create_date !== null
          ? dayjs(values.create_date[1]).format("YYYY-MM-DD")
          : null,
    });
    document.activeElement.blur();
  };

  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = 10;
    setPager({ ...currentPager });
    fetchInforInvoice({
      id_customer: idCustomer,
      page: pagination.current,
      page_size: pagination.pageSize,
      type_document: typeDocument,
      invoice_number: fieldFilter.invoice_number,
      total_vat_amount: fieldFilter.total_vat_amount,
      tax_code_seller: fieldFilter.tax_code_seller,
      status: fieldFilter.status,
      name_seller: fieldFilter.name_seller,
      code_lookup: fieldFilter.code_lookup,
      from_release_date: fieldFilter.from_release_date,
      to_release_date: fieldFilter.to_release_date,
      from_create_date: fieldFilter.from_create_date,
      to_create_date: fieldFilter.to_create_date,
    });
  };

  const openModalUploadAll = () => {
    setIsOpenModalUploadAll(true);
  };

  const closeModalUploadAll = () => {
    setIsOpenModalUploadAll(false);
  };

  const resetData = () => {
    form.resetFields();
    fetchInforInvoice({
      page: 1,
      page_size: pager.pageSize,
      id_customer: idCustomer,
      type_document: typeDocument,
    });

    setFieldFilter({
      invoice_number: null,
      total_vat_amount: null,
      tax_code_seller: null,
      status: null,
      name_seller: null,
      code_lookup: null,
      type_document: null,
      from_release_date: null,
      to_release_date: null,

      from_create_date: null,
      to_create_date: null,
    });
  };

  const downloadInvoiceAll = () => {
    // Dùng để tải tất cả thông tin đầu vào hóa đơn dạng file zip: ảnh/bản thể hiện HD, file excel HD theo mẫu
    const fnFileDownload = require("js-file-download");
    const filename =
      "ExportAllInvoiceWithTemplate_" +
      dayjs(new Date()).format("DDMMYYYY") +
      ".xlsx";
    DownloadInputAllInvoiceApi({
      id_customer: idCustomer,
    })
      .then((res) => {
        fnFileDownload(res.data, filename);
      })
      .catch((err) => {});
  };

  return (
    <div style={{ width: "100%" }}>
      {screenMobile === false ? (
        <>
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
            initialValues={{
              remember: true,
            }}
            className="text-label-manage-docs"
          >
            <Row>
              <Col span={6}>
                <Form.Item label="Ngày phát hành" name="release_date">
                  <RangePicker
                    disabledDate={disabledDateAccounting}
                    onCalendarChange={(val) => {
                      setDatesAccounting(val);
                    }}
                    locale={{
                      lang: {
                        locale: "en_US",
                        rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
                        yearFormat: "YYYY",
                        shortMonths: [
                          "Tháng 1",
                          "Tháng 2",
                          "Tháng 3",
                          "Tháng 4",
                          "Tháng 5",
                          "Tháng 6",
                          "Tháng 7",
                          "Tháng 8",
                          "Tháng 9",
                          "Tháng 10",
                          "Tháng 11",
                          "Tháng 12",
                        ],
                        shortWeekDays: [
                          "CN",
                          "T2",
                          "T3",
                          "T4",
                          "T5",
                          "T6",
                          "T7",
                        ],
                        ok: "OK",
                        monthBeforeYear: true,
                      },
                    }}
                    style={{ width: "100%" }}
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item label="NCC / Khách hàng" name="name_seller">
                  <Input
                    onBlur={(e) =>
                      form.setFieldValue("name_seller", e.target.value.trim())
                    }
                    placeholder="Nhập nhà cung cấp"
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item label="Trạng thái" name="status">
                  <Select
                    id="code_district"
                    size={"middle"}
                    allowClear
                    mode="multiple"
                    className="SelectTTDN"
                    placeholder="Chọn trạng thái"
                    maxTagCount={"responsive"}
                    optionFilterProp="children"
                  >
                    {status.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item label="Mã chứng từ" name="code_lookup">
                  <Input
                    onBlur={(e) =>
                      form.setFieldValue("code_lookup", e.target.value.trim())
                    }
                    placeholder="Nhập mã tra cứu"
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={2} offset={1}>
                <Form.Item label=" ">
                  <Button className="btn-search" htmlType="submit">
                    <img src={SearchIcon} alt=""></img>
                  </Button>
                  <Button
                    className="btn-search"
                    htmlType="button"
                    onClick={resetData}
                    style={{ marginRight: "10%" }}
                  >
                    <img src={ResetFields} alt=""></img>
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Ngày tải lên" name="create_date">
                  <RangePicker
                    disabledDate={disabledDateUpload}
                    onCalendarChange={(val) => {
                      setDatesUpload(val);
                    }}
                    locale={{
                      lang: {
                        locale: "en_US",
                        rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
                        yearFormat: "YYYY",
                        shortMonths: [
                          "Tháng 1",
                          "Tháng 2",
                          "Tháng 3",
                          "Tháng 4",
                          "Tháng 5",
                          "Tháng 6",
                          "Tháng 7",
                          "Tháng 8",
                          "Tháng 9",
                          "Tháng 10",
                          "Tháng 11",
                          "Tháng 12",
                        ],
                        shortWeekDays: [
                          "CN",
                          "T2",
                          "T3",
                          "T4",
                          "T5",
                          "T6",
                          "T7",
                        ],
                        ok: "OK",
                        monthBeforeYear: true,
                      },
                    }}
                    style={{ width: "100%" }}
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>
              </Col>

              <Col span={4} offset={1}>
                <Form.Item label="Mã số thuế" name="tax_code_seller">
                  <Input
                    onBlur={(e) =>
                      form.setFieldValue(
                        "tax_code_seller",
                        e.target.value.trim()
                      )
                    }
                    placeholder="Nhập mã số thuế"
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item label="Thành tiền" name="total_vat_amount">
                  <InputNumber
                    min={0}
                    size="middle"
                    controls={false}
                    placeholder="Nhập thành tiền"
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    onKeyPress={(e) => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (charCode < 48 || charCode > 57) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item label="Số hóa đơn" name="invoice_number">
                  <Input
                    onBlur={(e) =>
                      form.setFieldValue(
                        "invoice_number",
                        e.target.value.trim()
                      )
                    }
                    placeholder="Nhập số hóa đơn"
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div style={{ width: "100%", position: "relative" }}>
            <Row>
              <Col span={8}></Col>
              <Col span={8} className="title-table-invoice-all">
                {titleTable}
              </Col>
              <Col
                span={8}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  onClick={downloadInvoiceAll}
                  className="btn-invoice-all"
                  style={{
                    marginRight: "3%",
                    background: "rgba(50, 31, 219, 0.12)",
                    border: "unset",
                  }}
                  title="Tải xuống các hóa đơn được tải lên trong 1 năm từ thời điểm hiện tại"
                >
                  <img
                    alt=""
                    src={DownloadIcon}
                    style={{ marginRight: "3%" }}
                  ></img>
                  <span style={{ color: "rgb(85,69,225)", fontWeight: "bold" }}>
                    TẢI XUỐNG
                  </span>
                </Button>
                <Button
                  onClick={openModalUploadAll}
                  className="btn-invoice-all"
                  style={{
                    background: "rgba(58, 183, 176, 0.15)",
                    border: "unset",
                  }}
                >
                  <img
                    alt=""
                    src={UploadIcon}
                    style={{ marginRight: "3%" }}
                  ></img>
                  <span
                    style={{ color: "rgb(58,183,176)", fontWeight: "bold" }}
                  >
                    TẢI LÊN
                  </span>
                </Button>
              </Col>
            </Row>

            <Table
              // rowSelection = {rowSelection}
              size="small"
              columns={columns}
              dataSource={dataSource}
              onChange={handleChange}
              style={{ marginTop: "1%", fontSize: 14, position: "absolute" }}
              className="table-invoice-all"
              bordered
              scroll={{
                y: window.screen.availHeight >= 830 ? "40vh" : "36vh",
                x: 1400,
              }}
              loading={loadingTable}
              pagination={{
                current: pager.current,
                pageSize: pager.pageSize,
                total: pager.count,
                showSizeChanger: false,
                showTotal: (total, range) => `Tổng cộng: ${total}`,
              }}
            />
          </div>
          {isOpenModalUploadAll === true ? (
            <ModalUpload
              open={isOpenModalUploadAll}
              close={closeModalUploadAll}
              nameCompany={nameCompany}
              idCustomer={idCustomer}
            />
          ) : null}
          {isOpenModalDetail === true ? (
            <ModalViewDetail
              isOpenModalDetail={isOpenModalDetail}
              setIsOpenModalDetail={setIsOpenModalDetail}
              idUser={idCustomer}
              idInvoice={idInvoice}
            />
          ) : null}
        </>
      ) : (
        <>
          <ContentInvoiceMB
            titleTable={titleTable}
            dataSource={dataSource}
            openModalUploadAll={openModalUploadAll}
            handleChange={handleChange}
            pager={pager}
            columns={columnsMobile}
            fetchInforInvoice={fetchInforInvoice}
            typeDocument={typeDocument}
            disabledDateAccounting={disabledDateAccounting}
            setDatesAccounting={setDatesAccounting}
            status={status}
            disabledDateUpload={disabledDateUpload}
            setDatesUpload={setDatesUpload}
            setFieldFilter={setFieldFilter}
            loadingTable={loadingTable}
          />
          <ModalUploadMobile
            open={isOpenModalUploadAll}
            close={closeModalUploadAll}
          />

          {isOpenModalDetail === true ? (
            <ModalViewDetailMB
              isOpenModalDetail={isOpenModalDetail}
              setIsOpenModalDetail={setIsOpenModalDetail}
              idUser={idCustomer}
              idInvoice={idInvoice}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default ContentInvoice;
