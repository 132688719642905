import React, { useEffect, useState } from 'react'
import "./Invoice.css"
import { Container } from '@mui/material'
import { Row, Select, Tabs } from 'antd';
import ContentInvoice from './ContentInvoice';
import { GetListTaxAgent } from '../../../../api/manageInforUser';
import { useSelector } from 'react-redux';
const { Option } = Select;

function Invoice() {
  const [keyTab, setKeyTab] = useState("1")
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const listRole = ["Admin Tax", "Admin Manager"]
  const [dataTaxAgent, setDataTaxAgent] = useState([])
  const [idCustomer, setIdCustomer] = useState(null)
  const [nameCompany, setNameCompany] = useState("")
  const onChange = (key) => {
    setKeyTab(key)
  };

  const chooseIdCustomer = (value, name) => {
    setIdCustomer(value)
    if (name !== "") {
      if(typeof name === "string") {
        setNameCompany(name)
      } else if (typeof name === "object") {
        setNameCompany(name.children)
      }
    } else {
      const data = dataTaxAgent.filter(item => item.id === value)
      setNameCompany(data[0].name_organization)
    }
  };

  const items = [
    {
      key: '1',
      label: 'Tất cả',
      children:
        <ContentInvoice
          keyTab={"1"}
          titleTable={"DANH SÁCH HÓA ĐƠN"}
          typeDocument={undefined}
          idCustomer={idCustomer}
          nameCompany={nameCompany}
        />,
    },
    {
      key: '2',
      label: 'Mua vào',
      children:
        <ContentInvoice
          keyTab={"2"}
          titleTable={"DANH SÁCH HÓA ĐƠN MUA VÀO"}
          typeDocument={"HDMV"}
          idCustomer={idCustomer}
          nameCompany={nameCompany}
        />,
    },
    {
      key: '3',
      label: 'Bán ra',
      children:
        <ContentInvoice
          keyTab={"3"}
          titleTable={"DANH SÁCH HÓA ĐƠN BÁN RA"}
          typeDocument={"HDBR"}
          idCustomer={idCustomer}
          nameCompany={nameCompany}
        />,
    },
  ];

  const fetchListTaxAgent = () => {
    GetListTaxAgent().then(res => {
      const data = res.data.filter(item => item.id === res.data[0].id)
      setDataTaxAgent(res.data)
      chooseIdCustomer(res.data[0].id, data[0].name_organization)
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    if (listRole.includes(userInfo.role_name)) {
      fetchListTaxAgent();
    } else {
      setNameCompany(userInfo.customer_name)
      setIdCustomer(userInfo.customer_id)
    }
  }, []);

  const checkRoleAdminTax = userInfo.role_id === 4 || userInfo.role_id === 3

  return (
    <>
      <Container maxWidth="false" style={{ backgroundColor: "#fff", width: "97%", marginTop: "1.2%", height: "88vh" }}>
        {checkRoleAdminTax === true && dataTaxAgent.length > 0 ?
          <Row style={{ paddingTop: 10, display: "flex" }}>
            <span className='text-choose-company'>DOANH NGHIỆP : </span>
            <Select
              size={"middle"}
              style={{ width: "230px", float: "left", marginLeft: "12px" }}
              placeholder="Chọn doanh nghiệp"
              showSearch
              className='choose-company'
              optionFilterProp="children" // phải có mới search đc
              onChange={chooseIdCustomer}
              defaultValue={dataTaxAgent[0].id}
            >
              {dataTaxAgent.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name_organization}
                </Option>
              ))}
            </Select>
          </Row>
          : null}
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} activeKey={keyTab}>
        </Tabs>
      </Container>
    </>
  )
}

export default Invoice