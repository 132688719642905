import React from 'react'
import { Button as ButtonMui } from '@mui/material';
import IconDownload from "../../../../images/Download.svg";
import "./CategoryMaster.css"

function BtnDownload({ url }) {
    return (
        <>
            <a
                download
                href={url}
                style={{
                    display: "flex",
                    alignItem: "center",
                    justifyContent: "center",
                }}
            >
                <ButtonMui
                    size="small"
                    className='none-bg'
                    component="label"
                    style={{ height: "29px" }}
                >
                    <div class="iconDiv" tooltip={'Tải xuống'} tabindex="0">
                        <div class="iconSVG">
                            <img src={IconDownload} style={{ cursor: "pointer", paddingTop: "9px" }} alt=''></img>
                        </div>
                    </div>
                </ButtonMui>
            </a>
        </>
    )
}

export default BtnDownload