// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-user {
  border: 1px solid #a3b3b8;
  /* display: grid; */
  row-gap: 1ch;
  overflow-y: auto;
  height: 75vh;
  border-radius: 10px;
  padding-bottom: 4%;
  align-items: center;
  box-shadow: rgb(0 0 0 / 63%) 0px 3px 8px;
  padding: 0px 10px 4%;
  background-color: #fff;
}

.list-user .box-search-customer {
  height: 6vh;
  border-bottom: 1px solid #a3b3b8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
}

.select-infor-user.ant-select-disabled.ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  color: black;
  background: rgba(0, 0, 0, 0.04);
  cursor: not-allowed;
}

.ant-input-disabled,
.ant-input[disabled] {
  color: black;
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.search-user {
  width: 30%;
  float: left;
}

@media only screen and (max-width: 1024px) {
  .search-user {
    width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/containers/component/manage_user/infor_user/InforUser.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,wCAAwC;EACxC,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,gCAAgC;EAChC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,MAAM;EACN,WAAW;EACX,gBAAgB;AAClB;;AAEA;;;;EAIE,YAAY;EACZ,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;;EAEE,YAAY;EACZ,qCAAqC;EACrC,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".list-user {\r\n  border: 1px solid #a3b3b8;\r\n  /* display: grid; */\r\n  row-gap: 1ch;\r\n  overflow-y: auto;\r\n  height: 75vh;\r\n  border-radius: 10px;\r\n  padding-bottom: 4%;\r\n  align-items: center;\r\n  box-shadow: rgb(0 0 0 / 63%) 0px 3px 8px;\r\n  padding: 0px 10px 4%;\r\n  background-color: #fff;\r\n}\r\n\r\n.list-user .box-search-customer {\r\n  height: 6vh;\r\n  border-bottom: 1px solid #a3b3b8;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  position: sticky;\r\n  top: 0;\r\n  z-index: 10;\r\n  background: #fff;\r\n}\r\n\r\n.select-infor-user.ant-select-disabled.ant-select:not(\r\n    .ant-select-customize-input\r\n  )\r\n  .ant-select-selector {\r\n  color: black;\r\n  background: rgba(0, 0, 0, 0.04);\r\n  cursor: not-allowed;\r\n}\r\n\r\n.ant-input-disabled,\r\n.ant-input[disabled] {\r\n  color: black;\r\n  background-color: rgba(0, 0, 0, 0.04);\r\n  border-color: #d9d9d9;\r\n  box-shadow: none;\r\n  cursor: not-allowed;\r\n  opacity: 1;\r\n}\r\n\r\n.search-user {\r\n  width: 30%;\r\n  float: left;\r\n}\r\n\r\n@media only screen and (max-width: 1024px) {\r\n  .search-user {\r\n    width: 80%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
