import { Button, Col, Collapse, Form, Input, InputNumber, Row, Table } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import BoxIcon from "../../../../../images/image_manage_docs/BoxInforImage.svg"
import { PutInvoiceApi } from '../../../../../api/InvoiceApi'
import { openNotificationSweetAlert } from '../../../../../Function'
import ErrorImage from "../../../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "../../../../../images/SuccessNotiIcon.svg"
import MinusIcon from "../../../../../images/MinusIcon.svg"


function CollapseEditScreenMB({ dataDetail, setOpenFormEdit, openFormEdit }) {
    const [form] = Form.useForm()
    const labelCollapse = (text) => {
        return (
            <>
                <span style={{ columnGap: 8 }}><img alt='' src={BoxIcon}></img>{text}</span>
            </>
        )
    }
    const typeDocument = () => {
        if (dataDetail !== undefined && dataDetail !== null) {
            if (dataDetail.type_document === "HDMV") {
                return "(Mua vào)"
            } else if (dataDetail.type_document === "HDBR") {
                return "(Bán ra)"
            }
        } else {
            return null
        }
    }

    const items = [
        {
            key: '1',
            label: labelCollapse(`Thông tin hóa đơn ${typeDocument()}`),
            children: <BoxInforInvoice dataDetail={dataDetail} form={form} />,
        },
        {
            key: '2',
            label: labelCollapse("Thông tin nhà cung cấp"),
            children: <BoxInforSupplier dataDetail={dataDetail} form={form} />,
        },
        {
            key: '3',
            label: labelCollapse("Thông tin khách hàng"),
            children: <BoxInforCustomer dataDetail={dataDetail} form={form} />,
        },
        {
            key: '4',
            label: labelCollapse("Chi tiết sản phẩm"),
            children: <DetailProduct dataDetail={dataDetail} form={form} />,
        },
    ];

    const onFinish = (values) => {
        var FormData = require("form-data");
        var data = new FormData();
        data.append("invoice_id", dataDetail.invoice_id);
        data.append("file_invoice", dataDetail.file_invoice[0].name);
        data.append("dataset_name", dataDetail.dataset_name);
        data.append("type_criteria", dataDetail.type_criteria);
        data.append("type_document", dataDetail.type_document);

        data.append("form", values.form);
        data.append("symbol", values.symbol);
        data.append("invoice_number", values.invoice_number);

        data.append("tax_code_seller", values.tax_code_seller);
        data.append("address_seller", values.address_seller);
        data.append("name_seller", values.name_seller);
        data.append("phone_seller", values.phone_seller);

        data.append("tax_code_buyer", values.tax_code_buyer);
        data.append("address_buyer", values.address_buyer);
        data.append("name_buyer", values.name_buyer);
        data.append("phone_buyer", values.phone_buyer);

        data.append("list_products", JSON.stringify(values.list_products));

        data.append("total_discount_amount", values.total_discount_amount);
        data.append("total_money_without_vat", values.total_money_without_vat);
        data.append("total_vat_money", values.total_vat_money);
        data.append("total_vat_amount", values.total_vat_amount);

        PutInvoiceApi(data).then(res => {
            openNotificationSweetAlert(SuccessImage, res.data.message)
            setOpenFormEdit(false)
        }).catch(err => {
            openNotificationSweetAlert(ErrorImage, err.data.message)
            console.log(err)
        })
    }

    useEffect(() => {
        if (openFormEdit === true) {
            form.setFieldsValue({
                form: dataDetail.form,
                symbol: dataDetail.symbol,
                invoice_number: dataDetail.invoice_number,

                tax_code_seller: dataDetail.invoice_seller.tax_code_seller,
                address_seller: dataDetail.invoice_seller.address_seller,
                name_seller: dataDetail.invoice_seller.name_seller,
                phone_seller: dataDetail.invoice_seller.phone_seller,

                tax_code_buyer: dataDetail.invoice_buyer.tax_code_buyer,
                address_buyer: dataDetail.invoice_buyer.address_buyer,
                name_buyer: dataDetail.invoice_buyer.name_buyer,
                phone_buyer: dataDetail.invoice_buyer.phone_buyer,

                total_discount_amount: dataDetail.total_discount_amount,
                total_money_without_vat: dataDetail.total_money_without_vat,
                total_vat_money: dataDetail.total_vat_money,
                total_vat_amount: dataDetail.total_vat_amount,

                list_products: dataDetail.list_products,
            })
        }
    }, [openFormEdit]);

    const handleCancel = () => {
        setOpenFormEdit(false)
    }

    return (
        <>
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
                className='form-edit-invoice'
                autoComplete="off"
                initialValues={{
                    remember: true,
                }}
            >

                <Collapse className='collapse-mobile' expandIconPosition="end" size='small' items={items} defaultActiveKey={['1', '2', '3', '4']} />
                <Col style={{ display: "flex", justifyContent: "flex-end", marginTop: "2.5%" }}>
                    <Button
                        // variant="contained"
                        // type="success"
                        onClick={handleCancel}
                        htmlType="button"
                        className='text-btn design-btn-cancel'
                    >
                        Hủy
                    </Button>
                    <Button
                        variant="contained"
                        type="success"
                        htmlType="submit"
                        className='text-btn design-btn-submit'
                    >
                        Lưu
                    </Button>
                </Col>
            </Form>
        </>
    )
}

const BoxInforInvoice = ({ dataDetail, form }) => {
    const formatDate = (text) => {
        if (text !== undefined && text !== null) {
            return dayjs(text).format("DD-MM-YYYY")
        } else {
            return null;
        }
    }

    return (
        <>
            {dataDetail &&
                <Row className='box-infor-invoice' style={{ rowGap: 8 }}>
                    <Col span={12}>
                        <Col span={22}>
                            <Form.Item
                                name="form"
                                label="Mẫu số: "
                            >
                                <Input defaultValue={dataDetail.form}></Input>
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col span={12}>
                        <Col span={24}>
                            <Form.Item
                                name="invoice_signed_date"
                                label="Ngày ký: "
                            >
                                <Input disabled defaultValue={formatDate(dataDetail.invoice_signed_date)}></Input>
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col span={12}>
                        <Col span={22}>
                            <Form.Item
                                name="symbol"
                                label="Ký hiệu: "
                            >
                                <Input defaultValue={dataDetail.symbol}></Input>
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col span={12}>
                        <Col span={24}>
                            <Form.Item
                                name="invoice_signed_date"
                                label="Ngày phát hành:"
                            >
                                <Input disabled defaultValue={formatDate(dataDetail.invoice_date)}></Input>
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col span={12}>
                        <Col span={22}>
                            <Form.Item
                                name="invoice_number"
                                label="Số hóa đơn: "
                            >
                                <Input
                                    type='number'
                                    defaultValue={dataDetail.invoice_number}
                                    onBlur={(e) => {
                                        form.setFieldsValue({ invoice_number: e.target.value.trim() });
                                    }}
                                    onKeyPress={(e) => {
                                        const charCode = e.which
                                            ? e.which
                                            : e.keyCode;
                                        if (charCode < 48 || charCode > 57) {
                                            e.preventDefault();
                                        }
                                    }}
                                ></Input>                            </Form.Item>
                        </Col>
                    </Col>
                    <Col span={12}>
                        <Col span={24}>
                            <Form.Item
                                name="invoice_signed_date"
                                label="Ngày tải lên: "
                            >
                                <Input disabled defaultValue={formatDate(dataDetail.created_date)}></Input>
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col span={24}>
                        <Col span={24}>
                            <Form.Item
                                name="trader_organization"
                                label="Tổ chức sở hữu hóa đơn: "
                            >
                                <Input disabled defaultValue={dataDetail.trader_organization.name}></Input>
                            </Form.Item>
                        </Col>
                    </Col>
                </Row>
            }
        </>
    )
}

const BoxInforSupplier = ({ dataDetail, form }) => {
    return (
        <>
            {dataDetail &&
                <Row className='box-infor-supplier' style={{ rowGap: 8 }}>
                    <Col span={24}>
                        <Form.Item
                            name="name_seller"
                            label="Tên: "
                        >
                            <Input defaultValue={dataDetail.invoice_seller.name_seller}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="tax_code_seller"
                            label="Mã số thuế: "
                            rules={[
                                { 
                                    pattern: /^[0-9\\-]+$/,
                                    message: `Chỉ được nhập số và dấu "-"`,
                                },
                                {
                                    min: 2,
                                    message: "Phải nhập tối thiểu 2 kí tự",
                                },
                            ]}
                        >
                            <Input
                                defaultValue={dataDetail.invoice_seller.tax_code_seller}
                                onBlur={(e) => {
                                    form.setFieldsValue({ tax_code_seller: e.target.value.trim() });
                                }}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="address_seller"
                            label="Địa chỉ: "
                        >
                            <Input defaultValue={dataDetail.invoice_seller.address_seller}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="phone_seller"
                            label="Số điện thoại: "
                        >
                            <Input
                                type='number'
                                defaultValue={dataDetail.invoice_seller.phone_seller}
                                onBlur={(e) => {
                                    form.setFieldsValue({ phone_seller: e.target.value.trim() });
                                }}
                                onKeyPress={(e) => {
                                    const charCode = e.which
                                        ? e.which
                                        : e.keyCode;
                                    if (charCode < 48 || charCode > 57) {
                                        e.preventDefault();
                                    }
                                }}
                            ></Input>                        </Form.Item>
                    </Col>
                </Row>
            }
        </>
    )
}

const BoxInforCustomer = ({ dataDetail }) => {
    return (
        <>
            {dataDetail &&
                <Row className='box-infor-supplier' style={{ rowGap: 8 }}>
                    <Col span={24}>
                        <Form.Item
                            name="name_buyer"
                            label="Tên: "
                        >
                            <Input defaultValue={dataDetail.invoice_buyer.name_buyer}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="tax_code_buyer"
                            label="Mã số thuế: "
                        >
                            <Input defaultValue={dataDetail.invoice_buyer.tax_code_buyer}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="address_buyer"
                            label="Địa chỉ: "
                        >
                            <Input defaultValue={dataDetail.invoice_buyer.address_buyer}></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="phone_buyer"
                            label="Số điện thoại: "
                        >
                            <Input defaultValue={dataDetail.invoice_buyer.phone_buyer}></Input>
                        </Form.Item>
                    </Col>
                </Row>
            }
        </>
    )
}

const DetailProduct = ({ dataDetail, form }) => {
    const [dataSource, setDataSource] = useState(dataDetail && dataDetail.list_products)
    const ConvertLocaleString = (text) => {
        if (text !== undefined && text !== null) {
            return text.toLocaleString()
        } else {
            return text
        }
    }

    const customInputNumber = (text) => {
        return (
            <>
                <InputNumber
                    formatter={(value) =>
                        `${value}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                        )
                    }
                    onKeyPress={(e) => {
                        const charCode = e.which
                            ? e.which
                            : e.keyCode;
                        if (charCode < 48 || charCode > 57) {
                            e.preventDefault();
                        }
                    }}
                    defaultValue={ConvertLocaleString(text)}
                    style={{ width: "100%" }}
                    min={0}
                    controls={false}
                />
            </>
        )
    }

    const deleteRecord = (index) => {
        if (dataSource.length > 1) {
            const datas = dataSource.slice(0, index).concat(dataSource.slice(index + 1))
            setDataSource(datas)
            form.setFieldValue("list_products", datas)
        } else {
            openNotificationSweetAlert(ErrorImage, "Bảng chi tiết sản phẩm không được trống")
        }
    }

    const columns = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 50,
            align: "center",
            render: (text, record, index) =>
                index + 1,
        },
        {
            title: 'Mặt hàng',
            dataIndex: 'item_name',
            key: 'item_name',
            width: 250,
            align: "center",
            render: (text, record, index) => <Form.Item rules={[{ required: true, message: "" }]} name={["list_products", index, "item_name"]} label={""} >
                <Input defaultValue={text}></Input>
            </Form.Item>
        },
        {
            title: 'Đơn vị',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: 100,
            align: "center",
            render: (text, record, index) => <Form.Item name={["list_products", index, "unit_name"]} label={""} >
                <Input defaultValue={text}></Input>
            </Form.Item>
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 100,
            align: "center",
            render: (text, record, index) => <Form.Item name={["list_products", index, "quantity"]} label={""} >
                {customInputNumber(text)}
            </Form.Item>
        },
        {
            title: 'Đơn giá',
            dataIndex: 'unit_price',
            key: 'unit_price',
            width: 120,
            align: "center",
            render: (text, record, index) => <Form.Item name={["list_products", index, "unit_price"]} label={""} >
                {customInputNumber(text)}
            </Form.Item>
        },
        {
            title: 'Tiền hàng',
            dataIndex: 'amount_without_vat',
            key: 'amount_without_vat',
            width: 120,
            align: "center",
            render: (text, record, index) => <Form.Item name={["list_products", index, "amount_without_vat"]} label={""} >
                {customInputNumber(text)}

            </Form.Item>
        },
        {
            title: 'Tiền chiết khấu',
            dataIndex: 'discount_amount',
            key: 'discount_amount',
            align: "center",
            width: 100,
            render: (text, record, index) => <Form.Item name={["list_products", index, "discount_amount"]} label={""} >
                {customInputNumber(text)}

            </Form.Item>
        },
        {
            title: 'Thuế suất',
            dataIndex: 'vat_percentage',
            key: 'vat_percentage',
            width: 100,
            align: "center",
            render: (text, record, index) => <Form.Item name={["list_products", index, "vat_percentage"]} label={""} >
                <InputNumber
                    formatter={(value) =>
                        `${value}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                        )
                    }
                    onKeyPress={(e) => {
                        const charCode = e.which
                            ? e.which
                            : e.keyCode;
                        if (charCode < 48 || charCode > 57) {
                            e.preventDefault();
                        }
                    }}
                    defaultValue={ConvertLocaleString(text)}
                    style={{ width: "100%" }}
                    min={0}
                    controls={false}
                    suffix="%"
                />
            </Form.Item>
        },
        {
            title: 'Tiền thuế',
            dataIndex: 'amount_vat',
            key: 'amount_vat',
            width: 120,
            align: "center",
            render: (text, record, index) =>
                <Form.Item name={["list_products", index, "amount_vat"]} label={""} >
                    {customInputNumber(text)}
                </Form.Item>
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: 40,
            render: (text, record, index) =>
                <div className='item-center'>
                    <Button onClick={() => deleteRecord(index)}><img alt='' src={MinusIcon}></img></Button>
                </div>
        },
    ];

    const showFooter = () => {
        return (
            <>
                <Row className='box-detail-product' style={{ width: "100%" }}>
                    <Col span={24}>
                        <Form.Item name={"total_money_without_vat"} label={"Tổng tiền hàng:"} >
                            {customInputNumber(ConvertLocaleString(dataDetail.total_money_without_vat))}
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name={"total_discount_amount"} label={"Tổng tiền chiết khấu:"} >
                            {customInputNumber(ConvertLocaleString(dataDetail.total_discount_amount))}
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name={"total_vat_money"} label={"Tổng thuế GTGT:"} >
                            {customInputNumber(ConvertLocaleString(dataDetail.total_vat_money))}
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name={"total_vat_amount"} label={"Tổng tiền thanh toán:"} >
                            {customInputNumber(ConvertLocaleString(dataDetail.total_vat_amount))}
                        </Form.Item>
                    </Col>
                </Row>
            </>
        )
    }

    const addRow = () => {
        let valueKey = dataSource[dataSource.length - 1].key
        const newData = {
            key: valueKey + 1,
            item_name: '',
            unit_name: '', // Set default values as needed
            quantity: '',
            unit_price: '',
            amount_without_vat: '',
            discount_amount: '',
            vat_percentage: '',
            amount_vat: '',
        };
        setDataSource([...dataSource, newData]);
    };

    return (
        <>
            <div>
                {dataDetail &&
                    <>
                        <Button onClick={addRow} style={{ padding: "0px 6px", marginBottom: 10, float: "right" }}>
                            Thêm dòng
                        </Button>
                        <Table
                            scroll={{
                                x: 1300
                            }}
                            size='small'
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                            footer={() => showFooter()}
                            className='table-detail-product'
                            style={{ width: "100%", marginRight: "1%" }}
                        />
                    </>
                }
            </div>
        </>
    )
}

export default CollapseEditScreenMB