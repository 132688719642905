
import React, { useEffect, useState } from "react";
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
    SubMenu
} from "react-pro-sidebar";

import { GoCircle } from "react-icons/go";
import "./SideBar.css"
import "react-pro-sidebar/dist/css/styles.css";
import Logo from "../../images/LogoAI.svg"
import LogoRS from "../../images/LogoRainScaleSidebar.svg"
import { Typography } from "@mui/material";
import Cookies from "universal-cookie";

import { Link } from "react-router-dom";
import { authAxios } from "../../api/axiosClient";
import { logoutURL } from "../../constants";
import { logout } from "../../app/Actions/auth";
import withReactContent from "sweetalert2-react-content";
import WarningImage from "../../images/WarningNotiIcon.svg"
import Swal from "sweetalert2";
import ArrowIconCloseSideBar from "../../images/ArrowCloseSideBar.svg"
import { useSelector } from "react-redux";
import { screenMobile } from "../../Function";
import ModalTutorial from "../../containers/component/tutorial/ModalTutorial";

const cookies = new Cookies();
const MySwal = withReactContent(Swal);

const Item = ({ title, to, icon, selected, setSelected, className }) => {
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: '#fff',
                // paddingLeft: "20px",
            }}
            onClick={() => setSelected(title)}
            icon={icon}
            className={className}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};

const IconCircle = () => {
    return (
        <GoCircle style={{ fontSize: 6, border: "1px solid black", borderRadius: "50%" }} />
    )
}

const SideBarUser = ({ menuCollapse, setMenuCollapse }) => {
    const [checkPathCompany, setCheckPathCompany] = useState((window.location.pathname === '/' || window.location.pathname === '/category-master' || window.location.pathname === '/history'))
    const [checkPathManageDoc, setCheckPathManageDoc] = useState((window.location.pathname === '/invoice' || window.location.pathname === '/statement' || window.location.pathname === '/export-document'))
    const [checkPathAccounting, setCheckPathAccounting] = useState((window.location.pathname === '/accounting-invoice' || window.location.pathname === '/accounting-statement'))
    const [checkPathUser, setCheckPathUser] = useState(window.location.pathname === '/infor-user')
    const [openMenuManageCompany, setOpenMenuManageCompany] = useState(checkPathCompany)
    const [openMenuAccounting, setOpenMenuAccounting] = useState(checkPathAccounting)
    const [openMenuManageDocs, setOpenMenuManageDocs] = useState(checkPathManageDoc)
    const [openMenuManageUser, setOpenMenuManageUser] = useState(checkPathUser)
    const [openMenuSearch, setOpenMenuSearch] = useState(false)
    const [isOpenModalTutorial, setIsOpenModalTutorial] = useState(false)

    const userInfo = useSelector((state) => state.getUserInfo.userInfo);


    const listPermission = ["Admin", "Admin Tax", "Admin Manager"]
    const menuIconClick = () => {
        setMenuCollapse(!menuCollapse)
    };

    const [selected, setSelected] = useState(
        (window.location.pathname === '/' && 'Khai báo thông tin DN') ||
        (window.location.pathname === '/category-master' && 'Khai báo danh mục') ||
        (window.location.pathname === '/export-document' && 'Xuất hóa đơn theo mẫu') ||
        (window.location.pathname === '/invoice' && 'Hóa đơn') ||
        (window.location.pathname === '/statement' && 'Sao kê') ||
        (window.location.pathname === '/history' && 'Thông tin tải lên') ||
        (window.location.pathname === '/accounting-invoice' && 'Hóa đơn ') || // Phải để dấu cách
        (window.location.pathname === '/accounting-statement' && 'Sao kê ') || // Phải để dấu cách
        (window.location.pathname === '/infor-user' && 'Quản lý tài khoản')
    );
    const defaultSidebar = () => {
        // MySwal.fire({
        //     title: <span style={{ fontSize: 20 }}>Tính năng đang phát triển</span>,
        //     showCancelButton: false,
        //     showConfirmButton: false,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     imageUrl: WarningImage,
        //     customClass: {
        //         icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
        //         popup: "custom-notification",
        //         confirmButton: "btn-category-master",
        //         cancelButton: "btn-category-master",
        //     },
        //     position: "top",
        // })
        setIsOpenModalTutorial(true)
    }
    const logout_new = (e) => {
        authAxios()
            .get(logoutURL)
            .then((res) => {
                //tắt
                cookies.remove("token_vac");
                cookies.remove("refresh_vac");
                cookies.remove("csrftoken");
                window.location = "/";
            });
        logout();
    };

    useEffect(() => {
        if (window.location.pathname === '/' ||
            window.location.pathname === '/category-master' ||
            window.location.pathname === '/history'
        ) {
            setCheckPathCompany(true)
        } else {
            setCheckPathCompany(false)
        }
        if (window.location.pathname === '/invoice' ||
            window.location.pathname === '/statement' ||
            window.location.pathname === '/export-document') {
            setCheckPathManageDoc(true)
        } else {
            setCheckPathManageDoc(false)
        }
        if (window.location.pathname === '/accounting-invoice' ||
            window.location.pathname === '/accounting-statement') {
            setCheckPathAccounting(true)
        } else {
            setCheckPathAccounting(false)
        }
        if (window.location.pathname === '/infor-user') {
            // handleSidebarManageUser()
            setCheckPathUser(true)
        } else {
            setCheckPathUser(false)
        }

        setSelected(
            (window.location.pathname === '/' && 'Khai báo thông tin DN') ||
            (window.location.pathname === '/category-master' && 'Khai báo danh mục') ||
            (window.location.pathname === '/export-document' && 'Xuất hóa đơn theo mẫu') ||
            (window.location.pathname === '/invoice' && 'Hóa đơn') ||
            (window.location.pathname === '/statement' && 'Sao kê') ||
            (window.location.pathname === '/history' && 'Thông tin tải lên') ||
            (window.location.pathname === '/accounting-invoice' && 'Hóa đơn ') || // Phải để dấu cách
            (window.location.pathname === '/accounting-statement' && 'Sao kê ') || // Phải để dấu cách
            (window.location.pathname === '/infor-user' && 'Quản lý tài khoản')
        )
    }, [window.location.pathname]);

    useEffect(() => {
        if (menuCollapse === true) {
            handleSidebarManageCompany()
            handleSidebarManageDocs()
            handleSidebarMenuAccounting()
            handleSidebarManageUser()
        } else {
            if (checkPathCompany === true) {
                setOpenMenuManageCompany(true)
            }
            else if (checkPathManageDoc === true) {
                setOpenMenuSearch(true)
                setOpenMenuManageDocs(true)
            }
            else if (checkPathAccounting === true) {
                setOpenMenuAccounting(true)
                setOpenMenuManageDocs(true)
            }
            else if (checkPathUser === true) {
                setOpenMenuManageUser(true)
            }
        }
    }, [menuCollapse]);

    const handleSidebarManageCompany = () => {
        if (menuCollapse === true) {
            setOpenMenuManageCompany(false)
        } else {
            setOpenMenuManageCompany(!openMenuManageCompany)
        }
    }

    const handleSidebarManageUser = () => {
        if (menuCollapse === true) {
            setOpenMenuManageUser(false)
        } else {
            setOpenMenuManageUser(!openMenuManageUser)
        }
    }

    const handleSidebarManageDocs = () => {
        if (menuCollapse === true) {
            setOpenMenuManageDocs(false)
        } else {
            setOpenMenuManageDocs(!openMenuManageDocs)
        }
    }

    const handleSidebarMenuSearch = () => {
        setOpenMenuSearch(!openMenuSearch)
    }

    const handleSidebarMenuAccounting = () => {
        setOpenMenuAccounting(!openMenuAccounting)
    }

    const iconManageCompany = () => {
        if (menuCollapse === false && openMenuManageCompany === false) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path d="M10.9997 16.6834C13.0247 16.6834 14.6663 15.0418 14.6663 13.0168C14.6663 10.9917 13.0247 9.3501 10.9997 9.3501C8.97463 9.3501 7.33301 10.9917 7.33301 13.0168C7.33301 15.0418 8.97463 16.6834 10.9997 16.6834Z" stroke="url(#paint0_linear_2394_11307)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.2288 11.8706V12.7231C11.2288 13.0439 11.0638 13.3464 10.7797 13.5114L10.083 13.9331" stroke="url(#paint1_linear_2394_11307)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.33309 20.1667H14.6664C18.3514 20.1667 19.0114 18.6908 19.2039 16.8942L19.8914 9.56083C20.1389 7.32417 19.4973 5.5 15.5831 5.5H6.41643C2.50226 5.5 1.86059 7.32417 2.10809 9.56083L2.79559 16.8942C2.98809 18.6908 3.64809 20.1667 7.33309 20.1667Z" stroke="url(#paint2_linear_2394_11307)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.33301 5.50016V4.76683C7.33301 3.14433 7.33301 1.8335 10.2663 1.8335H11.733C14.6663 1.8335 14.6663 3.14433 14.6663 4.76683V5.50016" stroke="url(#paint3_linear_2394_11307)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.8458 10.0835C18.2599 11.2385 16.4999 12.0452 14.6758 12.5035" stroke="url(#paint4_linear_2394_11307)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2.40137 10.3311C3.9322 11.3761 5.60053 12.1186 7.33303 12.5402" stroke="url(#paint5_linear_2394_11307)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_2394_11307" x1="10.9997" y1="9.3501" x2="10.9997" y2="16.6834" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_2394_11307" x1="10.6559" y1="11.8706" x2="10.6559" y2="13.9331" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2394_11307" x1="10.9998" y1="5.5" x2="10.9998" y2="20.1667" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_2394_11307" x1="10.9997" y1="1.8335" x2="10.9997" y2="5.50016" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_2394_11307" x1="17.2608" y1="10.0835" x2="17.2608" y2="12.5035" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_2394_11307" x1="4.8672" y1="10.3311" x2="4.8672" y2="12.5402" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                </defs>
            </svg>
        } else if (menuCollapse === false && openMenuManageCompany === true) {
            return <svg width="40"
                //    className="box-svg" 
                height="42"
                viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* <circle cx="20" cy="20" r="20" fill="#F9F9F9" /> */}
                <path d="M9 42C8.44772 42 8 41.5523 8 41V41C8 40.4477 8.44772 40 9 40L31 40C31.5523 40 32 40.4477 32 41V41C32 41.5523 31.5523 42 31 42L9 42Z" fill="url(#paint0_linear_2374_44098)" />
                <path d="M19.1298 23.8799C18.8798 23.8799 18.6298 23.7499 18.4898 23.5199C18.2798 23.1699 18.3898 22.6999 18.7498 22.4899L19.6398 21.9599V20.8799C19.6398 20.4699 19.9798 20.1299 20.3898 20.1299C20.7998 20.1299 21.1398 20.4699 21.1398 20.8799V22.3799C21.1398 22.6399 20.9998 22.8899 20.7798 23.0199L19.5298 23.7699C19.3898 23.8399 19.2598 23.8799 19.1298 23.8799Z" fill="url(#paint1_linear_2374_44098)" />
                <path d="M29.09 14.98C28.24 14.04 26.83 13.57 24.76 13.57H24.52V13.53C24.52 11.85 24.52 9.77002 20.76 9.77002H19.24C15.48 9.77002 15.48 11.85 15.48 13.53V13.57H15.24C13.17 13.57 11.75 14.04 10.91 14.98C9.92004 16.09 9.95004 17.56 10.05 18.57L10.06 18.64L10.1291 19.446C10.1423 19.5999 10.2259 19.7392 10.3556 19.8232C10.6393 20.0068 10.9411 20.1995 11.23 20.36C11.37 20.45 11.52 20.53 11.67 20.61C12.8 21.23 14.01 21.72 15.25 22.06C15.28 24.65 17.4 26.75 20 26.75C22.62 26.75 24.75 24.62 24.75 22V21.96C26.01 21.58 27.22 21.05 28.35 20.39C28.41 20.36 28.45 20.33 28.5 20.3C28.8949 20.0854 29.3045 19.8045 29.6782 19.5395C29.7941 19.4573 29.869 19.3293 29.8847 19.1881L29.9 19.05L29.95 18.58C29.96 18.52 29.96 18.47 29.97 18.4C30.05 17.4 30.03 16.02 29.09 14.98ZM16.91 13.53C16.91 11.83 16.91 11.19 19.24 11.19H20.76C23.09 11.19 23.09 11.83 23.09 13.53V13.57H16.91V13.53ZM20 25.25C18.35 25.25 16.98 24.01 16.78 22.41C16.76 22.28 16.75 22.14 16.75 22C16.75 20.21 18.21 18.75 20 18.75C21.79 18.75 23.25 20.21 23.25 22C23.25 22.12 23.24 22.23 23.23 22.34V22.35C23.05 23.98 21.67 25.25 20 25.25Z" fill="url(#paint2_linear_2374_44098)" />
                <path d="M29.0401 21.7301C29.2888 21.5919 29.6115 21.7922 29.5856 22.0755L29.2301 25.9601C29.0201 27.9601 28.2001 30.0001 23.8001 30.0001H16.1801C11.7801 30.0001 10.9601 27.9601 10.7601 25.9701L10.4142 22.2668C10.3874 21.9793 10.6949 21.785 10.9501 21.9201V21.9201C11.7301 22.3501 12.5401 22.7301 13.3701 23.0301C13.6801 23.1401 13.9001 23.4001 13.9901 23.7201C14.7401 26.3201 17.1601 28.2501 20.0001 28.2501C22.8901 28.2501 25.3301 26.2801 26.0401 23.5801C26.1201 23.2601 26.3401 23.0001 26.6501 22.8801C27.4901 22.5501 28.2901 22.1601 29.0401 21.7301V21.7301Z" fill="url(#paint3_linear_2374_44098)" />
                <defs>
                    <linearGradient id="paint1_linear_2374_44098" x1="20.1292" y1="23.8799" x2="20.1292" y2="20.1299" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2374_44098" x1="22.6675" y1="26.75" x2="22.6675" y2="9.77002" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_2374_44098" x1="22.5734" y1="30.0001" x2="22.5734" y2="21.3701" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                </defs>
            </svg>
        } else if (menuCollapse === true && checkPathCompany === false) {
            return <svg width="40" className="box-svg" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#F9F9F9" />
                <path d="M20 26.2C22.2091 26.2 24 24.4091 24 22.2C24 19.9908 22.2091 18.2 20 18.2C17.7909 18.2 16 19.9908 16 22.2C16 24.4091 17.7909 26.2 20 26.2Z" stroke="url(#paint0_linear_2374_44098)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20.25 20.95V21.88C20.25 22.23 20.07 22.56 19.76 22.74L19 23.2" stroke="url(#paint1_linear_2374_44098)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.9998 30H23.9998C28.0198 30 28.7398 28.39 28.9498 26.43L29.6998 18.43C29.9698 15.99 29.2698 14 24.9998 14H14.9998C10.7298 14 10.0298 15.99 10.2998 18.43L11.0498 26.43C11.2598 28.39 11.9798 30 15.9998 30Z" stroke="url(#paint2_linear_2374_44098)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16 14V13.2C16 11.43 16 10 19.2 10H20.8C24 10 24 11.43 24 13.2V14" stroke="url(#paint3_linear_2374_44098)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M29.6498 19C27.9198 20.26 25.9998 21.14 24.0098 21.64" stroke="url(#paint4_linear_2374_44098)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.6201 19.27C12.2901 20.41 14.1101 21.22 16.0001 21.68" stroke="url(#paint5_linear_2374_44098)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_2374_44098" x1="20" y1="18.2" x2="20" y2="26.2" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_2374_44098" x1="19.625" y1="20.95" x2="19.625" y2="23.2" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2374_44098" x1="19.9998" y1="14" x2="19.9998" y2="30" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_2374_44098" x1="20" y1="10" x2="20" y2="14" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_2374_44098" x1="26.8298" y1="19" x2="26.8298" y2="21.64" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_2374_44098" x1="13.3101" y1="19.27" x2="13.3101" y2="21.68" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                </defs>
            </svg>
        } else if (menuCollapse === true && checkPathCompany === true) {
            return <svg width="40" className="box-svg" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#F9F9F9" />
                {/* <path d="M9 42C8.44772 42 8 41.5523 8 41V41C8 40.4477 8.44772 40 9 40L31 40C31.5523 40 32 40.4477 32 41V41C32 41.5523 31.5523 42 31 42L9 42Z" fill="url(#paint0_linear_2374_44098)" /> */}
                <path d="M19.1298 23.8799C18.8798 23.8799 18.6298 23.7499 18.4898 23.5199C18.2798 23.1699 18.3898 22.6999 18.7498 22.4899L19.6398 21.9599V20.8799C19.6398 20.4699 19.9798 20.1299 20.3898 20.1299C20.7998 20.1299 21.1398 20.4699 21.1398 20.8799V22.3799C21.1398 22.6399 20.9998 22.8899 20.7798 23.0199L19.5298 23.7699C19.3898 23.8399 19.2598 23.8799 19.1298 23.8799Z" fill="url(#paint1_linear_2374_44098)" />
                <path d="M29.09 14.98C28.24 14.04 26.83 13.57 24.76 13.57H24.52V13.53C24.52 11.85 24.52 9.77002 20.76 9.77002H19.24C15.48 9.77002 15.48 11.85 15.48 13.53V13.57H15.24C13.17 13.57 11.75 14.04 10.91 14.98C9.92004 16.09 9.95004 17.56 10.05 18.57L10.06 18.64L10.1291 19.446C10.1423 19.5999 10.2259 19.7392 10.3556 19.8232C10.6393 20.0068 10.9411 20.1995 11.23 20.36C11.37 20.45 11.52 20.53 11.67 20.61C12.8 21.23 14.01 21.72 15.25 22.06C15.28 24.65 17.4 26.75 20 26.75C22.62 26.75 24.75 24.62 24.75 22V21.96C26.01 21.58 27.22 21.05 28.35 20.39C28.41 20.36 28.45 20.33 28.5 20.3C28.8949 20.0854 29.3045 19.8045 29.6782 19.5395C29.7941 19.4573 29.869 19.3293 29.8847 19.1881L29.9 19.05L29.95 18.58C29.96 18.52 29.96 18.47 29.97 18.4C30.05 17.4 30.03 16.02 29.09 14.98ZM16.91 13.53C16.91 11.83 16.91 11.19 19.24 11.19H20.76C23.09 11.19 23.09 11.83 23.09 13.53V13.57H16.91V13.53ZM20 25.25C18.35 25.25 16.98 24.01 16.78 22.41C16.76 22.28 16.75 22.14 16.75 22C16.75 20.21 18.21 18.75 20 18.75C21.79 18.75 23.25 20.21 23.25 22C23.25 22.12 23.24 22.23 23.23 22.34V22.35C23.05 23.98 21.67 25.25 20 25.25Z" fill="url(#paint2_linear_2374_44098)" />
                <path d="M29.0401 21.7301C29.2888 21.5919 29.6115 21.7922 29.5856 22.0755L29.2301 25.9601C29.0201 27.9601 28.2001 30.0001 23.8001 30.0001H16.1801C11.7801 30.0001 10.9601 27.9601 10.7601 25.9701L10.4142 22.2668C10.3874 21.9793 10.6949 21.785 10.9501 21.9201V21.9201C11.7301 22.3501 12.5401 22.7301 13.3701 23.0301C13.6801 23.1401 13.9001 23.4001 13.9901 23.7201C14.7401 26.3201 17.1601 28.2501 20.0001 28.2501C22.8901 28.2501 25.3301 26.2801 26.0401 23.5801C26.1201 23.2601 26.3401 23.0001 26.6501 22.8801C27.4901 22.5501 28.2901 22.1601 29.0401 21.7301V21.7301Z" fill="url(#paint3_linear_2374_44098)" />
                <defs>
                    <linearGradient id="paint1_linear_2374_44098" x1="20.1292" y1="23.8799" x2="20.1292" y2="20.1299" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2374_44098" x1="22.6675" y1="26.75" x2="22.6675" y2="9.77002" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_2374_44098" x1="22.5734" y1="30.0001" x2="22.5734" y2="21.3701" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                </defs>
            </svg>
        }
    }

    const iconManageUser = () => {
        if (menuCollapse === false && openMenuManageUser === false) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path d="M10.9997 16.6834C13.0247 16.6834 14.6663 15.0418 14.6663 13.0168C14.6663 10.9917 13.0247 9.3501 10.9997 9.3501C8.97463 9.3501 7.33301 10.9917 7.33301 13.0168C7.33301 15.0418 8.97463 16.6834 10.9997 16.6834Z" stroke="url(#paint0_linear_2394_11307)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.2288 11.8706V12.7231C11.2288 13.0439 11.0638 13.3464 10.7797 13.5114L10.083 13.9331" stroke="url(#paint1_linear_2394_11307)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.33309 20.1667H14.6664C18.3514 20.1667 19.0114 18.6908 19.2039 16.8942L19.8914 9.56083C20.1389 7.32417 19.4973 5.5 15.5831 5.5H6.41643C2.50226 5.5 1.86059 7.32417 2.10809 9.56083L2.79559 16.8942C2.98809 18.6908 3.64809 20.1667 7.33309 20.1667Z" stroke="url(#paint2_linear_2394_11307)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.33301 5.50016V4.76683C7.33301 3.14433 7.33301 1.8335 10.2663 1.8335H11.733C14.6663 1.8335 14.6663 3.14433 14.6663 4.76683V5.50016" stroke="url(#paint3_linear_2394_11307)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.8458 10.0835C18.2599 11.2385 16.4999 12.0452 14.6758 12.5035" stroke="url(#paint4_linear_2394_11307)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2.40137 10.3311C3.9322 11.3761 5.60053 12.1186 7.33303 12.5402" stroke="url(#paint5_linear_2394_11307)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_2394_11307" x1="10.9997" y1="9.3501" x2="10.9997" y2="16.6834" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_2394_11307" x1="10.6559" y1="11.8706" x2="10.6559" y2="13.9331" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2394_11307" x1="10.9998" y1="5.5" x2="10.9998" y2="20.1667" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_2394_11307" x1="10.9997" y1="1.8335" x2="10.9997" y2="5.50016" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_2394_11307" x1="17.2608" y1="10.0835" x2="17.2608" y2="12.5035" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_2394_11307" x1="4.8672" y1="10.3311" x2="4.8672" y2="12.5402" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                </defs>
            </svg>
        } else if (menuCollapse === false && openMenuManageUser === true) {
            return <svg width="40"
                //    className="box-svg" 
                height="42"
                viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* <circle cx="20" cy="20" r="20" fill="#F9F9F9" /> */}
                <path d="M9 42C8.44772 42 8 41.5523 8 41V41C8 40.4477 8.44772 40 9 40L31 40C31.5523 40 32 40.4477 32 41V41C32 41.5523 31.5523 42 31 42L9 42Z" fill="url(#paint0_linear_2374_44098)" />
                <path d="M19.1298 23.8799C18.8798 23.8799 18.6298 23.7499 18.4898 23.5199C18.2798 23.1699 18.3898 22.6999 18.7498 22.4899L19.6398 21.9599V20.8799C19.6398 20.4699 19.9798 20.1299 20.3898 20.1299C20.7998 20.1299 21.1398 20.4699 21.1398 20.8799V22.3799C21.1398 22.6399 20.9998 22.8899 20.7798 23.0199L19.5298 23.7699C19.3898 23.8399 19.2598 23.8799 19.1298 23.8799Z" fill="url(#paint1_linear_2374_44098)" />
                <path d="M29.09 14.98C28.24 14.04 26.83 13.57 24.76 13.57H24.52V13.53C24.52 11.85 24.52 9.77002 20.76 9.77002H19.24C15.48 9.77002 15.48 11.85 15.48 13.53V13.57H15.24C13.17 13.57 11.75 14.04 10.91 14.98C9.92004 16.09 9.95004 17.56 10.05 18.57L10.06 18.64L10.1291 19.446C10.1423 19.5999 10.2259 19.7392 10.3556 19.8232C10.6393 20.0068 10.9411 20.1995 11.23 20.36C11.37 20.45 11.52 20.53 11.67 20.61C12.8 21.23 14.01 21.72 15.25 22.06C15.28 24.65 17.4 26.75 20 26.75C22.62 26.75 24.75 24.62 24.75 22V21.96C26.01 21.58 27.22 21.05 28.35 20.39C28.41 20.36 28.45 20.33 28.5 20.3C28.8949 20.0854 29.3045 19.8045 29.6782 19.5395C29.7941 19.4573 29.869 19.3293 29.8847 19.1881L29.9 19.05L29.95 18.58C29.96 18.52 29.96 18.47 29.97 18.4C30.05 17.4 30.03 16.02 29.09 14.98ZM16.91 13.53C16.91 11.83 16.91 11.19 19.24 11.19H20.76C23.09 11.19 23.09 11.83 23.09 13.53V13.57H16.91V13.53ZM20 25.25C18.35 25.25 16.98 24.01 16.78 22.41C16.76 22.28 16.75 22.14 16.75 22C16.75 20.21 18.21 18.75 20 18.75C21.79 18.75 23.25 20.21 23.25 22C23.25 22.12 23.24 22.23 23.23 22.34V22.35C23.05 23.98 21.67 25.25 20 25.25Z" fill="url(#paint2_linear_2374_44098)" />
                <path d="M29.0401 21.7301C29.2888 21.5919 29.6115 21.7922 29.5856 22.0755L29.2301 25.9601C29.0201 27.9601 28.2001 30.0001 23.8001 30.0001H16.1801C11.7801 30.0001 10.9601 27.9601 10.7601 25.9701L10.4142 22.2668C10.3874 21.9793 10.6949 21.785 10.9501 21.9201V21.9201C11.7301 22.3501 12.5401 22.7301 13.3701 23.0301C13.6801 23.1401 13.9001 23.4001 13.9901 23.7201C14.7401 26.3201 17.1601 28.2501 20.0001 28.2501C22.8901 28.2501 25.3301 26.2801 26.0401 23.5801C26.1201 23.2601 26.3401 23.0001 26.6501 22.8801C27.4901 22.5501 28.2901 22.1601 29.0401 21.7301V21.7301Z" fill="url(#paint3_linear_2374_44098)" />
                <defs>
                    <linearGradient id="paint1_linear_2374_44098" x1="20.1292" y1="23.8799" x2="20.1292" y2="20.1299" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2374_44098" x1="22.6675" y1="26.75" x2="22.6675" y2="9.77002" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_2374_44098" x1="22.5734" y1="30.0001" x2="22.5734" y2="21.3701" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                </defs>
            </svg>
        } else if (menuCollapse === true && checkPathUser === false) {
            return <svg width="40" className="box-svg" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#F9F9F9" />
                <path d="M20 26.2C22.2091 26.2 24 24.4091 24 22.2C24 19.9908 22.2091 18.2 20 18.2C17.7909 18.2 16 19.9908 16 22.2C16 24.4091 17.7909 26.2 20 26.2Z" stroke="url(#paint0_linear_2374_44098)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20.25 20.95V21.88C20.25 22.23 20.07 22.56 19.76 22.74L19 23.2" stroke="url(#paint1_linear_2374_44098)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.9998 30H23.9998C28.0198 30 28.7398 28.39 28.9498 26.43L29.6998 18.43C29.9698 15.99 29.2698 14 24.9998 14H14.9998C10.7298 14 10.0298 15.99 10.2998 18.43L11.0498 26.43C11.2598 28.39 11.9798 30 15.9998 30Z" stroke="url(#paint2_linear_2374_44098)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16 14V13.2C16 11.43 16 10 19.2 10H20.8C24 10 24 11.43 24 13.2V14" stroke="url(#paint3_linear_2374_44098)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M29.6498 19C27.9198 20.26 25.9998 21.14 24.0098 21.64" stroke="url(#paint4_linear_2374_44098)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.6201 19.27C12.2901 20.41 14.1101 21.22 16.0001 21.68" stroke="url(#paint5_linear_2374_44098)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_2374_44098" x1="20" y1="18.2" x2="20" y2="26.2" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_2374_44098" x1="19.625" y1="20.95" x2="19.625" y2="23.2" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2374_44098" x1="19.9998" y1="14" x2="19.9998" y2="30" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_2374_44098" x1="20" y1="10" x2="20" y2="14" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_2374_44098" x1="26.8298" y1="19" x2="26.8298" y2="21.64" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_2374_44098" x1="13.3101" y1="19.27" x2="13.3101" y2="21.68" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                </defs>
            </svg>
        } else if (menuCollapse === true && checkPathUser === true) {
            return <svg width="40" className="box-svg" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#F9F9F9" />
                {/* <path d="M9 42C8.44772 42 8 41.5523 8 41V41C8 40.4477 8.44772 40 9 40L31 40C31.5523 40 32 40.4477 32 41V41C32 41.5523 31.5523 42 31 42L9 42Z" fill="url(#paint0_linear_2374_44098)" /> */}
                <path d="M19.1298 23.8799C18.8798 23.8799 18.6298 23.7499 18.4898 23.5199C18.2798 23.1699 18.3898 22.6999 18.7498 22.4899L19.6398 21.9599V20.8799C19.6398 20.4699 19.9798 20.1299 20.3898 20.1299C20.7998 20.1299 21.1398 20.4699 21.1398 20.8799V22.3799C21.1398 22.6399 20.9998 22.8899 20.7798 23.0199L19.5298 23.7699C19.3898 23.8399 19.2598 23.8799 19.1298 23.8799Z" fill="url(#paint1_linear_2374_44098)" />
                <path d="M29.09 14.98C28.24 14.04 26.83 13.57 24.76 13.57H24.52V13.53C24.52 11.85 24.52 9.77002 20.76 9.77002H19.24C15.48 9.77002 15.48 11.85 15.48 13.53V13.57H15.24C13.17 13.57 11.75 14.04 10.91 14.98C9.92004 16.09 9.95004 17.56 10.05 18.57L10.06 18.64L10.1291 19.446C10.1423 19.5999 10.2259 19.7392 10.3556 19.8232C10.6393 20.0068 10.9411 20.1995 11.23 20.36C11.37 20.45 11.52 20.53 11.67 20.61C12.8 21.23 14.01 21.72 15.25 22.06C15.28 24.65 17.4 26.75 20 26.75C22.62 26.75 24.75 24.62 24.75 22V21.96C26.01 21.58 27.22 21.05 28.35 20.39C28.41 20.36 28.45 20.33 28.5 20.3C28.8949 20.0854 29.3045 19.8045 29.6782 19.5395C29.7941 19.4573 29.869 19.3293 29.8847 19.1881L29.9 19.05L29.95 18.58C29.96 18.52 29.96 18.47 29.97 18.4C30.05 17.4 30.03 16.02 29.09 14.98ZM16.91 13.53C16.91 11.83 16.91 11.19 19.24 11.19H20.76C23.09 11.19 23.09 11.83 23.09 13.53V13.57H16.91V13.53ZM20 25.25C18.35 25.25 16.98 24.01 16.78 22.41C16.76 22.28 16.75 22.14 16.75 22C16.75 20.21 18.21 18.75 20 18.75C21.79 18.75 23.25 20.21 23.25 22C23.25 22.12 23.24 22.23 23.23 22.34V22.35C23.05 23.98 21.67 25.25 20 25.25Z" fill="url(#paint2_linear_2374_44098)" />
                <path d="M29.0401 21.7301C29.2888 21.5919 29.6115 21.7922 29.5856 22.0755L29.2301 25.9601C29.0201 27.9601 28.2001 30.0001 23.8001 30.0001H16.1801C11.7801 30.0001 10.9601 27.9601 10.7601 25.9701L10.4142 22.2668C10.3874 21.9793 10.6949 21.785 10.9501 21.9201V21.9201C11.7301 22.3501 12.5401 22.7301 13.3701 23.0301C13.6801 23.1401 13.9001 23.4001 13.9901 23.7201C14.7401 26.3201 17.1601 28.2501 20.0001 28.2501C22.8901 28.2501 25.3301 26.2801 26.0401 23.5801C26.1201 23.2601 26.3401 23.0001 26.6501 22.8801C27.4901 22.5501 28.2901 22.1601 29.0401 21.7301V21.7301Z" fill="url(#paint3_linear_2374_44098)" />
                <defs>
                    <linearGradient id="paint1_linear_2374_44098" x1="20.1292" y1="23.8799" x2="20.1292" y2="20.1299" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2374_44098" x1="22.6675" y1="26.75" x2="22.6675" y2="9.77002" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_2374_44098" x1="22.5734" y1="30.0001" x2="22.5734" y2="21.3701" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                </defs>
            </svg>
        }
    }

    const iconManageDocs = () => {
        if (menuCollapse === false && openMenuManageDocs === false) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path d="M6.65504 1.8335H15.3359C15.9317 1.8335 16.4634 1.85185 16.94 1.91602C19.4792 2.20019 20.1667 3.39184 20.1667 6.65517V12.4485C20.1667 15.7118 19.4792 16.9035 16.94 17.1877C16.4634 17.2518 15.9409 17.2702 15.3359 17.2702H6.65504C6.05921 17.2702 5.52754 17.2518 5.05088 17.1877C2.51171 16.9035 1.82422 15.7118 1.82422 12.4485V6.65517C1.82422 3.39184 2.51171 2.20019 5.05088 1.91602C5.52754 1.85185 6.05921 1.8335 6.65504 1.8335Z" stroke="url(#paint0_linear_2425_10779)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.4482 7.62695H15.8216" stroke="url(#paint1_linear_2425_10779)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.17871 12.9341H6.19703H15.8312" stroke="url(#paint2_linear_2425_10779)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.41699 20.1665H15.5837" stroke="url(#paint3_linear_2425_10779)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.5949 7.60824H6.60313" stroke="url(#paint4_linear_2425_10779)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.62029 7.60824H9.62852" stroke="url(#paint5_linear_2425_10779)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_2425_10779" x1="13.4411" y1="17.2702" x2="13.4411" y2="1.8335" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_2425_10779" x1="14.5847" y1="8.62695" x2="14.5847" y2="7.62695" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2425_10779" x1="12.292" y1="13.9341" x2="12.292" y2="12.9341" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_2425_10779" x1="12.2225" y1="21.1665" x2="12.2225" y2="20.1665" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_2425_10779" x1="6.72099" y1="8.06657" x2="6.72099" y2="7.1499" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_2425_10779" x1="9.74638" y1="8.06657" x2="9.74639" y2="7.1499" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                </defs>
            </svg>
        } else if (menuCollapse === false && openMenuManageDocs === true) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path d="M15.5837 20.854H6.41699C6.04116 20.854 5.72949 20.5423 5.72949 20.1665C5.72949 19.7907 6.04116 19.479 6.41699 19.479H15.5837C15.9595 19.479 16.2712 19.7907 16.2712 20.1665C16.2712 20.5423 15.9595 20.854 15.5837 20.854Z" fill="url(#paint0_linear_2425_11346)" />
                <path d="M16.4997 1.8335H5.49967C3.47384 1.8335 1.83301 3.47433 1.83301 5.50016V13.7502C1.83301 15.776 3.47384 17.4168 5.49967 17.4168H16.4997C18.5255 17.4168 20.1663 15.776 20.1663 13.7502V5.50016C20.1663 3.47433 18.5255 1.8335 16.4997 1.8335ZM9.15717 6.71016C9.67051 6.71016 10.083 7.12266 10.083 7.62683C10.083 8.131 9.67051 8.5435 9.16634 8.5435C8.66217 8.5435 8.24967 8.131 8.24967 7.62683C8.24967 7.12266 8.65301 6.71016 9.15717 6.71016ZM6.26967 6.71016C6.78301 6.71016 7.19551 7.12266 7.19551 7.62683C7.19551 8.131 6.78301 8.5435 6.27884 8.5435C5.77467 8.5435 5.36217 8.131 5.36217 7.62683C5.36217 7.12266 5.75634 6.71016 6.26967 6.71016ZM15.8213 13.6218H6.19634C5.81134 13.6218 5.49967 13.3102 5.49967 12.9343C5.49967 12.5585 5.80217 12.2468 6.17801 12.2468H15.8305C16.2063 12.2468 16.518 12.5585 16.518 12.9343C16.518 13.3102 16.2063 13.6218 15.8213 13.6218ZM15.8213 8.31433H12.448C12.0722 8.31433 11.7605 8.00266 11.7605 7.62683C11.7605 7.251 12.0722 6.93933 12.448 6.93933H15.8213C16.1972 6.93933 16.5088 7.251 16.5088 7.62683C16.5088 8.00266 16.2063 8.31433 15.8213 8.31433Z" fill="url(#paint1_linear_2425_11346)" />
                <defs>
                    <linearGradient id="paint0_linear_2425_11346" x1="12.4059" y1="20.854" x2="12.4059" y2="19.479" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_2425_11346" x1="13.4441" y1="17.4168" x2="13.4441" y2="1.8335" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                </defs>
            </svg>
        } else if (menuCollapse === true && checkPathManageDoc === false) {
            return <svg width="40" className="box-svg" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#F9F9F9" />
                <path d="M15.2602 10H24.7302C25.3802 10 25.9602 10.02 26.4802 10.09C29.2502 10.4 30.0002 11.7 30.0002 15.26V21.58C30.0002 25.14 29.2502 26.44 26.4802 26.75C25.9602 26.82 25.3902 26.84 24.7302 26.84H15.2602C14.6102 26.84 14.0302 26.82 13.5102 26.75C10.7402 26.44 9.99023 25.14 9.99023 21.58V15.26C9.99023 11.7 10.7402 10.4 13.5102 10.09C14.0302 10.02 14.6102 10 15.2602 10Z" stroke="url(#paint0_linear_2374_44098)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M21.5801 16.3201H25.2601" stroke="url(#paint1_linear_2374_44098)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.7402 22.1099H14.7602H25.2702" stroke="url(#paint2_linear_2374_44098)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15 30H25" stroke="url(#paint3_linear_2374_44098)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.1947 16.3H15.2037" stroke="url(#paint4_linear_2374_44098)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.4945 16.3H18.5035" stroke="url(#paint5_linear_2374_44098)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_2374_44098" x1="22.6632" y1="26.84" x2="22.6632" y2="10" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_2374_44098" x1="23.9107" y1="17.3201" x2="23.9107" y2="16.3201" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_2374_44098" x1="21.4092" y1="23.1099" x2="21.4092" y2="22.1099" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_2374_44098" x1="21.3333" y1="31" x2="21.3333" y2="30" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_2374_44098" x1="15.3323" y1="16.8" x2="15.3323" y2="15.8" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_2374_44098" x1="18.6321" y1="16.8" x2="18.6321" y2="15.8" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                </defs>
            </svg>

        } else if (menuCollapse === true && checkPathManageDoc === true) {
            return <svg width="40" className="box-svg" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#F9F9F9" />
                <path d="M25 30.75H15C14.59 30.75 14.25 30.41 14.25 30C14.25 29.59 14.59 29.25 15 29.25H25C25.41 29.25 25.75 29.59 25.75 30C25.75 30.41 25.41 30.75 25 30.75Z" fill="url(#paint0_linear_2419_44830)" />
                <path d="M26 10H14C11.79 10 10 11.79 10 14V23C10 25.21 11.79 27 14 27H26C28.21 27 30 25.21 30 23V14C30 11.79 28.21 10 26 10ZM17.99 15.32C18.55 15.32 19 15.77 19 16.32C19 16.87 18.55 17.32 18 17.32C17.45 17.32 17 16.87 17 16.32C17 15.77 17.44 15.32 17.99 15.32ZM14.84 15.32C15.4 15.32 15.85 15.77 15.85 16.32C15.85 16.87 15.4 17.32 14.85 17.32C14.3 17.32 13.85 16.87 13.85 16.32C13.85 15.77 14.28 15.32 14.84 15.32ZM25.26 22.86H14.76C14.34 22.86 14 22.52 14 22.11C14 21.7 14.33 21.36 14.74 21.36H25.27C25.68 21.36 26.02 21.7 26.02 22.11C26.02 22.52 25.68 22.86 25.26 22.86ZM25.26 17.07H21.58C21.17 17.07 20.83 16.73 20.83 16.32C20.83 15.91 21.17 15.57 21.58 15.57H25.26C25.67 15.57 26.01 15.91 26.01 16.32C26.01 16.73 25.68 17.07 25.26 17.07Z" fill="url(#paint1_linear_2419_44830)" />
                <defs>
                    <linearGradient id="paint0_linear_2419_44830" x1="21.5333" y1="30.75" x2="21.5333" y2="29.25" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_2419_44830" x1="22.6667" y1="27" x2="22.6667" y2="10" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#058DF4" />
                        <stop offset="1" stop-color="#6266EF" />
                    </linearGradient>
                </defs>
            </svg>
        }
    }



    const [opacitySubmenu, setOpacitySubmenu] = useState(0)
    useEffect(() => {
        if (menuCollapse === false) {
            setTimeout(() => {
                setOpacitySubmenu(1)
            }, 100)
        } else {
            setOpacitySubmenu(0)
        }
    }, [menuCollapse]);

    return (
        <>
            <ProSidebar collapsed={menuCollapse} toggled={true} style={{ position: screenMobile === true ? "absolute" : null }}>
                <SidebarHeader>
                    <div className="logotext" style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "8vh"
                    }}>
                        {/* {menuCollapse ? <span className="text-logo">RS</span> : <img src={LogoRS} alt=""></img>} */}
                        {menuCollapse ? <img src={Logo} alt=""></img> : <img src={LogoRS} alt=""></img>}

                    </div>
                    <div className="closemenu arrow-zone" onClick={menuIconClick}>
                        {menuCollapse ? (
                            <>
                                {/* <IoIosArrowForward style={{ fontSize: 22 }} /> */}
                                <img style={{ transform: "rotate(180deg)" }} src={ArrowIconCloseSideBar} alt=""></img>
                            </>

                        ) : (
                            <>
                                {/* <IoIosArrowBack style={{ fontSize: 22 }} /> */}
                                <img src={ArrowIconCloseSideBar} alt=""></img>
                            </>
                        )}
                    </div>
                </SidebarHeader>

                <SidebarContent className="title-menu" style={{ height: "70vh", overflow: "auto", opacity: screenMobile === true ? opacitySubmenu : null }}>
                    <Menu iconShape="circle">
                        <span className={menuCollapse === false ? "text-menu" : "text-menu-menu-collapse"}>MENU</span>

                        {/* Quản lý doanh nghiệp */}


                        <SubMenu
                            title="Quản lý dữ liệu đầu vào"
                            icon={iconManageCompany()}
                            defaultOpen={true}
                            className={menuCollapse === true ? "submenu" : "manage-company"}
                            style={checkPathCompany === true && menuCollapse === true ? { background: "rgba(5, 141, 244, 0.1)" } : null}
                            open={openMenuManageCompany}
                            onOpenChange={() => handleSidebarManageCompany()}
                        >
                            {listPermission.includes(userInfo.role_name) ?
                                <>
                                    <Item
                                        title="Khai báo thông tin DN"
                                        to="/"
                                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                            <path d="M8 15.1668C10.7533 15.1668 13 12.9202 13 10.1668V6.8335C13 4.08016 10.7533 1.8335 8 1.8335C5.24667 1.8335 3 4.08016 3 6.8335V10.1668C3 12.9202 5.24667 15.1668 8 15.1668Z" stroke="url(#paint0_linear_2258_9563)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8 7.83333C7.44667 7.83333 7 7.38667 7 6.83333V5.5C7 4.94667 7.44667 4.5 8 4.5C8.54667 4.5 9 4.94667 9 5.5V6.83333C9 7.38667 8.54667 7.83333 8 7.83333Z" stroke="url(#paint1_linear_2258_9563)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8 4.50016V1.8335" stroke="url(#paint2_linear_2258_9563)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_2258_9563" x1="9.33333" y1="15.1668" x2="9.33334" y2="1.8335" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#058DF4" />
                                                    <stop offset="1" stop-color="#6266EF" />
                                                </linearGradient>
                                                <linearGradient id="paint1_linear_2258_9563" x1="8.26667" y1="7.83333" x2="8.26667" y2="4.5" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#058DF4" />
                                                    <stop offset="1" stop-color="#6266EF" />
                                                </linearGradient>
                                                <linearGradient id="paint2_linear_2258_9563" x1="8.63333" y1="4.50016" x2="8.63334" y2="1.8335" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#058DF4" />
                                                    <stop offset="1" stop-color="#6266EF" />
                                                </linearGradient>
                                            </defs>
                                        </svg>}

                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Item
                                        title="Khai báo danh mục"
                                        to="/category-master"
                                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                            <path d="M7.99967 11.8333V13.42C7.99967 14.6667 7.49967 15.1667 6.24634 15.1667H3.07967C1.83301 15.1667 1.33301 14.6667 1.33301 13.42V10.2533C1.33301 9 1.83301 8.5 3.07967 8.5H4.66634V10.08C4.66634 11.3333 5.16634 11.8333 6.41301 11.8333H7.99967Z" stroke="url(#paint0_linear_2258_9576)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M11.3337 8.49984V10.0798C11.3337 11.3332 10.8337 11.8332 9.58033 11.8332H6.41366C5.16699 11.8332 4.66699 11.3332 4.66699 10.0798V6.91317C4.66699 5.6665 5.16699 5.1665 6.41366 5.1665H8.00033V6.7465C8.00033 7.99984 8.50033 8.49984 9.74699 8.49984H11.3337Z" stroke="url(#paint1_linear_2258_9576)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M14.6667 3.58016V6.74683C14.6667 8.00016 14.1667 8.50016 12.9133 8.50016H9.74667C8.5 8.50016 8 8.00016 8 6.74683V3.58016C8 2.3335 8.5 1.8335 9.74667 1.8335H12.9133C14.1667 1.8335 14.6667 2.3335 14.6667 3.58016Z" stroke="url(#paint2_linear_2258_9576)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_2258_9576" x1="5.55523" y1="15.1667" x2="5.55523" y2="8.5" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#058DF4" />
                                                    <stop offset="1" stop-color="#6266EF" />
                                                </linearGradient>
                                                <linearGradient id="paint1_linear_2258_9576" x1="8.88921" y1="11.8332" x2="8.88922" y2="5.1665" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#058DF4" />
                                                    <stop offset="1" stop-color="#6266EF" />
                                                </linearGradient>
                                                <linearGradient id="paint2_linear_2258_9576" x1="12.2222" y1="8.50016" x2="12.2222" y2="1.8335" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#058DF4" />
                                                    <stop offset="1" stop-color="#6266EF" />
                                                </linearGradient>
                                            </defs>
                                        </svg>}

                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                </>
                                : null}

                            <Item
                                title="Thông tin tải lên"
                                to="/history"
                                icon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.6663 8.66659V5.99992C14.6663 2.66659 13.333 1.33325 9.99967 1.33325H5.99967C2.66634 1.33325 1.33301 2.66659 1.33301 5.99992V9.99992C1.33301 13.3333 2.66634 14.6666 5.99967 14.6666H9.99967" stroke="url(#paint0_linear_3862_80208)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12.6663 14.6667V10.6667L11.333 12.0001" stroke="url(#paint1_linear_3862_80208)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12.667 10.6667L14.0003 12.0001" stroke="url(#paint2_linear_3862_80208)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5 6H11" stroke="url(#paint3_linear_3862_80208)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5 8.5H11" stroke="url(#paint4_linear_3862_80208)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <defs>
                                        <linearGradient id="paint0_linear_3862_80208" x1="9.77745" y1="14.6666" x2="9.77746" y2="1.33325" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#058DF4" />
                                            <stop offset="1" stop-color="#6266EF" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_3862_80208" x1="12.1775" y1="14.6667" x2="12.1775" y2="10.6667" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#058DF4" />
                                            <stop offset="1" stop-color="#6266EF" />
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_3862_80208" x1="13.5114" y1="12.0001" x2="13.5114" y2="10.6667" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#058DF4" />
                                            <stop offset="1" stop-color="#6266EF" />
                                        </linearGradient>
                                        <linearGradient id="paint3_linear_3862_80208" x1="8.8" y1="7" x2="8.8" y2="6" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#058DF4" />
                                            <stop offset="1" stop-color="#6266EF" />
                                        </linearGradient>
                                        <linearGradient id="paint4_linear_3862_80208" x1="8.8" y1="9.5" x2="8.8" y2="8.5" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#058DF4" />
                                            <stop offset="1" stop-color="#6266EF" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                }
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </SubMenu>


                        {/* Quản lý chứng từ */}
                        <SubMenu
                            title="Quản lý dữ liệu đầu ra"
                            icon={iconManageDocs()}
                            style={(checkPathManageDoc === true || checkPathAccounting === true) && menuCollapse === true ? { background: "rgba(5, 141, 244, 0.1)" } : null}
                            className={menuCollapse === true ? "submenu" : "manage-company"}
                            open={openMenuManageDocs}
                            onOpenChange={() => handleSidebarManageDocs()}
                        >
                            <SubMenu
                                title="Tra cứu chứng từ"
                                icon={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                        <path d="M12.2503 7.20817C12.2503 10.2707 9.77116 12.7498 6.70866 12.7498C3.64616 12.7498 1.16699 10.2707 1.16699 7.20817C1.16699 4.14567 3.64616 1.6665 6.70866 1.6665" stroke="url(#paint0_linear_2339_10587)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M12.8337 13.3332L11.667 12.1665" stroke="url(#paint1_linear_2339_10587)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M8.45828 4.07596C8.25412 3.4343 8.49328 2.63513 9.16995 2.4193C9.52578 2.30263 9.96328 2.4018 10.2141 2.74596C10.4475 2.39013 10.9025 2.30846 11.2525 2.4193C11.9291 2.63513 12.1683 3.4343 11.9641 4.07596C11.6433 5.0968 10.5233 5.62763 10.2141 5.62763C9.89912 5.62763 8.79078 5.10846 8.45828 4.07596Z" stroke="url(#paint2_linear_2339_10587)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <defs>
                                            <linearGradient id="paint0_linear_2339_10587" x1="8.18644" y1="12.7498" x2="8.18644" y2="1.6665" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#058DF4" />
                                                <stop offset="1" stop-color="#6266EF" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_2339_10587" x1="12.4059" y1="13.3332" x2="12.4059" y2="12.1665" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#058DF4" />
                                                <stop offset="1" stop-color="#6266EF" />
                                            </linearGradient>
                                            <linearGradient id="paint2_linear_2339_10587" x1="10.6975" y1="5.62763" x2="10.6975" y2="2.37256" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#058DF4" />
                                                <stop offset="1" stop-color="#6266EF" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                }
                                style={{
                                    color: 'rgb(255, 255, 255)'
                                }}
                                open={openMenuSearch}
                                onOpenChange={() => handleSidebarMenuSearch()}
                            // className={menuCollapse === true ? "submenu manage-company" : "manage-company"}
                            >
                                <Item
                                    title="Hóa đơn"
                                    to="/invoice"
                                    icon={<IconCircle />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    className={'custom-padding'}
                                />
                                <Item
                                    title="Sao kê"
                                    to="/statement"
                                    icon={<IconCircle />}

                                    selected={selected}
                                    setSelected={setSelected}
                                    className={'custom-padding'}

                                />

                            </SubMenu>
                            <SubMenu
                                title="Định khoản chứng từ"
                                icon={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                        <path d="M14.4398 7.45984L13.7865 10.2465C13.2265 12.6532 12.1198 13.6265 10.0398 13.4265C9.70646 13.3998 9.34646 13.3398 8.95979 13.2465L7.83979 12.9798C5.05979 12.3198 4.19979 10.9465 4.85313 8.15984L5.50646 5.36651C5.63979 4.79984 5.79979 4.30651 5.99979 3.89984C6.77979 2.28651 8.10646 1.85318 10.3331 2.37984L11.4465 2.63984C14.2398 3.29318 15.0931 4.67318 14.4398 7.45984Z" stroke="url(#paint0_linear_2339_10627)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10.0402 13.4266C9.62689 13.7066 9.10689 13.9399 8.47356 14.1466L7.42023 14.4932C4.77356 15.3466 3.38023 14.6332 2.52023 11.9866L1.66689 9.35324C0.813561 6.70657 1.52023 5.30657 4.16689 4.45324L5.22023 4.10657C5.49356 4.0199 5.75356 3.94657 6.00023 3.8999C5.80023 4.30657 5.64023 4.7999 5.50689 5.36657L4.85356 8.1599C4.20023 10.9466 5.06023 12.3199 7.84023 12.9799L8.96023 13.2466C9.34689 13.3399 9.70689 13.3999 10.0402 13.4266Z" stroke="url(#paint1_linear_2339_10627)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M8.42676 6.18652L11.6601 7.00652" stroke="url(#paint2_linear_2339_10627)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7.77344 8.7666L9.70677 9.25994" stroke="url(#paint3_linear_2339_10627)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <defs>
                                            <linearGradient id="paint0_linear_2339_10627" x1="10.9805" y1="13.4519" x2="10.9805" y2="2.16748" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#058DF4" />
                                                <stop offset="1" stop-color="#6266EF" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_2339_10627" x1="6.84436" y1="14.8348" x2="6.84437" y2="3.8999" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#058DF4" />
                                                <stop offset="1" stop-color="#6266EF" />
                                            </linearGradient>
                                            <linearGradient id="paint2_linear_2339_10627" x1="10.4745" y1="7.00652" x2="10.4745" y2="6.18652" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#058DF4" />
                                                <stop offset="1" stop-color="#6266EF" />
                                            </linearGradient>
                                            <linearGradient id="paint3_linear_2339_10627" x1="8.99788" y1="9.25994" x2="8.99788" y2="8.7666" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#058DF4" />
                                                <stop offset="1" stop-color="#6266EF" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                }
                                style={{
                                    color: 'rgb(255, 255, 255)'
                                }}
                                open={openMenuAccounting}
                                onOpenChange={() => handleSidebarMenuAccounting()}
                            // className={menuCollapse === true ? "submenu manage-company" : "manage-company"}
                            >
                                <Item
                                    title="Hóa đơn "
                                    to="/accounting-invoice"
                                    icon={<IconCircle />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    className={'custom-padding'}
                                />
                                <Item
                                    title="Sao kê "
                                    to="/accounting-statement"
                                    icon={<IconCircle />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    className={'custom-padding'}
                                />

                            </SubMenu>

                            {listPermission.includes(userInfo.role_name) ?
                                <Item
                                    title="Xuất hóa đơn theo mẫu"
                                    to="/export-document"
                                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                        <path d="M2 6.5734V10.4201C2 11.8334 2 11.8334 3.33333 12.7334L7 14.8534C7.55333 15.1734 8.45333 15.1734 9 14.8534L12.6667 12.7334C14 11.8334 14 11.8334 14 10.4267V6.5734C14 5.16674 14 5.16674 12.6667 4.26674L9 2.14674C8.45333 1.82674 7.55333 1.82674 7 2.14674L3.33333 4.26674C2 5.16674 2 5.16674 2 6.5734Z" stroke="url(#paint0_linear_2258_9589)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M8 10.5C9.10457 10.5 10 9.60457 10 8.5C10 7.39543 9.10457 6.5 8 6.5C6.89543 6.5 6 7.39543 6 8.5C6 9.60457 6.89543 10.5 8 10.5Z" stroke="url(#paint1_linear_2258_9589)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <defs>
                                            <linearGradient id="paint0_linear_2258_9589" x1="9.6" y1="15.0934" x2="9.60001" y2="1.90674" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#058DF4" />
                                                <stop offset="1" stop-color="#6266EF" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_2258_9589" x1="8.53333" y1="10.5" x2="8.53334" y2="6.5" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#058DF4" />
                                                <stop offset="1" stop-color="#6266EF" />
                                            </linearGradient>
                                        </defs>
                                    </svg>}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                : null}
                        </SubMenu>


                        {/* Quản lý tài khoản */}

                        {listPermission.includes(userInfo.role_name) ?
                            <Item
                                title={"Quản lý tài khoản"}
                                icon={iconManageUser()}
                                to="/infor-user"
                                // defaultOpen={true}
                                className={menuCollapse === true ? "submenu" : "manage-company"}
                                style={checkPathUser === true && menuCollapse === true ? { background: "rgba(5, 141, 244, 0.1)" } : null}
                                // open={openMenuManageUser}
                                selected={selected}
                                setSelected={setSelected}
                            >
                            </Item>
                            : null}

                    </Menu>
                </SidebarContent>


                <SidebarFooter>
                    <Menu iconShape="square">
                        <Item
                            title={menuCollapse === false ? "Trợ giúp" : ""}
                            icon={
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M11.654 16.9577H11.6657" stroke="#394B76" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.6602 13.8507V13.5777C11.6602 12.6936 12.2063 12.2255 12.7523 11.8485C13.2854 11.4844 13.8184 11.0164 13.8184 10.1583C13.8184 8.96215 12.8563 8 11.6602 8C10.464 8 9.50195 8.96215 9.50195 10.1583" stroke="#394B76" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10.7499 2.45031C11.4499 1.86031 12.5799 1.86031 13.2599 2.45031L14.8399 3.80031C15.1399 4.05031 15.7099 4.26031 16.1099 4.26031H17.8099C18.8699 4.26031 19.7399 5.13031 19.7399 6.19031V7.89031C19.7399 8.29031 19.9499 8.85031 20.1999 9.15031L21.5499 10.7303C22.1399 11.4303 22.1399 12.5603 21.5499 13.2403L20.1999 14.8203C19.9499 15.1203 19.7399 15.6803 19.7399 16.0803V17.7803C19.7399 18.8403 18.8699 19.7103 17.8099 19.7103H16.1099C15.7099 19.7103 15.1499 19.9203 14.8499 20.1703L13.2699 21.5203C12.5699 22.1103 11.4399 22.1103 10.7599 21.5203L9.17988 20.1703C8.87988 19.9203 8.30988 19.7103 7.91988 19.7103H6.16988C5.10988 19.7103 4.23988 18.8403 4.23988 17.7803V16.0703C4.23988 15.6803 4.03988 15.1103 3.78988 14.8203L2.43988 13.2303C1.85988 12.5403 1.85988 11.4203 2.43988 10.7303L3.78988 9.14031C4.03988 8.84031 4.23988 8.28031 4.23988 7.89031V6.20031C4.23988 5.14031 5.10988 4.27031 6.16988 4.27031H7.89988C8.29988 4.27031 8.85988 4.06031 9.15988 3.81031L10.7499 2.45031Z" stroke="#394B76" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                            to="#"
                            setSelected={defaultSidebar}
                            className={menuCollapse === true ? "submenu manage-company" : "manage-company"}
                        />
                        <Item
                            title={menuCollapse === false ? "Đăng xuất" : ""}
                            icon={
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path d="M10.8333 5.33341V3.50008C10.8333 3.01385 10.6402 2.54754 10.2964 2.20372C9.95254 1.8599 9.48623 1.66675 9 1.66675H2.58333C2.0971 1.66675 1.63079 1.8599 1.28697 2.20372C0.943154 2.54754 0.75 3.01385 0.75 3.50008V14.5001C0.75 14.9863 0.943154 15.4526 1.28697 15.7964C1.63079 16.1403 2.0971 16.3334 2.58333 16.3334H9C9.48623 16.3334 9.95254 16.1403 10.2964 15.7964C10.6402 15.4526 10.8333 14.9863 10.8333 14.5001V12.6667" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M4.41699 9H17.2503M17.2503 9L14.5003 6.25M17.2503 9L14.5003 11.75" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            }
                            to="#"
                            setSelected={() => logout_new()}
                            className={menuCollapse === true ? "submenu manage-company" : "manage-company"}
                        />
                    </Menu>
                </SidebarFooter>
                <ModalTutorial
                    open={isOpenModalTutorial}
                    setIsOpenModalTutorial={setIsOpenModalTutorial}
                />
            </ProSidebar>
        </>
    );
};

export default SideBarUser;
