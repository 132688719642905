import { Button, Col, Form, Input, InputNumber, Radio, Row, Select, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { localhost } from '../../../../../server';
import { Button as ButtonMui, Container } from '@mui/material';
import CategoryMasterIcon from "../../../../../images/CategoryMasterIcon.svg"
import IconUpload from "../../../../../images/Upload.svg";
import IconEdit from "../../../../../images/EditIcon.svg";
import {GetInforMasterApi, GetListCircularNoApi, PutInforMasterApi } from '../../../../../api/categoryMaster';
import BtnDownload from '../BtnDownload';

import ErrorImage from "../../../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "../../../../../images/SuccessNotiIcon.svg"
import WarningImage from "../../../../../images/WarningNotiIcon.svg"
import ModalEditCategoryUser from '../modal_edit/ModalEditCategoryUser';
import ModalEditCategoryCustomer from '../modal_edit/ModalEditCategoryCustomer';
import { openNotificationSweetAlert } from '../../../../../Function';
import "./CategoryMasterRoleAdmin.css"
import { GetListTaxAgent } from '../../../../../api/manageInforUser';

const { Option } = Select;

const MySwal = withReactContent(Swal);

const CategoryMasterRoleAdmin = () => {
    const [chooseId, setChooseId] = useState()
    const [dataTaxAgent, setDataTaxAgent] = useState([])
    const [dataFilter, setDataFilter] = useState([])
    const [value, setValue] = useState(1);
    const fileInputRef = useRef(null);
    const fileInputRef1 = useRef(null);
    const [valueNameDMTK, setValueNameDMTK] = useState(null);
    const [valueNameNCC, setValueNameNCC] = useState(null);
    const [listCircular, setListCircular] = useState([])
    const [urlSupplier, setUrlSupplier] = useState()
    const [urlCustomer, setUrlCustomer] = useState()
    const [form] = Form.useForm();
    const [openEditCategoryUser, setOpenEditCategoryUser] = useState(false)
    const [openEditCategoryCustomer, setOpenEditCategoryCustomer] = useState(false)

    const [isEditCategoryUser, setIsEditCategoryUser] = useState(false)
    const [isEditCategoryCustomer, setIsEditCategoryCustomer] = useState(false)

    const FetchInforMaster = (id, idTypeBusiness) => {
        GetInforMasterApi({
            params: {
                id_customer: id,
                id_type_business: idTypeBusiness || null,
            }
        }).then(res => {
            if (res.data.category_account.name_file_full === "") {
                setIsEditCategoryUser(false)
            } else {
                setIsEditCategoryUser(true)
            }

            if (res.data.category_customer.name_file_full === "") {
                setIsEditCategoryCustomer(false)
            } else {
                setIsEditCategoryCustomer(true)
            }

            setValueNameDMTK(res.data.category_account.name_file_full)
            setValueNameNCC(res.data.category_customer.name_file_full)

            form.setFieldsValue({
                dmtk_path: res.data.category_account.name_file_full,
                ncc_path: res.data.category_customer.name_file_full,
                circular: res.data.id_circular,
                business_main: res.data.business_main,
                type_business: res.data.id_type_business,
                amount_deviation: res.data.amount_deviation,
            })
            setChooseId(id)
            setUrlSupplier(localhost + "/" + res.data.category_customer.path_file)
            setUrlCustomer(localhost + "/" + res.data.category_account.path_file)
        }).catch(err => {

        })
    }

    const fetchListTaxAgent = () => {
        GetListTaxAgent().then(res => {
            setChooseId(res.data[0].id)
            setDataTaxAgent(res.data)
            setDataFilter(res.data)
            FetchInforMaster(res.data[0].id)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        fetchListTaxAgent();
    }, []);

    useEffect(() => {
        fileInputRef1.current = null
        fileInputRef.current = null
        FetchListCircular()
    }, []);

    const listDataTaxAgent = () => {
        return dataFilter.map(item => (
            <Tag onClick={() => FetchInforMaster(item.id)} style={{ height: "fit-content", cursor: "pointer", width: "100%", whiteSpace: "wrap", padding: "4px 7px" }} color={chooseId === item.id ? "processing" : "default"}>{item.name_organization}</Tag>
        ))
    }

    const onChange = (e) => {
        fileInputRef1.current = null
        fileInputRef.current = null

        var inputElement = document.getElementById("myFile1")
        var inputElement2 = document.getElementById("myFile2")

        inputElement.value = ""
        inputElement2.value = ""
        setValue(e.target.value);
        FetchInforMaster(chooseId, e.target.value)
    };

    const isXlsxFile = (file) => {
        if (file !== undefined) {
            const fileType = file.type;
            const validTypes = [
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.ms-excel",
            ];
            const fileSize = file.size / 1024 / 1024 < 50
            return validTypes.includes(fileType) && fileSize;
        }
    };

    const handleFileChangeDMKH = (event) => {
        const file = event.target.files[0];
        if (!isXlsxFile(file)) {
            return openNotificationSweetAlert(ErrorImage, "Dung lượng file quá 50MB hoặc sai định dạng");
        } else {
            fileInputRef.current = event.target
            setValueNameDMTK(file.name);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // Handle the uploaded file here
        if (!isXlsxFile(file)) {
            return openNotificationSweetAlert(ErrorImage, "Dung lượng file quá 50MB hoặc sai định dạng");
        } else {
            fileInputRef1.current = event.target
            setValueNameNCC(file.name);
        }
    };

    const FetchListCircular = () => {
        GetListCircularNoApi().then(res => {
            setListCircular(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const handleRefreshData = () => {
        var inputElement = document.getElementById("myFile1")
        var inputElement2 = document.getElementById("myFile2")

        inputElement.value = ""
        inputElement2.value = ""
        fileInputRef1.current = null
        fileInputRef.current = null
        FetchInforMaster(chooseId)
    }

    const onFinish = (values) => {
        var FormData = require("form-data");
        var data = new FormData();
        data.append("id_customer", chooseId);
        data.append("id_circular", values.circular);
        data.append("business_main", values.business_main || "");
        data.append("id_type_business", values.type_business);
        data.append("amount_deviation", values.amount_deviation);
        if (fileInputRef1.current !== null) {
            if (fileInputRef1.current.files.length !== 0) {
                data.append("file_category_customer", fileInputRef1.current.files[0]);
            } else {
                data.append("file_category_customer", null);
            }
        } else {
            data.append("file_category_customer", null);
        }

        if (fileInputRef.current !== null) {
            if (fileInputRef.current.files.length !== 0) {
                data.append("file_category_account", fileInputRef.current.files[0]);
            } else {
                data.append("file_category_account", null);
            }
        } else {
            data.append("file_category_account", null);
        }

        MySwal.fire({
            title: <span style={{ fontSize: 20 }}>Bạn có chắc muốn lưu thay đổi ?</span>,
            // text: "You won't be able to revert this!",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            imageUrl: WarningImage,
            confirmButtonText: <span>Chắc chắn!</span>,
            cancelButtonText: <span>Không</span>,
            customClass: {
                icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
                popup: "custom-notification",
                confirmButton: "btn-category-master",
                cancelButton: "btn-category-master",
            },
            position: "top",
        }).then((result) => {
            if (result.isConfirmed) {
                PutInforMasterApi(data).then((res) => {
                    MySwal.fire({
                        title: "Thành công!",
                        text: res.data.message,
                        // icon: "success",
                        imageUrl: SuccessImage,
                        customClass: {
                            // confirmButton: "btn-confirm-delete",
                            icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
                            popup: "custom-notification",
                            confirmButton: "btn-category-master",
                            cancelButton: "btn-category-master",
                        },
                        position: "top",
                    });
                    handleRefreshData()
                    fetchListTaxAgent()
                })
                    .catch((err) => {
                        handleRefreshData()
                        MySwal.fire({
                            title: "Thất bại!",
                            text: err.data.message,
                            imageUrl: ErrorImage,
                            customClass: {
                                icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
                                popup: "custom-notification",
                                confirmButton: "btn-category-master",
                                cancelButton: "btn-category-master",
                            },
                            position: "top",
                        });
                    });
            }
        });
    };

    const openModalEditCategoryUser = () => {
        setOpenEditCategoryUser(true)
    }

    const openModalEditCategoryCustomer = () => {
        setOpenEditCategoryCustomer(true)
    }

    const searchCustomer = (event) => {
        const data = dataTaxAgent.filter(item => item.name_organization.match(event.target.value))
        setDataFilter(data)
    }

    return (
        <Container maxWidth="false" className='container-editOrView' style={{ width: "97%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className='bg-all' style={{ overflowY: "auto" }}>
                <Row style={{ width: "100%" }}>
                    <Col span={6} offset={1} >
                        <div className='list-user'>
                            <div className='box-search-customer'>
                                <Input style={{ width: "90%" }} placeholder='Tìm tên doanh nghiệp' onChange={searchCustomer}></Input>
                            </div>
                            <div style={{ rowGap: "1ch", paddingTop: "3%", display: "grid" }}>
                                {listDataTaxAgent()}
                            </div>
                        </div>
                    </Col>
                    <Col span={16} offset={1}>
                        <Form
                            form={form}
                            layout="vertical"
                            autoComplete="off"
                            onFinish={onFinish}
                            // onFinishFailed={onFinishFailed}
                            // validateMessages={validateMessages}
                            // loading={loading}
                            initialValues={{
                                remember: true,
                            }}
                            className='text-label-manage-company'
                            style={{ width: "93%", height: "inherit", padding: "1%" }}
                        >
                            <Row className="rowInforCustomer">
                                <Col
                                    span={24}
                                    style={{ display: "flex", paddingBottom: "1%" }}
                                >
                                    <img src={CategoryMasterIcon} alt=''></img>
                                    <span className="title-infor-company" style={{ margin: 0, marginLeft: "1%" }}>
                                        Danh mục
                                    </span>
                                </Col>
                            </Row>
                            <div style={{ marginTop: "2%" }}>
                                <Col>
                                    <Form.Item
                                        label="Loại hình kinh doanh"
                                        name='type_business'
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Vui lòng chọn danh mục Nhà cung cấp/ Khách hàng!",
                                            },
                                        ]}>
                                        <Radio.Group className='text-checkbox' onChange={onChange} value={value} style={{ display: "flex", columnGap: "20px" }}>
                                            <Radio value={1} className='checkbox-sign-up'>Thương mại</Radio>
                                            <Radio value={2} className='checkbox-sign-up'>Dịch vụ</Radio>
                                            <Radio value={3} className='checkbox-sign-up'>Sản xuất</Radio>
                                            <Radio value={4} className='checkbox-sign-up'>Xây dựng</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col className='hidden-bg-input'>
                                    <Form.Item name="dmtk_path" label={<span><span style={{ color: "red" }}>* </span>Danh mục tài khoản</span>}>
                                        <input
                                            ref={fileInputRef}
                                            accept=".xlsx,.xls"
                                            type={isEditCategoryUser === true ? "text" : "file"}
                                            style={{ display: "none" }}
                                            // onChange={handleFileChangeDMKH}
                                            name="myFile2"
                                            id="myFile2"
                                            onInput={handleFileChangeDMKH}
                                        />
                                        <Input
                                            placeholder="Danh mục Tài khoản"
                                            type="text"
                                            // disabled
                                            value={valueNameDMTK ? valueNameDMTK : null}
                                            readOnly
                                            size='large'
                                            suffix={
                                                <div style={{ display: "flex" }}>

                                                    <ButtonMui
                                                        htmlFor="myFile2"
                                                        component="label"
                                                        size="small"
                                                        className='none-bg'
                                                        style={{ height: "29px" }}
                                                    >
                                                        {isEditCategoryUser === false ?
                                                            <div class="iconDiv" tooltip="Tải lên" tabindex="0">
                                                                <div class="iconSVG">
                                                                    <img
                                                                        style={{ cursor: "pointer", paddingTop: "9px" }}
                                                                        src={IconUpload}
                                                                        alt=""
                                                                    ></img>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div class="iconDiv" tooltip="Chỉnh sửa" tabindex="0" onClick={openModalEditCategoryUser}>
                                                                <div class="iconSVG">
                                                                    <img
                                                                        style={{ cursor: "pointer", paddingTop: "9px" }}
                                                                        src={IconEdit}
                                                                        alt=""
                                                                    ></img>
                                                                </div>
                                                            </div>
                                                        }
                                                    </ButtonMui>

                                                    <BtnDownload url={urlCustomer} />
                                                </div>
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className='hidden-bg-input'>
                                    <Form.Item
                                        name="ncc_path"
                                        label={<span><span style={{ color: "red" }}>* </span>Danh mục nhà cung cấp khách hàng</span>}
                                    >
                                        <input
                                            ref={fileInputRef1}
                                            accept=".xlsx,.xls"
                                            type={isEditCategoryCustomer === true ? "text" : "file"}
                                            style={{ display: "none" }}
                                            onChange={handleFileChange}
                                            name="myFile1"
                                            id="myFile1"
                                        />
                                        <Input
                                            size={"large"}
                                            placeholder="Danh mục Nhà cung cấp/ Khách hàng"
                                            type="text"
                                            // disabled
                                            suffix={
                                                <div style={{ display: "flex" }}>

                                                    <ButtonMui
                                                        htmlFor="myFile1"
                                                        component="label"
                                                        size="small"
                                                        className='none-bg'
                                                        style={{ height: "29px" }}
                                                    >
                                                        {isEditCategoryCustomer === false ?
                                                            <div class="iconDiv" tooltip="Tải lên" tabindex="0">
                                                                <div class="iconSVG">
                                                                    <img
                                                                        style={{ cursor: "pointer", paddingTop: "9px" }}
                                                                        src={IconUpload}
                                                                        alt=""
                                                                    ></img>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div class="iconDiv" tooltip="Chỉnh sửa" tabindex="0" onClick={openModalEditCategoryCustomer}>
                                                                <div class="iconSVG">
                                                                    <img
                                                                        style={{ cursor: "pointer", paddingTop: "9px" }}
                                                                        src={IconEdit}
                                                                        alt=""
                                                                    ></img>
                                                                </div>
                                                            </div>
                                                        }
                                                    </ButtonMui>
                                                    <BtnDownload url={urlSupplier} />
                                                </div>
                                            }
                                            value={valueNameNCC}
                                            // disabled
                                            readOnly
                                            className="inputTTDN"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item label="Thông tư" name='circular'>
                                        <Select
                                            size={"large"}
                                            id="code_city"
                                            className="SelectTTDN"
                                            style={{ textAlign: "left" }}
                                            optionFilterProp="children"
                                        >
                                            {listCircular.map((item, index) => (
                                                <Option key={index} value={item.id}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item label="Ngành nghề kinh doanh" name='business_main'>
                                            <Input size='large' style={{ width: "98%", float: "left" }}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Tiền cho phép lệch hóa đơn"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập tên viết tắt!",
                                                },
                                            ]}
                                            className='error-amount'
                                            name="amount_deviation"
                                        >
                                            <InputNumber
                                                min={0}
                                                maxLength={12}
                                                suffix="đ"
                                                size='large'
                                                controls={false}
                                                placeholder="Nhập số tiền chênh lệch"
                                                formatter={(value) =>
                                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                                style={{ width: "98%", float: "right" }}
                                                onKeyPress={(e) => {
                                                    const charCode = e.which ? e.which : e.keyCode;
                                                    if (charCode < 48 || charCode > 57) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        htmlType="button"
                                        onClick={handleRefreshData}
                                        className='text-btn design-btn'
                                        style={{ marginRight: "2%", height: "5vh" }}
                                    >
                                        Hủy
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="success"
                                        htmlType="submit"
                                        className='text-btn'
                                        style={
                                            {
                                                border: "2px solid var(--Color-line, #058DF4)",
                                                borderRadius: 8,
                                                height: "5vh",
                                                width: "100px",
                                                color: "#fff",
                                                background: "var(--Color-line, linear-gradient(0deg, #058DF4 0%, #6266EF 100%))",
                                            }
                                        }
                                    >
                                        Lưu
                                    </Button>
                                </Col>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
            {openEditCategoryUser === true ?
                <ModalEditCategoryUser
                    open={openEditCategoryUser}
                    setOpenEditCategoryUser={setOpenEditCategoryUser}
                    idCustomer={chooseId}
                />
                : null}

            {openEditCategoryCustomer === true ?
                <ModalEditCategoryCustomer
                    open={openEditCategoryCustomer}
                    setOpenEditCategoryCustomer={setOpenEditCategoryCustomer}
                    idCustomer={chooseId}
                />
                : null}
        </Container>
    )
}

export default CategoryMasterRoleAdmin