import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import UploadIcon from "./../../../../../images/image_manage_docs/UploadInvoice.svg"
import DownloadIcon from "./../../../../../images/image_manage_docs/DownloadInvoice.svg"
import ViewFilter from "../../../../../images_mobile/ViewModalFilter.svg"
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'
const { RangePicker } = DatePicker;
const { Option } = Select;

function ContentInvoiceMB(
    { titleTable,
        openModalUploadAll,
        dataSource,
        handleChange,
        pager,
        columns,
        fetchInforInvoice,
        typeDocument,
        disabledDateAccounting,
        setDatesAccounting,
        status,
        disabledDateUpload,
        setDatesUpload,
        setFieldFilter,
        loadingTable
    }) {
    const [isOpenModalFilter, setIsOpenModalFilter] = useState(false)

    const openModalFilter = () => {
        setIsOpenModalFilter(true)
    }
    return (
        <>
            <div style={{ width: "100%", position: "relative" }}>
                <Row>
                    <Col span={24} className='title-table-invoice-all'>{titleTable}</Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Button onClick={openModalFilter} className='btn-invoice-all' style={{ marginRight: "3%", background: "rgba(50, 31, 219, 0.12)", border: "unset" }}><img alt='' src={ViewFilter}></img></Button>
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className='btn-invoice-all' style={{ marginRight: "3%", background: "rgba(50, 31, 219, 0.12)", border: "unset" }}><img alt='' src={DownloadIcon}></img></Button>
                        <Button onClick={openModalUploadAll} className='btn-invoice-all' style={{ background: "rgba(58, 183, 176, 0.15)", border: "unset" }}><img alt='' src={UploadIcon}></img></Button>
                    </Col>
                </Row>

                <Table
                    size='small'
                    columns={columns}
                    dataSource={dataSource}
                    onChange={handleChange}
                    style={{ marginTop: "3%", fontSize: 14, position: "absolute" }}
                    className='table-invoice-all'
                    bordered
                    loading={loadingTable}
                    scroll={{
                        // y: "58vh",
                        x: 800,
                    }}

                    pagination={{
                        current: pager.current,
                        pageSize: pager.pageSize,
                        total: pager.count,
                        showSizeChanger: false,
                        showTotal: (total, range) => `Tổng cộng: ${total}`
                    }}
                />
            </div>
            <ModalFilter
                open={isOpenModalFilter}
                setIsOpenModalFilter={setIsOpenModalFilter}
                fetchInforInvoice={fetchInforInvoice}
                pager={pager}
                typeDocument={typeDocument}
                disabledDateAccounting={disabledDateAccounting}
                setDatesAccounting={setDatesAccounting}
                status={status}
                disabledDateUpload={disabledDateUpload}
                setDatesUpload={setDatesUpload}
                setFieldFilter={setFieldFilter}
            />
        </>
    )
}

const ModalFilter = (
    {
        open,
        setIsOpenModalFilter,
        fetchInforInvoice,
        pager,
        typeDocument,
        disabledDateAccounting,
        setDatesAccounting,
        status,
        disabledDateUpload,
        setDatesUpload,
        setFieldFilter
    }) => {
    const userInfo = useSelector((state) => state.getUserInfo.userInfo);
    const [form] = Form.useForm();

    const handleCancel = () => {
        setIsOpenModalFilter(false)
    }

    const trimField = (text) => {
        if (text !== undefined && text !== null) {
            return text.trim()
        } else {
            return null
        }
    }

    const onFinish = (values) => {
        fetchInforInvoice({
            page: 1,
            page_size: pager.pageSize,
            id_customer: userInfo.customer_id,
            invoice_number: trimField(values.invoice_number),
            total_vat_amount: values.total_vat_amount,
            tax_code_seller: trimField(values.tax_code_seller),
            status: JSON.stringify(values.status),
            name_seller: trimField(values.name_seller),
            code_lookup: trimField(values.code_lookup),
            type_document: typeDocument,

            from_release_date: values.release_date !== undefined && values.release_date !== null ? dayjs(values.release_date[0]).format("YYYY-MM-DD") : null,
            to_release_date: values.release_date !== undefined && values.release_date !== null ? dayjs(values.release_date[1]).format("YYYY-MM-DD") : null,

            from_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[0]).format("YYYY-MM-DD") : null,
            to_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[1]).format("YYYY-MM-DD") : null,
        })
        handleCancel()

        setFieldFilter({
            invoice_number: trimField(values.invoice_number),
            total_vat_amount: values.total_vat_amount,
            tax_code_seller: trimField(values.tax_code_seller),
            status: JSON.stringify(values.status),
            name_seller: trimField(values.name_seller),
            code_lookup: trimField(values.code_lookup),
            type_document: typeDocument,

            from_release_date: values.release_date !== undefined && values.release_date !== null ? dayjs(values.release_date[0]).format("YYYY-MM-DD") : null,
            to_release_date: values.release_date !== undefined && values.release_date !== null ? dayjs(values.release_date[1]).format("YYYY-MM-DD") : null,

            from_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[0]).format("YYYY-MM-DD") : null,
            to_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[1]).format("YYYY-MM-DD") : null,
        })
        // document.activeElement.blur();
    }

    const resetData = () => {
        form.resetFields();
        fetchInforInvoice({
            page: 1,
            page_size: pager.pageSize,
            id_customer: userInfo.customer_id,
            type_document: typeDocument,
        })

        setFieldFilter({
            invoice_number: null,
            total_vat_amount: null,
            tax_code_seller: null,
            status: null,
            name_seller: null,
            code_lookup: null,
            type_document: null,
            from_release_date: null,
            to_release_date: null,

            from_create_date: null,
            to_create_date: null,
        })
    }
    return (
        <>
            <Modal
                open={open}
                // onOk={handleOk}
                onCancel={handleCancel}
                width={'85%'}
                style={{ height: '60vh', top: 5 }}
                // closable={false}
                className="modal-detail-invoice-MB"

                footer={false}
            // centered
            >
                <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    // validateMessages={validateMessages}
                    // loading={loading}
                    initialValues={{
                        remember: true,
                    }}
                    className='text-label-manage-docs'
                    style={{ paddingTop: "10%" }}
                >
                    <Row style={{ maxHeight: "75vh", overflowY: "scroll" }}>
                        <Col span={24}>
                            <Form.Item label="Ngày phát hành" name="release_date">
                                <RangePicker
                                    disabledDate={disabledDateAccounting}
                                    onCalendarChange={(val) => {
                                        setDatesAccounting(val);
                                    }}
                                    inputReadOnly={true}
                                    locale={{
                                        lang: {
                                            locale: "en_US",
                                            rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
                                            yearFormat: "YYYY",
                                            shortMonths: [
                                                "Tháng 1",
                                                "Tháng 2",
                                                "Tháng 3",
                                                "Tháng 4",
                                                "Tháng 5",
                                                "Tháng 6",
                                                "Tháng 7",
                                                "Tháng 8",
                                                "Tháng 9",
                                                "Tháng 10",
                                                "Tháng 11",
                                                "Tháng 12"
                                            ],
                                            shortWeekDays: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
                                            ok: "OK",
                                            monthBeforeYear: true,
                                        }
                                    }}
                                    style={{ width: "96%" }}
                                    format={"DD-MM-YYYY"}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Ngày tải lên" name="create_date">
                                <RangePicker
                                    disabledDate={disabledDateUpload}
                                    onCalendarChange={(val) => {
                                        setDatesUpload(val);
                                    }}
                                    inputReadOnly={true}
                                    locale={{
                                        lang: {
                                            locale: "en_US",
                                            rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
                                            yearFormat: "YYYY",
                                            shortMonths: [
                                                "Tháng 1",
                                                "Tháng 2",
                                                "Tháng 3",
                                                "Tháng 4",
                                                "Tháng 5",
                                                "Tháng 6",
                                                "Tháng 7",
                                                "Tháng 8",
                                                "Tháng 9",
                                                "Tháng 10",
                                                "Tháng 11",
                                                "Tháng 12"
                                            ],
                                            shortWeekDays: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
                                            ok: "OK",
                                            monthBeforeYear: true,
                                        }
                                    }}
                                    style={{ width: "96%" }}
                                    format={"DD-MM-YYYY"}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Nhà cung cấp / Khách hàng" name="name_seller">
                                <Input style={{ width: "96%" }} onBlur={(e) => form.setFieldValue("name_seller", e.target.value.trim())} placeholder='Nhập nhà cung cấp'></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Trạng thái" name="status">
                                <Select
                                    id="code_district"
                                    size={"middle"}
                                    allowClear
                                    mode='multiple'
                                    className="SelectTTDN"
                                    placeholder="Chọn trạng thái"
                                    maxTagCount={"responsive"}
                                    showSearch={false}
                                    style={{ width: "96%" }}
                                    optionFilterProp="children"
                                >
                                    {status.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Mã chứng từ" name="code_lookup">
                                <Input style={{ width: "96%" }}
                                    onBlur={(e) => form.setFieldValue("code_lookup", e.target.value.trim())} placeholder='Nhập mã tra cứu'></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Mã số thuế" name="tax_code_seller">
                                <Input style={{ width: "96%" }}
                                    onBlur={(e) => form.setFieldValue("tax_code_seller", e.target.value.trim())} placeholder='Nhập mã số thuế'></Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Thành tiền" name="total_vat_amount">
                                <InputNumber
                                    min={0}
                                    size='middle'
                                    controls={false}
                                    placeholder="Nhập thành tiền"
                                    style={{ width: "96%" }}
                                    formatter={(value) =>
                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                    onKeyPress={(e) => {
                                        const charCode = e.which ? e.which : e.keyCode;
                                        if (charCode < 48 || charCode > 57) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Số hóa đơn" name="invoice_number">
                                <Input style={{ width: "96%" }}
                                    onBlur={(e) => form.setFieldValue("invoice_number", e.target.value.trim())} placeholder='Nhập số hóa đơn'></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Col style={{ paddingTop: "3%" }}>
                        <div style={{ display: "flex", justifyContent: "flex-end", width: "96%" }}>
                            <Button
                                htmlType="button"
                                className='text-btn design-btn'
                                style={{ height: 45, width: "50%" }}
                                onClick={resetData}
                            >
                                Đặt lại
                            </Button>
                            <Button
                                htmlType="submit"
                                className='text-btn design-btn-submit'
                                style={{ height: 45, width: "50%" }}
                            >
                                Tìm kiếm
                            </Button>
                        </div>
                    </Col>
                </Form>
            </Modal>
        </>
    )
}


export default ContentInvoiceMB