import { Container } from '@mui/material'
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Table } from 'antd'
import React, { useState } from 'react'
import ViewFilter from "../../../../../images_mobile/ViewModalFilter.svg"
import ViewDetailMobile from './ViewDetailMobile'
import SearchIcon from "./../../../../../images/image_manage_docs/search.svg"
import ResetFields from "./../../../../../images/image_manage_docs/ResetFieldFilter.svg"
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker;
const { Option } = Select;


function HistoryMobile({
  columns,
  dataSource,
  handleChange,
  pager,
  isOpenModalDetail,
  setIsOpenModalDetail,
  nameStatusDetail,
  idDetail,
  idCustomer,
  disabledDateAccounting,
  setDatesAccounting,
  nguon_du_lieu,
  status,
  setFieldFilter,
  fetchDataHistory,
  loadingTable
}) {
  const [openModalFilter, setOpenModalFilter] = useState(false)

  const showModalFilter = () => {
    setOpenModalFilter(true)
  }
  return (
    <>
      <Container maxWidth="false" style={{ backgroundColor: "#fff", width: "97%", marginTop: "1.2%", height: "88vh" }}>
        <div style={{ width: "100%", padding: "16px 0px" }}>
          <div style={{ width: "100%", position: "relative" }}>
            <Row>
              <Col span={24} className='title-table-invoice-all'>DANH SÁCH TẢI LÊN</Col>
              <Col span={24}>
                <Button onClick={showModalFilter} className='btn-invoice-all' style={{ marginRight: "3%", background: "rgba(50, 31, 219, 0.12)", border: "unset" }}><img alt='' src={ViewFilter}></img></Button>
              </Col>
            </Row>
            <Table
              size='small'
              columns={columns}
              dataSource={dataSource}
              onChange={handleChange}
              style={{ marginTop: "3%", fontSize: 14, position: "absolute" }}
              className='table-invoice-all'
              bordered
              scroll={{
                // y: "42vh",
                x: 800,
              }}
              loading = {loadingTable}

              pagination={{
                current: pager.current,
                pageSize: pager.pageSize,
                total: pager.count,
                showSizeChanger: false,
                showTotal: (total, range) => `Tổng cộng: ${total}`
              }}
            />
          </div>
        </div>
        <ViewDetailMobile
          isOpenModalDetail={isOpenModalDetail}
          setIsOpenModalDetail={setIsOpenModalDetail}
          nameStatusDetail={nameStatusDetail}
          idDetail={idDetail}
          idCustomer={idCustomer}
        />
        <ModalFilter
          open={openModalFilter}
          setOpenModalFilter={setOpenModalFilter}
          disabledDateAccounting={disabledDateAccounting}
          setDatesAccounting={setDatesAccounting}
          nguon_du_lieu={nguon_du_lieu}
          status={status}
          setFieldFilter={setFieldFilter}
          fetchDataHistory={fetchDataHistory}
          pager={pager}
        />
      </Container>

    </>
  )
}

const ModalFilter = (
  {
    open,
    setOpenModalFilter,
    disabledDateAccounting,
    setDatesAccounting,
    nguon_du_lieu,
    status,
    setFieldFilter,
    fetchDataHistory,
    pager
  }
) => {
  const [form] = Form.useForm()
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  const handleCancel = () => {
    setOpenModalFilter(false)
  }
  const onFinish = (values) => {
    fetchDataHistory(
      {
        id_customer: userInfo.customer_id,
        page: 1,
        page_size: pager.pageSize,
        dataset_name: values.dataset_name,
        type_upload: JSON.stringify(values.type_upload),
        from_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[0]).format("YYYY-MM-DD") : null,
        to_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[1]).format("YYYY-MM-DD") : null,
        status: JSON.stringify(values.status)
      }
    )
    setFieldFilter({
      dataset_name: values.dataset_name,
      type_upload: JSON.stringify(values.type_upload),
      from_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[0]).format("YYYY-MM-DD") : null,
      to_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[1]).format("YYYY-MM-DD") : null,
      status: JSON.stringify(values.status)
    })
    setOpenModalFilter(false)
  }

  const resetData = () => {
    form.resetFields();
    fetchDataHistory({
      page: 1,
      page_size: pager.pageSize,
      id_customer: userInfo.customer_id,
    })
    setFieldFilter({
      type_upload: null,
      dataset_name: null,
      from_create_date: null,
      to_create_date: null,
      status: null,
    })
  }
  return (
    <>
      <Modal
        open={open}
        // onOk={handleOk}
        onCancel={handleCancel}
        width={'85%'}

        style={{ height: '60vh', top: 50, position: "relative" }}
        // closable={false}
        // className="modal-detail"

        footer={false}
      // centered
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          // validateMessages={validateMessages}
          // loading={loading}
          initialValues={{
            remember: true,
          }}
          style={{ paddingTop: "8%" }}
          className='text-label-manage-docs'
        >
          <Row>
            <Col span={24}>
              <Form.Item label="Ngày tải lên" name={"create_date"}>
                <RangePicker
                  disabledDate={disabledDateAccounting}
                  onCalendarChange={(val) => {
                    setDatesAccounting(val);
                  }}
                  inputReadOnly={true}
                  locale={{
                    lang: {
                      locale: "en_US",
                      rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
                      yearFormat: "YYYY",
                      shortMonths: [
                        "Tháng 1",
                        "Tháng 2",
                        "Tháng 3",
                        "Tháng 4",
                        "Tháng 5",
                        "Tháng 6",
                        "Tháng 7",
                        "Tháng 8",
                        "Tháng 9",
                        "Tháng 10",
                        "Tháng 11",
                        "Tháng 12"
                      ],
                      shortWeekDays: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
                      ok: "OK",
                      monthBeforeYear: true,
                    }
                  }}
                  style={{ width: "100%" }}
                  format={"DD-MM-YYYY"}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Nguồn dữ liệu" name={"type_upload"}>
                <Select
                  id="code_district"
                  maxTagCount={"responsive"}
                  size={"middle"}
                  allowClear
                  mode='multiple'
                  className="SelectTTDN"
                  placeholder="Chọn nguồn dữ liệu"
                  // disabled={canEdit}
                  optionFilterProp="children"
                  showSearch={false}

                >

                  {nguon_du_lieu.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Trạng thái" name={"status"}>
                <Select
                  id="code_district"
                  size={"middle"}
                  allowClear
                  maxTagCount={"responsive"}
                  mode='multiple'
                  className="SelectTTDN"
                  placeholder="Chọn trạng thái"
                  showSearch={false}
                  optionFilterProp="children"
                >

                  {status.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}

                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Mã gói yêu cầu" name={"dataset_name"}>
                <Input placeholder='Nhập mã gói yêu cầu'></Input>
              </Form.Item>
            </Col>
            <Col span={24} style={{ paddingTop: "3%" }}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  htmlType="button"
                  className='text-btn design-btn'
                  style={{ height: 45, width: "50%" }}
                  // onClick={() => setTimeout(() => {
                  //     ClickCancelEdit()
                  // }, 200)}
                  onClick={resetData}
                >
                  Đặt lại
                </Button>
                <Button
                  // variant="contained"
                  // type="success"
                  htmlType="submit"
                  // disabled={loadingButtonSave}
                  className='text-btn design-btn-submit'
                  style={{ height: 45, width: "50%" }}
                >
                  Tìm kiếm
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default HistoryMobile