// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-accounting-invoice .ant-table-thead .ant-table-cell {
  color: var(--text-title-1, #2d3459);
  text-align: center;
  /* font-family: Roboto; */
  font-size: 14px;
  font-style: normal;
  font-weight: 650;
  line-height: normal;
}

.title-table-accounting-invoice {
  color: var(--text-title-2, #394b76);
  /* font-family: Roboto; */
  font-size: 18px;
  font-style: normal;
  font-weight: 650;
  line-height: 20px; /* 111.111% */
  text-transform: uppercase;
  justify-content: center;
  display: flex;
  align-items: center;
}

.form-email
  .ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .ant-upload-list-item-action.ant-btn-sm {
  height: 22px;
  border: 0;
  line-height: 1;
}

.dien-giai-hoaDon {
  white-space: nowrap;
  max-width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  .title-table-accounting-invoice {
    font-size: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/containers/component/manage_doc/accounting_entry/accounting_invoice/AccountingInvoice.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;EACnC,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;EAChC,yBAAyB;EACzB,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB;;AAEA;;;;;;EAME,YAAY;EACZ,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".table-accounting-invoice .ant-table-thead .ant-table-cell {\r\n  color: var(--text-title-1, #2d3459);\r\n  text-align: center;\r\n  /* font-family: Roboto; */\r\n  font-size: 14px;\r\n  font-style: normal;\r\n  font-weight: 650;\r\n  line-height: normal;\r\n}\r\n\r\n.title-table-accounting-invoice {\r\n  color: var(--text-title-2, #394b76);\r\n  /* font-family: Roboto; */\r\n  font-size: 18px;\r\n  font-style: normal;\r\n  font-weight: 650;\r\n  line-height: 20px; /* 111.111% */\r\n  text-transform: uppercase;\r\n  justify-content: center;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.form-email\r\n  .ant-upload-wrapper\r\n  .ant-upload-list\r\n  .ant-upload-list-item\r\n  .ant-upload-list-item-actions\r\n  .ant-upload-list-item-action.ant-btn-sm {\r\n  height: 22px;\r\n  border: 0;\r\n  line-height: 1;\r\n}\r\n\r\n.dien-giai-hoaDon {\r\n  white-space: nowrap;\r\n  max-width: 420px;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  text-align: left;\r\n}\r\n\r\n@media only screen and (max-width: 1024px) {\r\n  .title-table-accounting-invoice {\r\n    font-size: 16px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
