// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content1 {
  will-change: margin-left;
  transform: translateX(0);
  transition: transform 0.5s ease;
  width: 100%;
}

.content1.collapsed {
  transform: translateX(80px);
}
`, "",{"version":3,"sources":["webpack://./src/layout/Layout.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,wBAAwB;EACxB,+BAA+B;EAC/B,WAAW;AACb;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".content1 {\r\n  will-change: margin-left;\r\n  transform: translateX(0);\r\n  transition: transform 0.5s ease;\r\n  width: 100%;\r\n}\r\n\r\n.content1.collapsed {\r\n  transform: translateX(80px);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
